<style>
amplify-authenticator {
  --container-align: start;
  --container-height: auto;
  --box-shadow: none;
}
:root {
  --amplify-primary-color: #004693;
  --amplify-primary-tint: #004693;
  --amplify-primary-shade: #004693;
}
</style>
<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-img
          class="mx-auto"
          src="/img/SCG_logos_w_Reg_jpgs/SCG_logo_01_stack wGTBS.jpg"
          width="50%"
          max-height="300px"
          contain
        ></v-img>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" xs="11," sm="9" md="7" lg="6" xl="5">
        <v-card class="elevation-12" :loading="loading ? 'secondary' : false">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title
              :style="
                $vuetify.breakpoint.width < 450
                  ? 'font-size: ' +
                    ($vuetify.breakpoint.width / 450) * 1.15 +
                    'em'
                  : ''
              "
              >SoCalGas&reg; Inter-Office Audit Login</v-toolbar-title
            >
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-form
            v-if="$isSandbox == true"
            ref="form"
            v-model="valid"
            @submit.prevent="onSubmit"
          >
            <v-card-text>
              <v-text-field
                autofocus
                label="Enter your username"
                v-model="username"
                prepend-icon="mdi-account"
                type="text"
                :rules="[v => !!v || 'Username is required']"
                required
              ></v-text-field>

              <v-text-field
                label="Enter your password"
                v-model="password"
                min="6"
                prepend-icon="mdi-lock"
                type="password"
                :rules="[v => !!v || 'Password is required']"
                required
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <p class="error--text">{{ error }}</p>
              <v-spacer></v-spacer>
              <v-btn type="submit" color="primary" :disabled="!valid || loading"
                >Login</v-btn
              >
            </v-card-actions>
          </v-form>

          <v-form
            v-if="$isSandbox == false"
            ref="form"
            v-model="valid"
            @submit.prevent="onSubmit"
          >
            <v-btn
              style="margin-top:15px;"
              type="submit"
              color="primary"
              :disabled="!valid || loading"
              >Microsoft Sign-In</v-btn
            >
          </v-form>
          <div v-if="authState !== 'signedin'">You are signed out.</div>
          <amplify-authenticator v-if="$isSandbox == false">
            <amplify-sign-in
              header-text="Cognito Sign-In"
              slot="sign-in"
              :form-fields.prop="formFields"
            >
              <div slot="federated-buttons"></div
            ></amplify-sign-in>
          </amplify-authenticator>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//import { required } from "vuelidate/lib/validators";

import { router } from "@/router";
import { authenticationService } from "@/_auth/authentication.service";
import { onAuthUIStateChange } from "@aws-amplify/ui-components";
import awsconfig from "../../aws-exports";
import { Auth } from "aws-amplify";
Auth.configure(awsconfig);

export default {
  data() {
    return {
      valid: false,
      submitted: false,
      username: "",
      password: "",
      loading: false,
      returnUrl: "",
      error: "",
      user: undefined,
      authState: undefined,
      formFields: [
        {
          type: "username",
          label: "Email *",
          placeholder: "Enter E-mail Address",
          required: true
        },
        { type: "password" }
      ]
    };
  },
  // validations: {
  //   username: { required },
  //   password: { required }
  // },
  created() {
    onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData;
      console.log(this.$isSandbox);
      //console.log(this.user);
    });
    this.returnUrl = this.$route.query.returnUrl || "/";
  },
  beforeDestroy() {
    return onAuthUIStateChange;
  },
  methods: {
    onSubmit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.submitted = true;

      this.error = "";

      this.loading = true;

      if (this.$isSandbox) {
        authenticationService.login().then(
          user => {
            console.log(user); // HACK
            router.push(this.returnUrl);
          },
          error => {
            this.error = error;
            this.loading = false;
          }
        );
      } else {
        Auth.federatedSignIn({
          provider: "ActiveDirectory"
        });
      }
    }
  }
};
</script>
