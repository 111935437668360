<template>
  <div :class="{ 'disabled-text': !isSupervisor }">
    <basic-question
      v-model="value"
      :disabled="!isSupervisor"
      @update="update"
    ></basic-question>
    <additional-actions-required-base>
      <template #row1>
        <v-row style="margin:0; padding-left:10px">
          <div style="padding:20px"><b>Required:</b></div>
          <div>
            <v-radio-group
              v-model="subAns"
              @change="updateSubAns"
              row
              :disabled="!isSupervisor"
            >
              <v-radio
                v-for="(answerChoice, index) in ['YES', 'NO']"
                :key="index"
                :label="answerChoice"
                :value="answerChoice"
              ></v-radio>
            </v-radio-group>
          </div>
        </v-row>
      </template>
    </additional-actions-required-base>
  </div>
</template>
<style scoped>
.disabled-text {
  color: grey;
}
</style>
<script>
import AdditionalActionsRequiredBase from "../../slots/AdditionalActionsRequiredBase";
import BasicQuestion from "../BasicQuestion";
import { RoleCheck } from "@/_auth/role";
import { authenticationService } from "@/_auth/authentication.service";
export default {
  props: ["value"],
  data: () => ({
    subAns: null,
    subAnsPath: "subitems/0/answer"
  }),
  components: {
    AdditionalActionsRequiredBase,
    BasicQuestion
  },
  computed: {
    isSupervisor() {
      return RoleCheck.isSupervisor(authenticationService.currentUserValue);
    }
  },
  methods: {
    update(input) {
      this.$emit("update", input);
    },
    updateSubAns() {
      this.$emit("update", [
        {
          suffix: this.subAnsPath,
          value: this.subAns
        }
      ]);
    }
  },
  created() {
    if (this.value.subitems != null && this.value.subitems[0].answer != null) {
      this.subAns = this.value.subitems[0].answer;
    }
  }
};
</script>
