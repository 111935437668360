import { handleResponse } from "@/_auth/handle-response";
// import { requestOptions } from "@/_auth/request-options";
import { Auth } from "aws-amplify";

export const officeService = {
  getAll,
  getOfficeNameByOfficeId,
  getOfficeLocationIdByOfficeId,
  getOfficeAddressByOfficeId
  // getById
};

let officeList = null;
async function getAll() {
  if (officeList === null) {
    let url = `${process.env.VUE_APP_SERVICE_BASE}${process.env.VUE_APP_OFFICE_GET_URI}`;
    // officeList = fetch(url, requestOptions.get()).then(handleResponse);

    const response = await fetch(url, {
      method: "GET",

      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Content-Type": "application/json"
      }
    }).then(handleResponse);
    officeList = await response;

    // response.ok; // => false
    // response.status; // => 404

    // officeList = await response.text();
  }
  return new Promise(resolve => resolve(officeList));
}

function getOfficeNameByOfficeId(officeId) {
  return getAll().then(value => {
    let office = value.find(o => o.id == officeId);
    return office.officeName;
  });
}

function getOfficeLocationIdByOfficeId(officeId) {
  return getAll().then(value => {
    let office = value.find(o => o.id == officeId);
    return office.locationId;
  });
}

function getOfficeAddressByOfficeId(officeId) {
  return getAll().then(value => {
    let office = value.find(o => o.id == officeId);
    return office.address;
  });
}

// function getById(id) {
//   let url = `${process.env.VUE_APP_SERVICE_BASE}${process.env.VUE_APP_OFFICE_GET_URI}/${id}`;
//   return fetch(url, requestOptions.get()).then(handleResponse);
// }
