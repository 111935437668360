import Vue from "vue";

import "../node_modules/nprogress/nprogress.css";
import "@aws-amplify/ui-vue";
import { Amplify } from "aws-amplify";
import awsconfig from "../aws-exports";
import VueCurrencyInput from "vue-currency-input";

Vue.use(VueCurrencyInput);

Amplify.configure(awsconfig); //doesn't matter if configured in sandbox environment

import AsyncComputed from "vue-async-computed";
Vue.use(AsyncComputed);

import App from "./App.vue";
import "./registerServiceWorker";
import { router } from "./router";
import vuetify from "./plugins/vuetify";

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

import VueExpandableImage from "vue-expandable-image";
Vue.use(VueExpandableImage);

Vue.config.productionTip = false;
/** START SANDBOX SETUP SECTION
 * This section helps configure "sandbox", which is an application mode allowing local development without backend interaction.
 * When in Sandbox mode, all calls to backend services are caught and handled by local mock code.  To the application, the calls
 * to the service js files should look identical, and the application should not be able to determine if it is in sandbox mode or
 * not.  The following section configures this mode if there are no values set for AUTH_BASE and SERVICE_BASE configuration parameters
 * It also sets a Vue variable available in every Vue component as this.$isSandbox in the event that logic must be branched.  Such
 * branching should be avoided as much as possible, so that the sandbox and live code work as closely as possible, and should only be
 * used when no other feasible way to fake javascript code is possible (such as login changes between SSO and local form-based, where
 * it may be difficult to use the same exact flow)
 * By default, npm run serve runs in sandbox mode.  This can be overriden by copying .env to .env.development.local , and leaving
 * only the top 2 lines (AUTH_BASE and SERVICE_BASE) with real values.  This file should not be committed (it is explicitly ignored,
 * do not add it).  Once in this state, you can use the below comment line to force a shift between sandbox and live
 * without restarting the 'serve'
 */
// import { configureSandboxAuthBackend } from "./_auth/sandbox_auth";
// import { configureSandboxOfficeBackend } from "./_services/sandbox_office";
// import { configureSandboxAuditBackend } from "./_services/sandbox_audit";

// if (process.env.VUE_APP_AUTH_BASE == "") {
//   configureSandboxAuthBackend();
// }
// if (process.env.VUE_APP_SERVICE_BASE == "") {
//   configureSandboxOfficeBackend();
//   configureSandboxAuditBackend();
// }

// let isSandbox = false;

// if (
//   process.env.VUE_APP_AUTH_BASE == "" ||
//   process.env.VUE_APP_SERVICE_BASE == ""
// ) {
//   isSandbox = true;
// }
/// Allows forced flipping of sandbox mode in DEV... DO NOT CHECK THIS LINE IN UNCOMMENTED!!!!!!!!!!!!!!!!!!!!!
/// DEV HACK:isSandbox = true; // DO NOT CHECK THIS LINE IN UNCOMMENTED!!!!!!!

// Vue.prototype.$isSandbox = isSandbox;

// if (isSandbox) {
//   process.env.VUE_APP_AUTH_BASE == "";
//   process.env.VUE_APP_SERVICE_BASE == "";
//   configureSandboxAuthBackend();
//   configureSandboxOfficeBackend();
//   configureSandboxAuditBackend();
// }
/***** END SANDBOX SETUP SECTION */

new Vue({
  router,
  vuetify,
  render: (h) => h(App)
}).$mount("#app");
