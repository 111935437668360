<template>
  <div>
    <v-row>
      <v-col>
        <v-menu
          v-model="dateMenu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              v-model="selectedDate1"
              prepend-icon="mdi-calendar"
              readonly
              :disabled="disabled"
              v-bind="attrs"
              v-on="on"
              outlined
              clearable
              @click:clear="clearDate1()"
              :style="{ 'min-width': selectedDate1 ? '' : '267px' }"
            >
            </v-text-field>
          </template>
          <v-date-picker
            :disabled="disabled"
            v-model="selectedDate1"
            :min="minDate"
            :max="maxDate < selectedDate2 ? maxDate : selectedDate2"
            :picker-date="minDate"
            @input="menuPicker = false"
            @change="datePicked1()"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-menu
          v-model="dateMenu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              v-model="selectedDate2"
              prepend-icon="mdi-calendar"
              readonly
              :disabled="disabled"
              v-bind="attrs"
              v-on="on"
              outlined
              clearable
              @click:clear="clearDate2()"
              :style="{ 'min-width': selectedDate2 ? '' : '267px' }"
            >
            </v-text-field>
          </template>
          <v-date-picker
            :disabled="disabled"
            v-model="selectedDate2"
            :min="minDate > selectedDate1 ? minDate : selectedDate1"
            :max="maxDate"
            :picker-date="minDate"
            @input="menuPicker = false"
            @change="datePicked2()"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    index1: {
      type: Number,
      default: 0
    },
    index2: {
      type: Number,
      default: 0
    },
    initialDate1: {
      type: String,
      default: null
    },
    initialDate2: {
      type: String,
      default: null
    },
    minDate: String,
    maxDate: String,
    disabled: Boolean
  },
  data: () => ({
    selectedDate1: null,
    selectedDate2: null,
    dateMenu1: false,
    dateMenu2: false
  }),
  methods: {
    datePicked1() {
      this.$emit("updateDate", this.selectedDate1, this.index1);
    },
    datePicked2() {
      this.$emit("updateDate", this.selectedDate2, this.index2);
    },
    clearDate1() {
      this.selectedDate1 = "";
      this.datePicked1();
    },
    clearDate2() {
      this.selectedDate2 = "";
      this.datePicked2();
    }
  },
  created() {
    this.selectedDate1 = this.initialDate1;
    this.selectedDate2 = this.initialDate2;
  }
};
</script>
