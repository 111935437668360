<template>
  <v-container>
    <!-- <v-row
      align="center"
      justify="center"
      v-if="app.layout == 'old-app-layout'"
    >
      <v-col cols="12">
        <Home_Lead v-if="isLead"></Home_Lead>
        <Home_Supervisor v-if="isSupervisor"></Home_Supervisor>
      </v-col>
      <v-col cols="12">
        <AuditList></AuditList>
        <amplify-sign-out></amplify-sign-out>
      </v-col>
    </v-row> -->

    <v-row class="welcome-margin">
      <v-col class="section-header" cols="12">
        Welcome
        <a
          :class="{
            'dark-name': $vuetify.theme.dark,
            'light-name': !$vuetify.theme.dark
          }"
        >
          {{ currentUser.firstName }} {{ currentUser.lastName }}
        </a>
      </v-col>
      <v-col cols="12">
        <Home_Lead v-if="isLead"></Home_Lead>
        <Home_Supervisor v-else-if="isSupervisor"></Home_Supervisor>
        <Home_Manager v-else-if="isManager"></Home_Manager>
        <Home_Tech v-else-if="isTechAdvisor"></Home_Tech>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.welcome-margin {
  margin-top: 10%;
}
.section-header {
  font-weight: 800;
  font-size: 24px;
}
.section-header .light-name {
  font-weight: 100;
  color: black;
}
.section-header .dark-name {
  font-weight: 100;
  color: white;
}
</style>

<script>
// @ is an alias to /src
import { authenticationService } from "@/_auth/authentication.service";
import { RoleCheck } from "@/_auth/role";
// import { auditService } from "@/_services/audit.service";
import Home_Lead from "@/views/Home_Lead";
import Home_Supervisor from "@/views/Home_Supervisor";
import Home_Manager from "@/views/Home_Manager";
import Home_Tech from "@/views/Home_Tech";
import commonMixin from "@/mixins/common";

export default {
  name: "Home",
  components: {
    Home_Lead,
    Home_Supervisor,
    Home_Manager,
    Home_Tech
  },
  mixins: [commonMixin],
  data() {
    return {
      loading: false,
      currentUser: authenticationService.currentUserValue
    };
  },
  computed: {
    isLead() {
      return RoleCheck.isLead(this.currentUser);
    },
    isSupervisor() {
      return RoleCheck.isSupervisor(this.currentUser);
    },
    isManager() {
      return RoleCheck.isManager(this.currentUser);
    },
    isTechAdvisor() {
      console.log(RoleCheck.isTechAdvisor(this.currentUser));
      return RoleCheck.isTechAdvisor(this.currentUser);
    }
  },
  created() {
    this.currentUser = authenticationService.currentUserValue;
  }
};
</script>
