import { Auth } from "aws-amplify";
import { handleResponse } from "@/_auth/handle-response";

export const fileDownloadService = {
  presignedDownload
};

async function presignedDownload(key) {
  try {
    const response = await getPresignedUrl(key);
    return response.presignedUrl;
  } catch (error) {
    console.log("An error occurred while retrieving the presigned URL:", error);
    Promise.reject(error);
  }
}

/**
 * Returns a presigned URL to download one specific file from an S3 bucket.
 * @param key
 **/
async function getPresignedUrl(key) {
  const url = process.env.VUE_APP_SERVICE_BASE + "/attachment/download";

  if (key) {
    let payload = {
      bucket: process.env.VUE_APP_ATTACHMENT_BUCKET,
      key: key
    };

    return await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    })
      .then(handleResponse)
      .then(response => {
        return response;
      });
  }
}
