<template>
  <v-col>
    <div v-if="view != 'general'">
      <v-row v-for="(data, appendixIndex) in appendixData" :key="data.appendix">
        <v-col>
          <v-row class="headers flex-nowrap">
            <div class="circle circle-color-exp">{{ data.appendix }}</div>
            {{ data.name }}</v-row
          >
          <v-row v-if="view != 'procedure'">
            <v-col>
              <v-row class="headers">Appendix</v-row>
              <v-row
                v-for="elem in filterByRole(
                  appendixElement[data.appendix][summaryType],
                  'leadauditor'
                )"
                :key="
                  `leadauditor-${getCommentOrAttachmentIdentifier(
                    summaryType,
                    elem
                  )}`
                "
              >
                <comment-base
                  v-if="summaryType == 'comments'"
                  :comment="elem"
                ></comment-base>
                <attachment-base
                  v-if="summaryType == 'attachments'"
                  :attachment="elem"
                  @remove="
                    toRemove => handeAttachmentRemove(toRemove, appendixIndex)
                  "
                ></attachment-base>
              </v-row>
              <v-row
                v-for="elem in filterByRole(
                  appendixElement[data.appendix][summaryType],
                  'techadvisor'
                )"
                :key="
                  `techadvisor-${getCommentOrAttachmentIdentifier(
                    summaryType,
                    elem
                  )}`
                "
              >
                <comment-base
                  v-if="summaryType == 'comments'"
                  :comment="elem"
                ></comment-base>
                <attachment-base
                  v-if="summaryType == 'attachments'"
                  :attachment="elem"
                  @remove="
                    toRemove => handeAttachmentRemove(toRemove, appendixIndex)
                  "
                ></attachment-base>
              </v-row>
              <v-row
                v-for="elem in filterByRole(
                  appendixElement[data.appendix][summaryType],
                  'branchsupervisor'
                )"
                :key="
                  `branchsupervisor-${getCommentOrAttachmentIdentifier(
                    summaryType,
                    elem
                  )}`
                "
              >
                <comment-base
                  v-if="summaryType == 'comments'"
                  :comment="elem"
                ></comment-base>
                <attachment-base
                  v-if="summaryType == 'attachments'"
                  :attachment="elem"
                  @remove="
                    toRemove => handeAttachmentRemove(toRemove, appendixIndex)
                  "
                ></attachment-base>
              </v-row>
              <v-row
                v-for="elem in filterByRole(
                  appendixElement[data.appendix][summaryType],
                  'manager'
                )"
                :key="
                  `manager-${getCommentOrAttachmentIdentifier(
                    summaryType,
                    elem
                  )}`
                "
              >
                <comment-base
                  v-if="summaryType == 'comments'"
                  :comment="elem"
                ></comment-base>
                <attachment-base
                  v-if="summaryType == 'attachments'"
                  :attachment="elem"
                  @remove="
                    toRemove => handeAttachmentRemove(toRemove, appendixIndex)
                  "
                ></attachment-base>
              </v-row>
              <v-row
                v-if="appendixElement[data.appendix][summaryType].length == 0"
                >No appendix {{ summaryType }}</v-row
              >
            </v-col>
          </v-row>
          <v-row v-if="view != 'appendix'">
            <v-col>
              <v-row class="headers">Procedure</v-row>
              <v-row
                v-if="
                  appendixElement[data.appendix].procedure[summaryType]
                    .length == 0
                "
                >No procedure {{ summaryType }}</v-row
              >
              <v-row
                v-for="(elem, index) in appendixElement[data.appendix]
                  .procedure[summaryType]"
                :key="`leadauditor-${index}`"
              >
                <v-col>
                  <v-row
                    ><span v-html="`${elem.identifier}. ${elem.text}`"></span
                  ></v-row>
                  <v-row
                    v-for="e in filterProcedureByRole(elem.elem, 'leadauditor')"
                    :key="
                      `leadauditor-${getCommentOrAttachmentIdentifier(
                        summaryType,
                        e
                      )}`
                    "
                  >
                    <comment-base
                      v-if="summaryType == 'comments'"
                      :comment="e"
                    ></comment-base>
                    <attachment-base
                      v-if="summaryType == 'attachments'"
                      :attachment="e"
                      @remove="
                        toRemove =>
                          handeAttachmentRemove(
                            toRemove,
                            appendixIndex,
                            elem.questionIndex
                          )
                      "
                    ></attachment-base>
                  </v-row>
                  <v-row
                    v-for="e in filterProcedureByRole(elem.elem, 'techadvisor')"
                    :key="
                      `techadvisor-${getCommentOrAttachmentIdentifier(
                        summaryType,
                        e
                      )}`
                    "
                  >
                    <comment-base
                      v-if="summaryType == 'comments'"
                      :comment="e"
                    ></comment-base>
                    <attachment-base
                      v-if="summaryType == 'attachments'"
                      :attachment="e"
                      @remove="
                        toRemove =>
                          handeAttachmentRemove(
                            toRemove,
                            appendixIndex,
                            elem.questionIndex
                          )
                      "
                    ></attachment-base>
                  </v-row>
                  <v-row
                    v-for="e in filterProcedureByRole(
                      elem.elem,
                      'branchsupervisor'
                    )"
                    :key="
                      `branchsupervisor-${getCommentOrAttachmentIdentifier(
                        summaryType,
                        e
                      )}`
                    "
                  >
                    <comment-base
                      v-if="summaryType == 'comments'"
                      :comment="e"
                    ></comment-base>
                    <attachment-base
                      v-if="summaryType == 'attachments'"
                      :attachment="e"
                      @remove="
                        toRemove =>
                          handeAttachmentRemove(
                            toRemove,
                            appendixIndex,
                            elem.questionIndex
                          )
                      "
                    ></attachment-base>
                  </v-row>
                  <v-row
                    v-for="e in filterProcedureByRole(elem.elem, 'manager')"
                    :key="
                      `manager-${getCommentOrAttachmentIdentifier(
                        summaryType,
                        e
                      )}`
                    "
                  >
                    <comment-base
                      v-if="summaryType == 'comments'"
                      :comment="e"
                    ></comment-base>
                    <attachment-base
                      v-if="summaryType == 'attachments'"
                      :attachment="e"
                      @remove="
                        toRemove =>
                          handeAttachmentRemove(
                            toRemove,
                            appendixIndex,
                            elem.questionIndex
                          )
                      "
                    ></attachment-base>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </v-col>
      </v-row>
    </div>
    <v-row :class="view == 'general' || view == 'all' ? '' : 'general-hidden'">
      <v-col>
        <v-row class="headers" id="general">
          General
        </v-row>
        <div v-if="general.length == 0" class="mb-4">
          No general {{ summaryType }}
        </div>
        <v-row
          v-for="elem in general"
          :key="getCommentOrAttachmentIdentifier(summaryType, elem)"
        >
          <comment-base
            v-if="summaryType == 'comments'"
            :comment="elem"
          ></comment-base>
          <attachment-base
            v-if="summaryType == 'attachments'"
            :attachment="elem"
            @remove="toRemove => handeAttachmentRemove(toRemove)"
          ></attachment-base>
        </v-row>
        <div
          v-if="summaryType == 'comments'"
          :class="view == 'general' ? '' : 'general-hidden'"
        >
          <v-row>
            <v-textarea
              ref="commentBox"
              background-color="white"
              outlined
              v-model="commentDraft"
              :disabled="submitting"
            >
            </v-textarea>
          </v-row>
          <v-row>
            <v-col
              ><v-btn
                outlined
                color="#0098DA"
                style="background-color: white; margin-top:10px;"
                @click="submitNewComment"
                :disabled="submitting"
              >
                <div v-if="submitting">Submitting...</div>
                <div v-else>Submit</div>
              </v-btn></v-col
            >
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-col>
</template>
<style scoped>
.general-hidden {
  height: 0px;
  width: 0px;
  opacity: 0;
}
.headers {
  color: #0098da;
  font-weight: 800;
}
#general {
  font-size: 26px;
}
.circle {
  width: 26px;
  height: 26px;
  line-height: 28px;
  border-radius: 50%;
  text-align: center;
  margin-right: 20px;
  color: #fff;
  background: #0098da;
  font-weight: 400;
}
.row {
  margin-left: -12px;
  margin-right: -12px;
}
</style>
<script>
import CommentBase from './CommentBase.vue';
import AttachmentBase from './AttachmentBase.vue';
import { authenticationService } from '@/_auth/authentication.service';
import { auditService } from '@/_services/audit.service';

export default {
  props: ['value', 'view', 'summaryType', 'addGeneralComment'],
  components: {
    CommentBase,
    AttachmentBase
  },
  data() {
    return {
      appendixData: this.value.activities,
      general: [],
      commenting: false,
      commentDraft: null,
      submitting: false,
      overlay: false
    };
  },
  watch: {
    value: function() {
      this.appendixData = this.value.activities;
    }
  },
  computed: {
    appendixElement() {
      var appendixElem = {};
      for (let appIndex in this.appendixData) {
        var comments = [],
          attachments = [];
        if ('undefined' !== typeof this.appendixData[appIndex].comments) {
          comments = this.appendixData[appIndex].comments;
        } else {
          comments = [];
        }
        if ('undefined' !== typeof this.appendixData[appIndex].attachments) {
          attachments = this.appendixData[appIndex].attachments;
        } else {
          attachments = [];
        }

        var commentProcedures = [];
        var attachmentProcedures = [];
        for (let qIndex in this.appendixData[appIndex].questions) {
          var procedureComments = this.appendixData[appIndex].questions[qIndex]
            .comments;
          if (
            'undefined' !== typeof procedureComments &&
            procedureComments.length > 0
          ) {
            commentProcedures.push({
              identifier: this.appendixData[appIndex].questions[qIndex]
                .identifier,
              text: this.appendixData[appIndex].questions[qIndex].text,
              elem: procedureComments
            });
          }

          var procedureAttachments = this.appendixData[appIndex].questions[
            qIndex
          ].attachments;
          if (
            'undefined' !== typeof procedureAttachments &&
            procedureAttachments.length > 0
          ) {
            attachmentProcedures.push({
              identifier: this.appendixData[appIndex].questions[qIndex]
                .identifier,
              questionIndex: qIndex,
              text: this.appendixData[appIndex].questions[qIndex].text,
              elem: procedureAttachments
            });
          }
        }

        appendixElem[this.appendixData[appIndex].appendix] = {
          comments: comments.slice().reverse(),
          attachments: attachments.slice().reverse(),
          procedure: {
            comments: commentProcedures,
            attachments: attachmentProcedures
          }
        };
      }
      return appendixElem;
    }
  },
  methods: {
    getCommentOrAttachmentIdentifier(type, object) {
      if (type === 'comments') {
        return object.commentDate;
      } else {
        return object.attachmentKey;
      }
    },
    filterProcedureByRole(element, role) {
      return element.filter(
        el =>
          (el.commenter && el.commenter.role === role) ||
          (el.attacher && el.attacher.role === role)
      );
    },
    filterByRole(elements, role) {
      return elements
        .filter(
          commentOrAttachment =>
            (commentOrAttachment.commenter != null &&
              commentOrAttachment.commenter.role === role) ||
            (commentOrAttachment.attacher != null &&
              commentOrAttachment.attacher.role === role)
        )
        .slice();
    },
    focusTextbox() {
      this.$refs.commentBox.focus();
    },
    addAttachment(attachment) {
      this.general.push(attachment);
    },
    handeAttachmentRemove(
      toRemove,
      appendixIndex = null,
      questionIndex = null
    ) {
      this.overlay = true;

      const auditId = this.$route.params.id;
      let jsonPath = '';
      let s3Key = toRemove.attachmentKey;
      let onSuccessCallback = () => {};

      // Check atachment type based off defined func arguments
      if (appendixIndex === null && questionIndex === null) {
        // general attachment
        const revertedIndex = this.general.findIndex(
          item => item.attachmentKey === toRemove.attachmentKey
        );
        const toRemoveIndex = this.general.length - revertedIndex - 1;
        jsonPath = `/attachments/${toRemoveIndex}`;
        onSuccessCallback = () => this.general.splice(revertedIndex, 1);
      } else if (appendixIndex !== null && questionIndex === null) {
        // appendix attachment
        const toRemoveIndex = this.appendixData[
          appendixIndex
        ].attachments.findIndex(
          item => item.attachmentKey === toRemove.attachmentKey
        );
        jsonPath = `/activities/${appendixIndex}/attachments/${toRemoveIndex}`;
        onSuccessCallback = () =>
          this.appendixData[appendixIndex].attachments.splice(toRemoveIndex, 1);
      } else {
        // question attachment
        const toRemoveIndex = this.appendixData[appendixIndex].questions[
          questionIndex
        ].attachments.findIndex(
          item => item.attachmentKey === toRemove.attachmentKey
        );
        jsonPath = `/activities/${appendixIndex}/questions/${questionIndex}/attachments/${toRemoveIndex}`;
        onSuccessCallback = () =>
          this.appendixData[appendixIndex].questions[
            questionIndex
          ].attachments.splice(toRemoveIndex, 1);
      }

      // Execute Backend request
      auditService
        .removeAttachment(auditId, jsonPath, s3Key)
        .then(() => {
          onSuccessCallback();
        })
        .catch(error => {
          this.errorText = error;
          console.error(
            `Warning unable to remove attachment for the following reasons:\n${error}\nPlease try again later.`,
            error
          );
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    submitNewComment() {
      if (this.commentDraft !== null) {
        if (this.commentDraft.length > 0) {
          this.submitting = true;
          var path = `/comments`;
          var op = 'add';
          var auditid = this.$route.params.id;
          var currentTime = new Date().toString();
          var value = {
            commentDate: currentTime,
            commenterName: `${authenticationService.currentUserValue.firstName} ${authenticationService.currentUserValue.lastName}`,
            commenter: authenticationService.currentUserValue,
            comment: this.commentDraft
          };
          var objList = [];
          if ('undefined' == typeof this.value['comments']) {
            objList.push({
              op: 'add',
              path: path,
              value: []
            });
          }
          objList.push({
            op: op,
            path: `${path}/`,
            value: value
          });

          auditService
            .updateAudit(auditid, objList)
            .then(response => {
              console.log(response);
              this.general.push(value);
              this.commentDraft = null;
              this.submitting = false;
            })
            .catch(error => {
              this.errorText = error;
              console.error(error);
              console.log(
                `Warning unable to submit comment for the following reasons:\n${error}\nPlease try again later.`
              );
              this.submitting = false;
            });
        }
      }
    }
  },
  created() {
    if (
      'undefined' != typeof this.value &&
      'undefined' != typeof this.value[this.summaryType]
    ) {
      this.general = this.value[this.summaryType].slice().reverse();
    } else {
      this.general = [];
    }
  }
};
</script>
