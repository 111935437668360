<template>
  <v-row noGutters>
    <template>
      <!-- Existing comments button -->
      <v-btn
        icon
        color="#0098DA"
        v-if="value && value.length > 0"
        @click="toggleCommentDisplay"
        style1="z-index: 9999"
      >
        <v-badge :content="value.length" overlap>
          <v-icon>mdi-chat-processing-outline</v-icon>
        </v-badge>
      </v-btn>

      <!-- New comment text entry -->
      <template v-if="showNewCommentEntry">
        <v-textarea
          outlined
          name="newComment"
          v-model="newCommentText"
          label="New Comment"
        ></v-textarea>
        <v-container>
          <v-btn
            @click="addComment"
            :disabled="!newCommentText || !newCommentText.trim()"
            >Submit comment</v-btn
          >
          <v-btn @click="showNewCommentEntry = !showNewCommentEntry"
            >Close</v-btn
          >
        </v-container>
      </template>
    </template>

    <!-- comment display -->
    <template v-if="showComments">
      <template v-for="(comment, index) in value">
        <v-col cols="12" :key="'comment' + index">
          <v-textarea
            solo
            auto-grow
            width="95%"
            readonly
            dense
            height="1"
            :value="getCommentDisplayText(comment)"
            label="Old Comment"
          ></v-textarea>
        </v-col>
      </template>
    </template>

    <!-- New comment button -->
    <v-btn
      v-if="!value || value.length <= 0 || showComments"
      icon
      v-show="!showNewCommentEntry"
      color="#0098DA"
      @click="showNewCommentEntry = !showNewCommentEntry"
      :disabled="disableNewComment"
      absolute
      bottom
      left
      style1="left: 60px; z-index: 9999"
    >
      <v-icon>mdi-chat-plus-outline</v-icon>
    </v-btn>
  </v-row>
</template>

<script>
import { Role } from "@/_auth/role";
import { authenticationService } from "@/_auth/authentication.service";

export default {
  props: ["value"],
  data: () => ({
    showNewCommentEntry: false,
    showComments: false,
    newCommentText: null
  }),
  computed: {
    allowEdit() {
      let currentUser = authenticationService.currentUserValue;
      if (currentUser.roles.includes(Role.Supervisor)) {
        return true;
      }
      return false;
    },
    disableNewComment() {
      return (
        (this.value.questionType == "SUPERVISOR" ||
          this.value.questionType == "SUPERVISOR-REQUIRED") &&
        !this.allowEdit
      );
    }
  },
  methods: {
    addComment() {
      let currentUser = this.$root.$children[0].currentUser;
      let userFullName = currentUser.firstName + " " + currentUser.lastName;
      let newComment = {
        comment: this.newCommentText,
        commentDate: new Date(),
        commenterName: userFullName
      };
      newComment.comment = (newComment.comment || {}).trim();
      this.value.push(newComment);
      this.newCommentText = null;
      this.showNewCommentEntry = false;
      this.triggerSave();
    },
    triggerSave() {
      this.$emit("trigger-save");
    },
    toggleCommentDisplay() {
      this.showComments = !this.showComments;
    },
    getCommentDisplayText(commentMeta) {
      var date = commentMeta.commentDate
        ? new Date(commentMeta.commentDate)
        : null;
      return (
        (commentMeta.commenterName ? commentMeta.commenterName : "----") +
        " (" +
        (date ? date.toLocaleString() : "----") +
        "): " +
        commentMeta.comment
      );
    }
  }
};
</script>

<style></style>
