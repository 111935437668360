<template>
  <v-col style="margin:0;margin-left:10spx;">
    <v-row style="margin:0;border: 4px solid transparent;">
      <div class="aar-header" style="width: 100%">
        <slot v-if="$slots.title" name="title"></slot>
      </div>
    </v-row>
    <v-row style="margin:0;border: 4px solid transparent;">
      <div class="row-content">
        <slot name="row1"></slot>
      </div>
    </v-row>
    <v-row style="margin:0;border: 4px solid transparent;" v-if="$slots.row2">
      <div class="row-content">
        <slot name="row2"></slot>
      </div>
    </v-row>
    <v-row style="margin:0; border: 4px solid transparent;" v-if="$slots.row3">
      <div class="row-content">
        <slot name="row3"></slot>
      </div>
    </v-row>
  </v-col>
</template>
<style scoped>
.row-content,
.aar-header {
  /* background-color: rgba(196, 196, 196, 0.5); */
  border: 2px solid transparent;
  /* padding-top: 10px; */
  width: 100%;
}
.aar-header {
  font-weight: bold;
}
</style>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "Additional Action Required"
    }
  }
};
</script>
