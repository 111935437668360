import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";
import { Role } from "@/_auth/role";
import Home from "@/views/Home.vue";
import LoginPage from "@/views/LoginPage";
import StartAudit from "@/views/StartAudit";
import PerformAudit from "@/views/PerformAudit";
import Appendix from "@/components/new-audits/Appendix";
import CommentSummary from "@/components/new-audits/CommentSummary";
import AttachmentSummary from "@/components/new-audits/AttachmentSummary";
import Login from "@/views/Login";
import ChecklistSummary from "@/components/new-audits/ChecklistSummary";
import { authenticationService } from "../_auth/authentication.service";
import { auditService, auditStatusService } from "../_services/audit.service";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { authorize: [Role.Lead], requiresAuth: true }
  },
  {
    path: "/about",
    name: "About",
    meta: { authorize: [], requiresAuth: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/oldlogin",
    component: LoginPage
  },
  {
    name: "login",
    path: "/login",
    component: Login
  },
  {
    path: "/startaudit",
    component: StartAudit,
    meta: { authorize: [Role.Lead], requiresAuth: true }
  },
  {
    name: "perform-audit",
    path: "/performaudit/:id",
    component: PerformAudit,
    children: [
      { name: "appendix", path: "appendix/:appendixId", component: Appendix },
      {
        name: "comment-summary",
        path: "commentsummary",
        component: CommentSummary
      },
      {
        name: "attachment-summary",
        path: "attachmentsummary",
        component: AttachmentSummary
      },
      {
        name: "checklist-summary",
        path: "checklistsummary",
        component: ChecklistSummary
      }
    ],
    meta: {
      authorize: [Role.Lead, Role.Manager, Role.Supervisor, Role.TechAdvisor],
      requiresAuth: true
    }
  },

  { path: "*", redirect: "/" }
];

export const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeResolve(async (to, from, next) => {
  NProgress.start();

  if (to.path.includes("performaudit")) {
    if (from.params.id != to.params.id) {
      auditService.submittedSubj.next(false);
      auditStatusService.updateAuditStatus({});
    }
  } else {
    auditService.submittedSubj.next(false);
    auditStatusService.updateAuditStatus({});
  }

  window.scrollTo(0, 0);
  if (to.matched.some(record => record.meta.requiresAuth)) {
    await authenticationService
      .isAuthenticated()
      .then(async () => {
        await authenticationService
          .updateUserInfo()
          .then(() => {
            next();
          })
          .catch(e => {
            console.log(e);
            next({ name: "login" });
          });
        // }
      })
      .catch(e => {
        console.log(e);
        next({ name: "login" });
      });
  }
  next();
});

router.afterEach(() => {
  NProgress.done();
});
// Keeping this, might be useful in debugging currentUser setup
// router.beforeEach((to, from, next) => {
//   const { authorize } = to.meta;
//   const currentUser = authenticationService.currentUserValue;
//   window.scrollTo(0, 0);

//   if (authorize) {
//     if (!currentUser) {
//       return next({ path: "/login", query: { returnUrl: to.path } });
//     }

//     // Checks that at least on of the roles in the meta authorize tag belongs to the users role list
//     // if (authorize.length && !authorize.includes(currentUser.role)) {
//     if (
//       authorize.length &&
//       !authorize.some(r => currentUser.roles.indexOf(r) >= 0)
//     ) {
//       return next({ path: "/" });
//     }
//   }
//   next();
// });
