import { BehaviorSubject } from "rxjs";

import { handleResponse } from "@/_auth/handle-response";
// import { requestOptions } from "@/_auth/request-options";
import { Auth } from "aws-amplify";

const currentUserSubject = new BehaviorSubject(false);

export const authenticationService = {
  isAuthenticated,
  updateUserInfo,
  logout,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  }
};

async function isAuthenticated() {
  return await Auth.currentAuthenticatedUser();
}

async function updateUserInfo() {
  if (!currentUserSubject.value) {
    let url =
      process.env.VUE_APP_AUTH_BASE + process.env.VUE_APP_AUTH_AUTHENTICATE_URI;
    return await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Content-Type": "application/json",
        //"Access-Control-Allow-Origin": "*"
      }
    })
      .then(handleResponse)
      .then(user => {
        // localStorage.setItem("currentUser", JSON.stringify(user));
        currentUserSubject.next(user);
        return user;
      });
  }
}

function logout() {
  // localStorage.removeItem("currentUser");
  currentUserSubject.next(false);
  Auth.signOut()
    .then(response => {
      console.log(response);
    })
    .catch(e => {
      console.log(e);
    });
}
