<template>
  <v-card elevation="0">
    <v-row>
      <v-col :class="'question' + (identifier ? ' indented-question' : '')">
        {{ identifier }}
        <span v-html="value.text.replace(/\r?\n/g, '<br/>')"></span>
      </v-col>
      <v-col cols="4" sm="2" md="2" lg="2" xl="1">
        <v-radio-group
          v-model="value.answer"
          class="mt-0 pt-0"
          :disabled="disabled"
        >
          <span>{{ adherenceDisplayedWord }}</span>
          <v-radio
            v-for="(answerChoice, index) in ['YES', 'NO', 'N/A']"
            :key="index"
            :label="answerChoice"
            :value="answerChoice"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
  </v-card>
</template>

<style lang="less"></style>

<script>
export default {
  props: ["value", "disabled"],
  data: () => ({}),
  computed: {
    identifier() {
      if (!this.value.identifier) {
        return null;
      }
      if (this.value.identifier.length > 0) {
        let rv = this.value.identifier;
        if (this.value.identifier != "=>") {
          rv += ".";
        }
        return rv;
      }
      return null;
    },
    adherenceDisplayedWord() {
      if (this.$vuetify.breakpoint.width < 280) {
        return "";
      } else if (this.$vuetify.breakpoint.width < 320) {
        return "Adheres?";
      }
      return "Adherence:";
    }
  },
  methods: {}
};
</script>
