<template>
  <v-row style="padding-bottom: 10px; margin-right: 0px;">
    <v-col>
      <v-row
        class="flex-nowrap justify-space-between"
        style="padding-right: 10px"
      >
        <b
          >{{ calculateDate() }} | {{ calculateUserRole }} |
          {{ calculateUser }}</b
        >
        <div v-if="comment.lastEdited" style="margin-left:5px;font-size:10px;">
          <i>edited at {{ formatDate(comment.lastEdited) }}</i>
        </div>
        <v-row v-if="isUser" style="margin-left:0px">
          <v-icon @click="editMode = true" v-if="enableEdit"
            >mdi-pencil-circle-outline</v-icon
          >
          <v-icon @click="deleteComment" v-if="enableDelete"
            >mdi-close-circle-outline</v-icon
          >
        </v-row>
      </v-row>
      <v-row v-if="!editMode">{{ calculateComment }}</v-row>
      <v-col v-else-if="editMode">
        <v-textarea
          background-color="white"
          outlined
          dense
          v-model="commentText"
        ></v-textarea>
        <v-row class="justify-space-around">
          <v-btn @click="editComment">Edit</v-btn>
          <v-btn @click="editMode = false">Cancel</v-btn>
        </v-row>
      </v-col>
    </v-col>
  </v-row>
</template>
<style scoped>
.row {
  margin-left: 0;
  margin-right: 0;
}
</style>

<script>
import { authenticationService } from "@/_auth/authentication.service";

export default {
  props: ["comment", "enableEdit", "enableDelete"],
  emit: ["edit", "delete"],
  data() {
    return {
      editMode: false,
      submittingEdit: false,
      commentText: ""
    };
  },
  computed: {
    isUser() {
      if (this.comment.commenter != null) {
        return (
          authenticationService.currentUserValue.userId ==
          this.comment.commenter.userId
        );
      } else {
        return false;
      }
    },
    calculateUser() {
      if (
        "undefined" !== typeof this.comment &&
        "undefined" !== typeof this.comment["commenter"]
      ) {
        if (this.comment.commenter.firstName) {
          return (
            this.comment.commenter.firstName +
            " " +
            this.comment.commenter.lastName
          );
        }
      }
      return "Unknown";
    },
    calculateUserRole() {
      if (
        "undefined" !== typeof this.comment &&
        "undefined" !== typeof this.comment["commenter"]
      ) {
        if (this.comment.commenter.role) {
          switch (this.comment.commenter.role) {
            case "leadauditor":
              return "Lead Auditor";
            case "techadvisor":
              return "Tech Advisor";
            case "branchsupervisor":
              return "Branch Supervisor";
            case "manager":
              return "Manager";
            default:
              return "";
          }
        }
      }
      return "Unknown";
    },
    calculateComment: {
      get: function() {
        if (this.comment != null && this.comment["comment"] != null) {
          if (this.comment.comment.length > 0) {
            return this.comment.comment;
          }
        }
        return "Unknown";
      },
      set: function(newComment) {
        return newComment;
      }
    }
  },
  methods: {
    addZero(val) {
      if (val <= 9) {
        return `0${val}`;
      }
      return val;
    },
    calculateDate() {
      if (
        "undefined" !== typeof this.comment &&
        "undefined" !== typeof this.comment["commentDate"]
      ) {
        if (this.comment.commentDate.length > 0) {
          var date = new Date(this.comment.commentDate);
          return `${this.addZero(date.getMonth() + 1)}/${this.addZero(
            date.getDate()
          )}/${date.getFullYear()} ${this.addZero(
            date.getHours()
          )}:${this.addZero(date.getMinutes())}`;
        }
      }
      return "Unknown";
    },
    formatDate(dateToFormat) {
      if (dateToFormat.length > 0) {
        var date = new Date(dateToFormat);
        return `${this.addZero(date.getMonth() + 1)}/${this.addZero(
          date.getDate()
        )}/${date.getFullYear()} ${this.addZero(
          date.getHours()
        )}:${this.addZero(date.getMinutes())}`;
      }
      return "Unknown";
    },
    editComment() {
      if (this.commentText.trim().length != 0) {
        this.comment.comment = this.commentText;
        this.comment.lastUpdated = new Date().toString();
        this.$emit("edit", this.commentText);
        this.editMode = false;
      }
    },
    deleteComment() {
      this.$emit("delete");
    }
  },
  mounted() {
    this.commentText = this.calculateComment;
  }
};
</script>
