<template>
  <v-col class="justify-space-around section" :style="highlightStyle">
    <v-row class="flex-nowrap">
      <v-col style="max-width: 95%" class="flex-grow-1 flex-shrink-0">
        <slot name="main"></slot>
      </v-col>
      <v-col cols="1" v-if="!noComments">
        <v-row>
          <div v-if="isTechAdvisor || isQuestionAEleven">
            <v-icon
              @click="
                activateComments();
                scrollToBottom();
              "
              >mdi-comment-processing-outline</v-icon
            >
          </div>
          <div v-if="!isTechAdvisor && !isQuestionAEleven">
            <v-icon @click="activateComments()"
              >mdi-comment-processing-outline</v-icon
            >
          </div>
        </v-row>
        <v-row>
          <div v-if="isTechAdvisor || isQuestionAEleven">
            <v-icon
              @click="
                activateAttachments();
                scrollToBottom();
              "
              >mdi-note-plus-outline</v-icon
            >
          </div>
          <div v-if="!isTechAdvisor && !isQuestionAEleven">
            <v-icon @click="activateAttachments()"
              >mdi-note-plus-outline</v-icon
            >
          </div>
        </v-row>
        <v-row>
          <div v-if="isManager && isQuestionAEleven">
            <v-icon
              @click="printQuestion()"
              >mdi-printer-outline</v-icon
            >
          </div>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      v-if="
        !noComments &&
          (expandTabSection ||
            this.commentList.length > 0 ||
            this.attachmentList.length > 0)
      "
      class="justify-center"
      style="margin: 0px;"
      id="commentSection"
    >
      <v-tabs
        v-model="tab"
        :slider-color="sliderColor"
        background-color="transparent"
        optional
      >
        <v-tab @click="activateComments()">
          <v-badge
            v-if="commentList.length > 0"
            :content="commentList.length.toString()"
          >
            <v-icon color="rgba(0, 0, 0, 0.6)"
              >mdi-comment-processing-outline</v-icon
            >
          </v-badge>
          <v-icon color="rgba(0, 0, 0, 0.6)" v-else
            >mdi-comment-processing-outline</v-icon
          >
        </v-tab>

        <v-tab @click="activateAttachments()">
          <v-badge
            v-if="attachmentList.length > 0"
            :content="(attachmentList.length - attachmentList.filter(item => !item.attachmentKey).length).toString()"
          >
            <v-icon color="rgba(0, 0, 0, 0.6)">mdi-note-plus-outline</v-icon>
          </v-badge>
          <v-icon color="rgba(0, 0, 0, 0.6)" v-else
            >mdi-note-plus-outline</v-icon
          >
        </v-tab>
      </v-tabs>
      <v-row style="margin-left:15px;margin-right:18px;">
        <v-col
          class="flex-grow-1 flex-shrink-0"
          style="background-color:transparent;"
        >
          <v-tabs-items v-model="tab" style="background-color:transparent;">
            <v-tab-item>
              <!-- Display all comments -->
              <div v-if="commentList.length == 0">
                <div class="text-style"><b>Question Comments</b></div>
                <div class="text-style">
                  There are currently no Question Level Comments
                </div>
              </div>
              <v-row
                class="comment"
                v-for="(comment, index) in commentList"
                :key="index"
              >
                <comment-base
                  :enableEdit="true"
                  @edit="editComment($event, index)"
                  @delete="deleteComment(index)"
                  :enableDelete="true"
                  :comment="comment"
                ></comment-base>
                <!-- <v-icon>mdi-pencil-box-outline</v-icon> -->
              </v-row>
              <!-- Add more comments -->
              <div :class="commenting ? '' : 'hidden-textbox'">
                <v-textarea
                  ref="commentBox"
                  v-model="commentDraft"
                  background-color="white"
                  :disabled="submitting"
                  outlined
                ></v-textarea>
                <v-row>
                  <v-col
                    ><v-btn
                      outlined
                      color="#0098DA"
                      style="background-color: white; margin-top:10px;"
                      @click="submitNewComment"
                      :disabled="submitting"
                    >
                      <div v-if="submitting">Submitting...</div>
                      <div v-else>Submit</div>
                    </v-btn></v-col
                  >
                  <v-col
                    ><v-btn
                      outlined
                      color="#0098DA"
                      style="background-color: white; margin-top:10px;"
                      @click="commentToggle"
                      >Cancel</v-btn
                    ></v-col
                  >
                </v-row>
              </div>
              <v-btn
                outlined
                color="#0098DA"
                style="background-color: white; margin-top:10px;"
                v-if="!commenting"
                @click="commentToggle"
              >
                New Comment
              </v-btn>
            </v-tab-item>
            <v-tab-item>
              <v-card style="padding:20px;">
                <div v-if="attachmentList.length == 0">
                  <div class="text-style"><b>Question Attachments</b></div>
                  <div class="text-style">
                    There are currently no Question Level Attachments
                  </div>
                </div>
                <div
                  class="attachment"
                  v-for="(attachment, index) in attachmentList"
                  :key="attachment.attachmentKey"
                  v-else
                >
                  <attachment-base
                    :attachment="attachment"
                    @remove="toRemove => handeAttachmentRemove(toRemove, index)"
                  ></attachment-base>
                </div>
                <FileUpload
                  :attachmentList="attachmentList"
                  :appendixIndex="appendixIndex"
                  :questionIndex="questionIndex"
                  :counter="true"
                  @attachmentAdded="attachmentAdded"
                />
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-row>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-col>
</template>
<style scoped>
.hidden-textbox {
  height: 0;
  width: 0;
  opacity: 0;
}
.v-window {
  overflow: visible;
}
.v-icon {
  color: #0098da;
}
.v-tabs {
  padding-left: 30px;
  padding-right: 30px;
  background-color: transparent;
}
.v-tab {
  height: 60px;
}
.section {
  margin-bottom: 20px;
}
.section:hover {
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.section-active {
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.text-style {
  margin-bottom: 20px;
}
.highligher-style {
  border-color: 'red';
  border-style: 'solid';
  border-left-width: 'thin thin thin thick';
}
</style>
<script>
import { auditService } from '@/_services/audit.service';
import { authenticationService } from '@/_auth/authentication.service';
import { pdfService } from '@/_services/pdf.service';
import { RoleCheck } from '@/_auth/role';
import CommentBase from './CommentBase';
import AttachmentBase from './AttachmentBase';
import FileUpload from '@/components/FileUpload';

export default {
  props: {
    value: Object,
    appendixIndex: Number,
    currentUser: String,
    isManager: Boolean,
    noComments: {
      type: Boolean,
      default: false
    },
    questionIndex: Number,
    auditFrom: String,
    auditTo: String,
    location: String
  },
  components: { CommentBase, AttachmentBase, FileUpload },
  data() {
    return {
      selected: false,
      tab: null,
      expandTabSection: false,

      //comments
      newComment: null,
      commenting: false,
      commentDraft: null,
      submitting: false,
      commentList: [],

      //attachments
      attachmentList: [],

      //errorHandling
      errorText: '',
      isLead: false,
      isSupervisor: false,

      overlay: false
    };
  },
  computed: {
    highlightStyle() {
      if (this.highlight() == '') {
        return '';
      } else {
        return `border-style: solid; border-color: ${this.highlight()}; border-width: 1px 1px 1px 10px;background-color:white`;
      }
    },
    sliderColor() {
      return this.expandTabSection ? 'primary' : 'transparent';
    },
    sectionClass() {
      return this.selected ? 'section-active' : 'section';
    },
    attachments() {
      if (
        'undefined' === typeof this.value['attachments'] &&
        this.value.attachments
      ) {
        return this.value.attachments;
      }
      return [];
    },
    isTechAdvisor() {
      return RoleCheck.isTechAdvisor(this.$parent.$parent.currentUser);
    },
    isQuestionAEleven() {
      return this.value.sortOrder == 11;
    }
  },
  watch: {
    $route() {
      this.updateComments();
      this.updateAttachments();
    }
  },
  methods: {
    highlight() {
      if (RoleCheck.isLead(authenticationService.currentUserValue)) {
        // if (auditService.submittedValue && this.leadAnswerIncomplete()) {
        if (auditService.submittedValue && this.leadAnswerIncompleteBackend()) {
          return '#FF0000';
        }
      } else if (
        RoleCheck.isSupervisor(authenticationService.currentUserValue) ||
        RoleCheck.isManager(authenticationService.currentUserValue)
      ) {
        if (
          (auditService.submittedValue &&
            this.value.questionType.includes('SUPERVISOR') &&
            (this.value.answered == null || this.value.answer == null)) ||
          (this.value.answered != null &&
            this.value.answer != null &&
            (this.value.answer.toUpperCase() == 'NO' ||
              this.value.answer.toUpperCase() == 'NA') &&
            this.value.comments.length == 0)
        ) {
          return '#FF0000';
        } else if (this.value.questionType.includes('SUPERVISOR')) {
          return '#0098DA';
        } else if (
          this.value.answer == 'NO' ||
          this.value.answer == 'NA' ||
          this.checkSubitemsNo()
        ) {
          return 'orange';
        }
      }
      return '';
    },
    checkSubitemsNo() {
      if (this.value.subitems != null) {
        for (let subitem in this.value.subitems) {
          if (
            this.value.subitems[subitem].answer == 'NO' ||
            this.value.subitems[subitem].answer == 'NA'
          ) {
            return true;
          }
        }
      }
      return false;
    },
    leadAnswerIncompleteBackend() {
      var map = auditService.errMap.value;
      var appLetter = this.$route.params.appendixId;
      var identifier = parseInt(this.value.identifier);
      if (map && map[appLetter] && map[appLetter][identifier]) {
        return true;
      }
      return false;
    },
    leadAnswerIncomplete() {
      if (this.appendixIndex == 0 && this.value.identifier == 10) {
        if (auditService.a10NotValid.value != null) {
          return auditService.a10NotValid.value;
        } else {
          return false;
        }
      } else if (this.appendixIndex == 4 && this.value.identifier == 1) {
        var leadE1 = this.value.subitems[1].answer;
        return (
          leadE1 == null ||
          (leadE1 != null &&
            (leadE1 == 'NO' || leadE1 == 'NA') &&
            this.value.comments.length == 0)
        );
      } else {
        switch (this.value.questionType) {
          case 'BASIC':
            return (
              !this.value.answered ||
              (this.value.answer != null &&
                (this.value.answer == 'NO' ||
                  this.value.answer == 'NA' ||
                  this.checkSubitemsNo()) &&
                this.value.comments.length == 0)
            );
          case 'FROMTO':
            return (
              this.value.answer == null ||
              this.value.answerFromToDates[0] == null ||
              this.value.answerFromToDates[0] == '' ||
              this.value.answerFromToDates[1] == null ||
              this.value.answerFromToDates[1] == '' ||
              (this.value.answer != null &&
                (this.value.answer == 'NO' || this.value.answer == 'NA') &&
                this.value.comments.length == 0)
            );
          default:
            break;
        }
      }
      return false;
    },
    scrollToBottom() {
      window.scrollTo(0, document.body.scrollHeight);
    },
    activateComments() {
      if (this.tab == 0 && this.expandTabSection == true) {
        this.tab = null;
        this.expandTabSection = false;
      } else {
        this.tab = 0;
        this.expandTabSection = true;
      }
    },
    activateAttachments() {
      if (this.tab == 1 && this.expandTabSection == true) {
        this.tab = null;
        this.expandTabSection = false;
      } else {
        this.tab = 1;
        this.expandTabSection = true;
      }
    },
    closeTabSection() {
      this.expandTabSection = false;
      this.tab = null;
    },
    attachmentAdded(attachment) {
      this.attachmentList.push(attachment);
    },
    /**
     * Handler function which takes care of attachment removal
     * @param {*} toRemove - attachment object which we are about to remove
     * @param {*} index - index from attachmentList array - this array is reversed version of original array from db
     */
    handeAttachmentRemove(toRemove, index) {
      this.overlay = true;
      // get index of attachment that we are about to remove
      const toRemoveIndex = this.attachmentList.length - index - 1;
      const auditId = this.$route.params.id;
      const jsonPath = `/activities/${this.appendixIndex}/questions/${this.questionIndex}/attachments/${toRemoveIndex}`;
      // execute Backend request
      auditService
        .removeAttachment(auditId, jsonPath, toRemove.attachmentKey)
        .then(() => {
          this.attachmentList.splice(index, 1);
        })
        .catch(error => {
          this.errorText = error;
          console.error(
            `Warning unable to remove attachment for the following reasons:\n${error}\nPlease try again later.`,
            error
          );
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    commentToggle() {
      this.commenting = !this.commenting;
      if (this.commenting) {
        this.$refs.commentBox.focus();
      }
    },
    submitNewComment() {
      if (this.commentDraft !== null) {
        if (this.commentDraft.length > 0) {
          this.submitting = true;
          // check to see if call successful
          // var appendixId = this.$route.params.appendixId;
          var path = `/activities/${this.appendixIndex}/questions/${this.questionIndex}/comments`;
          var op = 'add';
          var auditid = this.$route.params.id;
          var currentTime = new Date().toString();
          var value = {
            commentDate: currentTime,
            commenterName: `${authenticationService.currentUserValue.firstName} ${authenticationService.currentUserValue.lastName}`,
            commenter: authenticationService.currentUserValue,
            comment: this.commentDraft
          };

          var objList = [];
          if ('undefined' == typeof this.value['comments']) {
            objList.push({
              op: 'add',
              path: path,
              value: []
            });
          }

          objList.push({
            op: op,
            path: `${path}/`,
            value: value
          });

          auditService
            .updateAudit(auditid, objList)
            .then(response => {
              console.log(response);
              this.commentList.push(value);
              this.commentDraft = null;
              this.submitting = false;
            })
            .catch(error => {
              this.errorText = error;
              console.error(error);
              console.log(
                `Warning unable to submit comment for the following reasons:\n${error}\nPlease try again later.`
              );
              this.submitting = false;
            });
        }
      }
    },
    updateComments() {
      if ('undefined' !== typeof this.value['comments']) {
        if ('string' === typeof this.value.comments) {
          this.commentList = [
            {
              commentDate: 'Unknown',
              commenterName: 'Unknown',
              comment: this.value.comments
            }
          ];
        }
        if (
          'object' === typeof this.value.comments &&
          Array.isArray(this.value.comments)
        ) {
          this.commentList = this.value.comments.slice().reverse();
        }
      } else {
        this.commentList = [];
      }
      this.commenting = false;
      this.commentDraft = null;
    },
    updateAttachments() {
      if ('undefined' !== typeof this.value['attachments']) {
        if (Array.isArray(this.value.attachments)) {
          this.attachmentList = this.value.attachments.slice().reverse();
        }
      } else {
        this.attachmentList = [];
      }
    },
    editComment(updates, index) {
      // this.commentList[index].comment = updates;
      this.overlay = true;
      let commentIndex = this.commentList.length - index - 1;
      var path = `/activities/${this.appendixIndex}/questions/${this.questionIndex}/comments/${commentIndex}`;
      var objVal = this.commentList[index];
      objVal.comment = updates;
      objVal.lastEdited = new Date().toString();
      auditService
        .updateAudit(this.$route.params.id, [
          {
            op: 'replace',
            path: path,
            value: objVal
          }
        ])
        .then(response => {
          console.log(response);
          this.commentList[index] = objVal;
          this.overlay = false;
        })
        .catch(error => {
          this.errorText = error;
          console.error(error);
          console.log(
            `Warning unable to submit comment for the following reasons:\n${error}\nPlease try again later.`
          );
          this.submitting = false;
          this.updateComments();
          this.overlay = false;
        });
    },
    deleteComment(index) {
      this.overlay = true;
      let commentIndex = this.commentList.length - index - 1;
      var path = `/activities/${this.appendixIndex}/questions/${this.questionIndex}/comments/${commentIndex}`;
      auditService
        .updateAudit(this.$route.params.id, [
          {
            op: 'remove',
            path: path,
            value: this.value.comments[commentIndex]
          }
        ])
        .then(response => {
          console.log(response);
          this.commentList.splice(index, 1);
          this.overlay = false;
        })
        .catch(error => {
          this.errorText = error;
          console.error(error);
          console.log(
            `Warning unable to submit comment for the following reasons:\n${error}\nPlease try again later.`
          );
          this.submitting = false;
          this.overlay = false;
        });
    },
    printQuestion() {
      pdfService
      .printQuestionA11(this.value, {
        leadAuditor: this.currentUser,
        location: this.location,
        startDate: this.auditFrom,
        endDate: this.auditTo
      })
      .then(() => console.log("PDF for question 11 successfully created."))
      .catch(error => {
        console.log("Error generating PDF: " + error);
      });
    }
  },
  created() {
    this.updateComments();
    this.updateAttachments();
  }
};
</script>
