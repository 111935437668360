<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    color="#012957"
    :mini-variant.sync="miniDrawer"
    permanent
    touchless
    :width="navWidth()"
    style="z-index: 1000"
  >
    <v-list class="drawer-list">
      <!-- If the drawer is collapsed -->
      <div v-if="miniDrawer">
        <v-list-item @click="miniDrawer = !miniDrawer">
          <v-list-item-action>
            <v-icon>mdi-menu</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item>
          <v-btn
            link
            :to="{ path: '/' }"
            icon
            width="24px"
            @click.stop="miniDrawer = miniDrawer"
          >
            <v-icon>mdi-home</v-icon>
          </v-btn>
        </v-list-item>
      </div>
      <!-- if the mini drawer is collapsed and we are in perform audit -->
      <div
        v-if="
          miniDrawer && !isTechAdvisor && isPerformAudit && activities != null
        "
        class="mt-10 appendix-summary"
      >
        <v-list-item
          v-for="activity in activities"
          class="pl-0 mb-5"
          :key="activity.appendix"
          @click.stop="appendixClicked(activity.appendix)"
        >
          <v-avatar
            size="36"
            color="#0298da"
            class="text-center mx-auto"
            v-if="!submitted"
          >
            <span class="white--text">{{ activity.appendix }}</span>
          </v-avatar>
          <v-badge
            color="orange"
            icon="mdi-exclamation"
            left
            overlap
            v-else-if="activity.appendix in appendixWithErrors"
          >
            <v-avatar size="36" color="#0298da" class="text-center mx-auto">
              <span class="white--text">{{ activity.appendix }}</span>
            </v-avatar>
          </v-badge>
          <v-badge color="green" icon="mdi-check" left overlap v-else>
            <v-avatar size="36" color="#0298da" class="text-center mx-auto">
              <span class="white--text">{{ activity.appendix }}</span>
            </v-avatar>
          </v-badge>
        </v-list-item>

        <v-list-item
          class="summary-icon mb-5"
          v-for="view in summaryViews"
          :key="view.name"
        >
          <v-btn
            link
            :to="{ name: view.link }"
            icon
            width="24px"
            @click.stop="miniDrawer = miniDrawer"
          >
            <v-icon>{{ view.icon }}</v-icon>
          </v-btn>
        </v-list-item>
      </div>
      <div v-if="!miniDrawer">
        <v-btn
          link
          :to="{ path: '/' }"
          icon
          class="ml-8"
          @click="miniDrawer = !miniDrawer"
        >
          <v-icon>mdi-home</v-icon>
        </v-btn>
        <v-btn @click="miniDrawer = !miniDrawer" icon class="btn-close-drawer">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="d-flex flex-column" style="height: 100%">
        <!-- if we are in perform audit and the drawer is expanded -->
        <v-container v-if="!miniDrawer && !isTechAdvisor && isPerformAudit">
          <div>
            <v-btn
              color="primary"
              class="ml-4 my-5"
              @click="startNewAudit"
              v-if="isLead"
            >
              Start New Audit
            </v-btn>
            <v-divider class="ml-5 mt-5 mr-5"></v-divider>
            <h3 class="ma-5">Open Audit Checklists</h3>
          </div>

          <v-list-item
            v-for="activity in activities"
            class="pl-0 mb-3 ml-3"
            :key="activity.appendix"
            @click.stop="appendixClicked(activity.appendix)"
          >
            <v-row align-center>
              <v-col cols="1">
                <v-avatar
                  size="36"
                  color="#0298da"
                  left
                  class="text-center mx-auto ml-2"
                  v-if="!submitted"
                >
                  <span class="white--text">{{ activity.appendix }}</span>
                </v-avatar>
                <v-badge
                  color="orange"
                  icon="mdi-exclamation"
                  left
                  overlap
                  class="ml-2"
                  v-else-if="activity.appendix in appendixWithErrors"
                >
                  <v-avatar
                    size="36"
                    color="#0298da"
                    class="text-center mx-auto"
                  >
                    <span class="white--text">{{ activity.appendix }}</span>
                  </v-avatar>
                </v-badge>
                <v-badge
                  color="green"
                  icon="mdi-check"
                  left
                  overlap
                  class="ml-2"
                  v-else
                >
                  <v-avatar
                    size="36"
                    color="#0298da"
                    class="text-center mx-auto"
                  >
                    <span class="white--text">{{ activity.appendix }}</span>
                  </v-avatar>
                </v-badge>
              </v-col>
              <v-col cols="11">
                <p white class="ml-3">
                  {{ activity.name }}
                </p>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item
            v-for="view in summaryViews"
            :key="view.name"
            link
            class="ml-2"
            :to="{ name: view.link }"
            @click="miniDrawer = !miniDrawer"
          >
            <v-row>
              <v-col cols="1">
                <v-icon>{{ view.icon }} </v-icon>
              </v-col>
              <v-col cols="11">
                <p white>{{ view.name }}</p>
              </v-col>
            </v-row>
          </v-list-item>
        </v-container>
        <!-- if drawer is open and we aren't in the perform audit page -->
        <v-container v-if="!miniDrawer && !isPerformAudit">
          <div>
            <div class="mt-10"></div>
            <v-divider class="ml-5 mt-10 mr-5"></v-divider>
            <h3 class="mt-5 ml-5">Open Audit Checklists</h3>
            <p class="mt-5 ml-5">
              Keep track of your current Audit progress here.
            </p>
            <p v-if="isLead" class="mt-5 ml-5">
              <a
                link
                :to="{ path: '/performaudit' }"
                @click="miniDrawer = !miniDrawer"
              >
                Start a new audit</a
              >
            </p>
          </div>
        </v-container>
        <v-container
          class="mt-auto"
          v-if="!miniDrawer && (isTechAdvisor || !isPerformAudit)"
        >
          <v-row class="mx-5">
            <v-avatar size="36" color="grey" class="text-center"
              ><span class="white--text">{{ currentUserInitials }}</span>
            </v-avatar>
            <h3
              class="d-flex flex-row align-self-center ml-4"
              v-if="currentUser"
              align="center"
              justify="center"
            >
              Welcome
              <span class="ml-1 "
                >{{ currentUser.firstName }}
                {{ currentUser.lastName }}
              </span>
            </h3>
          </v-row>
          <!-- <v-row>
              <v-switch
                class="ml-3"
                v-model="$vuetify.theme.dark"
                secondary
                label="Dark Mode"
              ></v-switch>
            </v-row> -->
          <v-row class="mx-5">
            <v-btn link @click="logout()" color="white" outlined class="mt-10">
              <v-icon>mdi-logout-variant mdi-rotate-180</v-icon>
              <span class="pl-2">Logout</span>
            </v-btn>
          </v-row>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-divider class="mt-5 mb-10">inset</v-divider>
              </v-col>
            </v-row>
            <v-img
              class="mx-auto logo"
              src="/img/socalwhite.png"
              width="50%"
              max-height="300px"
              max-width="300px"
              contain
              left
            ></v-img>
            <v-row>
              <v-col cols="12">
                <p white class="mt-10">
                  © 2021 Southern California Gas Company. <br />
                  All copyright and trademark rights reserved.
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-container>
      </div>
    </v-list>
    <v-menu top offset-x v-if="miniDrawer">
      <template v-slot:activator="{ on, attrs }">
        <v-avatar
          size="36"
          color="grey"
          class="text-center mx-auto"
          style="position: fixed; bottom: 10px; left:10px"
          v-bind="attrs"
          v-on="on"
          ><span class="white--text">{{ currentUserInitials }}</span>
        </v-avatar>
      </template>
      <v-list>
        <v-list-item link @click="app.logout">
          <v-list-item-icon class="mr-5">
            <v-icon dark color="#7E6990">mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-navigation-drawer>
</template>

<style lang="scss" scoped>
.drawer-list {
  height: 100%;
  width: 100%;
  position: fixed;
}

.theme--dark.v-divider {
  opacity: 1;
}

label.v-label.theme--light {
  color: #ffffff;
}

.btn-close-drawer {
  right: 20px;
  position: absolute;
}

.appendix-summary {
  margin-top: 0px !important;
  padding-left: 10px;
}

.v-application .mb-5 {
  margin-bottom: 5px !important;
}

.v-application .ma-5 {
  margin-left: 20px !important;
  margin-right: 20px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.summary-icon {
  padding-left: 5px;
}

.logo {
  margin-left: 10px !important;
}

// Hack to get uppercase words to TitleCase (e.g. HELLO -> Hello)
.appendix-name {
  text-transform: lowercase !important;
}

.appendix-name::first-letter {
  text-transform: uppercase !important;
}

.v-navigation-drawer:not(.v-navigation-drawer--mini-variant)
  .v-navigation-drawer__content {
  margin: 10px;
}

.v-btn {
  &.v-btn--icon::before {
    opacity: 0;
  }
}

.v-application .mb-3 {
  margin-bottom: 0px !important;
}

.v-application p {
  margin-bottom: 0px !important;
}

.v-application .mt-5 {
  margin-top: 0px !important;
}

.theme--light.v-navigation-drawer {
  .v-divider {
    border-color: #ffffff;
  }

  a[link] {
    color: #0296d8;
  }

  .v-badge {
    i.v-icon.v-icon {
      color: green;
      font-size: 20px;
      position: absolute;
      left: 3px;
    }
  }

  i.v-icon.v-icon,
  a.v-list-item--active i.v-icon.v-icon {
    color: #ffffff;
  }

  h3,
  p {
    color: #ffffff;
  }

  .activity {
    margin-bottom: -40px;
  }
}
</style>
<script>
import { RoleCheck } from "@/_auth/role";
import commonMixin from "@/mixins/common";
import { router } from "@/router";
import { authenticationService } from "@/_auth/authentication.service";
import { auditService, auditStatusService } from "@/_services/audit.service";

export default {
  name: "SideNav",

  components: {},
  mixins: [commonMixin],
  props: ["activities", "auditId"],

  data: () => ({
    drawer: true,
    miniDrawer: true,
    activityByAppendix: null,
    currentUser: null,
    currentUserInitials: "NA",
    isTechAdvisor: false,
    appendixWithErrors: {},
    submitted: false,
    summaryViews: [
      {
        name: "Audit Comments",
        icon: "mdi-comment-text-outline",
        link: "comment-summary"
      },
      {
        name: "Audit Attached Documents",
        icon: "mdi-text-box-multiple-outline",
        link: "attachment-summary"
      },
      {
        name: "Check and Submit Audit",
        icon: "mdi-checkbox-marked-circle",
        link: "checklist-summary"
      }
    ]
  }),

  computed: {
    isPerformAudit() {
      return this.$route.path.startsWith("/performaudit");
    },
    mainClasses() {
      if (this.$route.name == "login") {
        return ["dark-background", "main"];
      }
      if (!this.$vuetify.theme.dark) {
        return ["main-background", "main"];
      } else {
        return ["main"];
      }
    },
    isLead() {
      return RoleCheck.isLead(authenticationService.currentUserValue);
    },
    isManager() {
      return RoleCheck.isManager(authenticationService.currentUserValue);
    }
  },
  mounted() {
    if (!authenticationService.currentUserValue) {
      authenticationService.updateUserInfo().then(user => {
        this.currentUser = user;
        this.currentUserInitials =
          user.firstName.charAt(0).toUpperCase() +
          user.lastName.charAt(0).toUpperCase();
        this.isTechAdvisor = RoleCheck.isTechAdvisor(this.currentUser);
      });
    } else {
      this.currentUser = authenticationService.currentUserValue;
      this.currentUserInitials =
        this.currentUser.firstName.charAt(0).toUpperCase() +
        this.currentUser.lastName.charAt(0).toUpperCase();
      this.isTechAdvisor = RoleCheck.isTechAdvisor(this.currentUser);
    }
  },
  methods: {
    startNewAudit() {
      this.miniDrawer = true;
      this.$router.push("/performaudit", () => {});
    },
    navWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "80vw";
        case "sm":
          return "80vw";
        case "md":
          return "60vw";
        case "lg":
          return "60vw";
        case "xl":
          return "60vw";
        default:
          return "80vw";
      }
    },
    logout() {
      this.miniDrawer = !this.miniDrawer;
      authenticationService.logout();
    },

    appendixClicked(id) {
      if (!this.miniDrawer) {
        this.miniDrawer = true;
      }
      router.push({ name: "appendix", params: { appendixId: id } }, () => {});
    }
  },
  created() {
    this.subscription = auditStatusService
      .getAuditStatus()
      .subscribe(status => {
        this.appendixWithErrors = auditStatusService.appendixWithErrors(status);
        this.submitted = auditService.submittedValue;
      });
  }
};
</script>
