var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.auditListLoaded)?_c('div',[_c('v-container',[_c('v-row',{staticClass:"section-header section-margin",style:({ color: _vm.textColor('black') }),attrs:{"cols":"12"}},[(!_vm.isManager)?_c('span',[_vm._v("Existing Audits")]):_vm._e(),(_vm.isManager)?_c('span',[_vm._v("Approved Audits")]):_vm._e()]),[(_vm.outstandingAuditsCount == 0)?_c('v-row',{style:({ color: _vm.textColor('black') })},[_vm._v("You have no outstanding "+_vm._s(_vm.auditPlural)+" ")]):_vm._e(),(_vm.outstandingAuditsCount > 0)?_c('v-row',{style:({ color: _vm.textColor('black') })},[_vm._v(" You have "),_c('a',{style:({ color: _vm.textColor('red') }),attrs:{"strong":"","id":"space"}},[_vm._v(_vm._s(_vm.outstandingAuditsCount)+" ")]),_vm._v(" outstanding "+_vm._s(_vm.auditPlural)+" ")]):_vm._e(),(_vm.auditList.length > 0)?_c('v-row',{staticClass:"section-margin"},[_c('v-simple-table',{staticStyle:{"border":"1px solid lightgrey"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left",on:{"click":function($event){return _vm.sortAudits('dateStarted')}}},[_c('a',{style:({ color: _vm.textColor('black') })},[_vm._v("Start Date")]),(
                        _vm.sorting.columnName === 'dateStarted' &&
                          _vm.sorting.direction === 'desc'
                      )?_c('v-icon',{staticClass:"space",style:({ color: _vm.textColor('gray'), cursor: 'pointer' }),attrs:{"small":""}},[_vm._v("mdi-arrow-down-thin")]):_vm._e(),(
                        _vm.sorting.columnName === 'dateStarted' &&
                          _vm.sorting.direction === 'asc'
                      )?_c('v-icon',{staticClass:"space",style:({ color: _vm.textColor('gray'), cursor: 'pointer' }),attrs:{"small":""}},[_vm._v("mdi-arrow-up-thin")]):_vm._e()],1),_c('th',{staticClass:"text-left",on:{"click":function($event){return _vm.sortAudits('officeName')}}},[_c('a',{style:({ color: _vm.textColor('black') })},[_vm._v("Branch Office")]),(
                        _vm.sorting.columnName === 'officeName' &&
                          _vm.sorting.direction === 'desc'
                      )?_c('v-icon',{staticClass:"space",style:({ color: _vm.textColor('gray') }),attrs:{"small":""}},[_vm._v("mdi-arrow-down-thin")]):_vm._e(),(
                        _vm.sorting.columnName === 'officeName' &&
                          _vm.sorting.direction === 'asc'
                      )?_c('v-icon',{staticClass:"space",style:({ color: _vm.textColor('gray') }),attrs:{"small":""}},[_vm._v("mdi-arrow-up-thin")]):_vm._e()],1),(_vm.isManager || _vm.isSupervisor)?_c('th',{staticClass:"text-left"},[_c('a',{style:({ color: _vm.textColor('black') })},[_vm._v("Status")])]):_vm._e()])]),_c('tbody',_vm._l((_vm.sortedAuditList),function(audit){return _c('tr',{key:audit.auditId,on:{"click":function($event){return _vm.testAuditNav(audit.auditId)}}},[_c('td',[_vm._v(" "+_vm._s(_vm.formatDateForList(audit.dateStarted))+" ")]),_c('td',[_c('a',[_vm._v(_vm._s(audit.officeName))])]),(_vm.isManager || _vm.isSupervisor)?_c('td',[_vm._v(" "+_vm._s(audit.statusCode)+" ")]):_vm._e()])}),0)]},proxy:true}],null,false,2806079635)})],1):_vm._e()],(!_vm.isManager)?_c('v-row',{staticClass:"section-margin",style:({ color: _vm.textColor('black') })},[_c('v-icon',{staticClass:"space",style:({ color: _vm.textColor('black') })},[_vm._v("mdi-eye-outline")]),_vm._v(" You will not see past audits once they are marked complete. ")],1):_vm._e(),_c('v-row')],2)],1):_vm._e(),(!_vm.auditListLoaded)?_c('h3',[_vm._v(" Loading audit list... ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }