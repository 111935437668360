<template>
  <div>
    <template v-if="!isSupervisor && !isManager">
      <basic-question :disabled="disabled" v-model="value" @update="update">
      </basic-question>
      <v-row style="margin:0; padding:10px">
        <!-- From -->
        <v-col>
          <v-menu
            v-model="dateFromMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :disabled="disabled"
                v-model="dateFrom"
                label="From date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                @focus="dateFromMenu = true"
                v-on="on"
                outlined
                clearable
                @click:clear="clearDate('dateFrom')"
              >
              </v-text-field>
            </template>
            <v-date-picker
              :disabled="disabled"
              v-model="dateFrom"
              :max="dateTo"
              @input="menuFrom = false"
              @change="update('dateFrom')"
            >
            </v-date-picker>
          </v-menu>
        </v-col>

        <!-- To -->
        <v-col>
          <v-menu
            v-model="dateToMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateTo"
                label="To date"
                prepend-icon="mdi-calendar"
                readonly
                :disabled="disabled"
                @focus="dateToMenu = true"
                v-bind="attrs"
                v-on="on"
                outlined
                clearable
                @click:clear="clearDate('dateTo')"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="dateTo"
              :min="dateFrom"
              :disabled="disabled"
              @input="menuTo = false"
              @change="update('dateTo')"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </template>
    <template v-else-if="isSupervisor || isManager">
      <v-row>
        <basic-question :disabled="disabled" v-model="value" @update="update">
        </basic-question>
        <v-chip style="margin-top:15px; font-weight: bold;" label
          >{{ this.value.answerFromToDates[0] }} -
          {{ this.value.answerFromToDates[1] }}</v-chip
        >
      </v-row>
    </template>
  </div>
</template>
<style scoped></style>
<script>
import BasicQuestion from "./BasicQuestion";
import { authenticationService } from "@/_auth/authentication.service";
import { RoleCheck } from "@/_auth/role";
export default {
  props: {
    value: Object,
    disabled: {
      default: false,
      type: Boolean
    }
  },
  components: { BasicQuestion },
  data: () => ({
    dateFromMenu: false,
    dateFrom: null,
    dateToMenu: false,
    dateTo: null,
    answer: null
  }),
  watch: {},
  computed: {
    isSupervisor() {
      return RoleCheck.isSupervisor(authenticationService.currentUserValue);
    },
    isManager() {
      return RoleCheck.isManager(authenticationService.currentUserValue);
    }
  },
  methods: {
    update(input) {
      var updateVals = [];
      switch (input) {
        case "dateFrom":
          updateVals.push({
            suffix: "answerFromToDates/0",
            value: this.dateFrom
          });
          break;
        case "dateTo":
          updateVals.push({
            suffix: "answerFromToDates/1",
            value: this.dateTo
          });
          break;
        default:
          this.answer = input[0].value;
          updateVals.push(input[0]);
          break;
      }
      // check if answered completely
      if (
        (this.dateTo == null || (this.dateTo != null && this.dateTo != "")) &&
        (this.dateFrom == null ||
          (this.dateFrom != null && this.dateFrom != "")) &&
        this.answer != null
      ) {
        updateVals.push({ suffix: "answered", value: true });
      } else {
        updateVals.push({ suffix: "answered", value: false });
      }
      this.$emit("update", updateVals);
    },
    clearDate(val) {
      switch (val) {
        case "dateFrom":
          this.dateFrom = "";
          this.update("dateFrom");
          break;
        case "dateTo":
          this.dateTo = "";
          this.update("dateTo");
          break;
        default:
          break;
      }
    }
  },
  created() {
    this.answer = this.value.answer;
    if (this.value.answerFromToDates.length == 2) {
      this.dateFrom = this.value.answerFromToDates[0];
      this.dateTo = this.value.answerFromToDates[1];
    } else if (this.value.answerFromToDates.length == 1) {
      this.dateFrom = this.value.answerFromToDates[0];
    }
  }
};
</script>
