<template>
  <div>
    <v-col>
      <!-- <p><b>Cash and coin deposit slip amounts</b></p>
      <p>* should match armored courier consignment log</p> -->
      <div>
        <b>Note: Some deposits may post next work day, due to timing.</b>
      </div>
      <div>Add Branch Location (2-4 digits)</div>
      <v-row class="justify-space-around">
        <v-text-field
          v-model="locationId"
          @change="updateLocationId()"
          label="Branch Location"
          :loading="locationIdLoading"
          :disabled="!isLead"
          outlined
        ></v-text-field>
        <v-btn v-if="isLead" icon @click="updateToLocationId()"
          ><v-icon>mdi-refresh</v-icon></v-btn
        >
      </v-row>
      <v-col id="gridDiv" :style="divWidth">
        <v-row
          class="justify-start flex-nowrap"
          style="margin-left: 0px"
          v-for="i in 6"
          :key="i"
        >
          <v-col class="small_number_rectangle flex-grow-0">{{ i }}</v-col>
          <AuditorGrid
            :currentUser="currentUser"
            :index="i - 1"
            v-model="value"
            @update="updateLead"
            :disabled="!isLead"
            >Table {{ i }}</AuditorGrid
          >
          <TechGrid
            :currentUser="currentUser"
            :index="i - 1"
            v-model="value"
            @update="updateTech"
            v-if="!isLead"
            :disabled="disabled || !isTechAdvisor"
            >Tech Table {{ i }}</TechGrid
          >
        </v-row>
      </v-col>
    </v-col>
  </div>
</template>
<style scoped>
#gridDiv {
  overflow-x: auto;
  overflow-y: auto;
}
.small_number_rectangle {
  height: 50px;
  margin-top: 12px;
  background: #8c8c8c;
  vertical-align: middle;
  color: white;
}
</style>
<script>
import AuditorGrid from "../../slots/ATenAuditorGridBase";
import TechGrid from "../../slots/ATenTechGridBase";
import { RoleCheck } from "@/_auth/role";
import { authenticationService } from "@/_auth/authentication.service";
import { officeService } from "@/_services/office.service";

export default {
  props: { value: Object, disabled: Boolean, officeId: Number },
  components: {
    AuditorGrid,
    TechGrid
  },
  data() {
    return {
      buttonText: "Show Tables",
      clicked: false,
      leadTables: [[], [], [], [], [], []],
      techTables: [[], [], [], [], [], []],
      currentUser: null,
      ans: null,
      subAns1: null,
      subAns2: null,
      divWidth: "",
      locationId: "",
      locationIdLoading: false
    };
  },
  methods: {
    updateLocationId() {
      this.$emit("update", [{ suffix: "locationId", value: this.locationId }]);
    },
    updateLead(updates, index) {
      this.leadTables[index] = updates;
      this.$emit("update", [
        {
          suffix: `subitems/3`,
          value: this.leadTables
        }
      ]);
    },
    updateTech(updates, index) {
      this.techTables[index] = updates;
      this.$emit("update", [
        {
          suffix: "subitems/4",
          value: this.techTables
        }
      ]);
    },
    updateAns(updates) {
      this.ans = updates[0].value;
      this.$emit("update", updates);
    },
    updateSubAns1(updates) {
      this.subAns1 = updates[0].value;
      this.$emit("update", [
        { suffix: "subitems/1/answer", value: updates[0].value }
      ]);
    },
    updateSubAns2(updates) {
      this.subAns2 = updates[0].value;
      this.$emit("update", [
        { suffix: "subitems/2/answer", value: updates[0].value }
      ]);
    },
    answered() {
      if (
        (this.ans != null || this.value.answer != null) &&
        (this.subAns1 != null || this.value.subitems[1].answer != null) &&
        (this.subAns2 != null || this.value.subitems[2].answer != null)
      ) {
        return { suffix: "answered", value: true };
      } else {
        return { suffix: "answered", value: false };
      }
    },
    buttonClicked() {
      if (this.isLead) {
        if (this.value.subitems[3] == null) {
          this.$emit("update", [{ suffix: "subitems/3", value: [] }]);
        } else {
          this.leadTables = this.value.subitems[3];
        }
      } else if (this.isTechAdvisor) {
        if (this.value.subitems[4] == null) {
          this.$emit("update", [{ suffix: "subitems/4", value: [] }]);
        } else {
          this.techTables = this.value.subitems[4];
        }
      }
    },
    handleResize() {
      this.divWidth = `width: ${window.innerWidth - 200}px`;
    },
    async updateToLocationId() {
      this.locationIdLoading = true;
      if (this.officeId) {
        this.locationId = await officeService.getOfficeLocationIdByOfficeId(
          this.officeId
        );
        this.locationIdLoading = false;
        this.$emit("update", [
          { suffix: "locationId", value: this.locationId }
        ]);
      }
    }
  },
  computed: {
    isLead() {
      return RoleCheck.isLead(authenticationService.currentUserValue);
    },
    isTechAdvisor() {
      return RoleCheck.isTechAdvisor(authenticationService.currentUserValue);
    }
  },
  created() {
    this.currentUser =
      authenticationService.currentUserValue.firstName +
      " " +
      authenticationService.currentUserValue.lastName;
    this.ans = this.value.answer;
    if (this.value.subitems) {
      this.subAns1 = this.value.subitems[1] ? this.value.subitems[1].answer : null;
      this.subAns2 = this.value.subitems[2] ? this.value.subitems[2].answer : null;
    } else if (!this.value.subitems) {
      this.$emit("update", [{ suffix: "subitems", value: [] }]);
      this.$emit("update", [
        {
          suffix: "subitems/0",
          value: {
            identifier: null,
            text: "For completion by Auditor:",
            questionType: "FYI",
            sortOrder: 10
          }
        }
      ]);
      this.$emit("update", [
        {
          suffix: "subitems/1",
          value: {
            answererId: null,
            identifier: "a",
            comments: [],
            answer: null,
            answerDate: null,
            answererName: null,
            sortOrder: 10,
            text:
              "Copy and attach all applicable supporting documentation (Deposit Slip, Confirmation Receipt, End of Day Report, Bank Final Receipt, Consignment Log) to the audit.",
            questionType: "TASK"
          }
        }
      ]);
      this.$emit("update", [
        {
          suffix: "subitems/2",
          value: {
            answererId: null,
            identifier: "b",
            comments: [],
            answer: null,
            answerDate: null,
            answererName: null,
            sortOrder: 10,
            text:
              "In order to match with the bank report, e-mail the Customer Service Technical Advisor with the following information for all (cash, coin and check) deposits selected:\n&nbsp;&nbsp;=> Branch Number | Cash, Coin or Check\n&nbsp;&nbsp;=> Deposit Dates | cc: Audited Supervisor\n&nbsp;&nbsp;=> Amount",
            questionType: "TASK"
          }
        }
      ]);
      // // copy over what would be in A10
      this.value.subitems = [
        {
          identifier: null,
          text: "For completion by Auditor:",
          questionType: "FYI",
          sortOrder: 10
        },
        {
          answererId: null,
          identifier: "a",
          comments: [],
          answer: null,
          answerDate: null,
          answererName: null,
          sortOrder: 10,
          text:
            "Copy and attach all applicable supporting documentation (Deposit Slip, Confirmation Receipt, End of Day Report, Bank Final Receipt, Consignment Log) to the audit.",
          questionType: "TASK"
        },
        {
          answererId: null,
          identifier: "b",
          comments: [],
          answer: null,
          answerDate: null,
          answererName: null,
          sortOrder: 10,
          text:
            "In order to match with the bank report, e-mail the Customer Service Technical Advisor with the following information for all (cash, coin and check) deposits selected:\n&nbsp;&nbsp;=> Branch Number | Cash, Coin or Check\n&nbsp;&nbsp;=> Deposit Dates | cc: Audited Supervisor\n&nbsp;&nbsp;=> Amount",
          questionType: "TASK"
        }
      ];
      this.subAns1 = this.value.subitems[1].answer;
      this.subAns2 = this.value.subitems[2].answer;
    }
    if (!this.value.locationId) {
      this.updateToLocationId();
    } else {
      this.locationId = this.value.locationId;
    }
    this.buttonClicked();
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }
};
</script>
