<template>
  <v-col>
    <div id="first_rectangle">
      <p class="text" style="padding-top: 15px; padding-left: 15px;">
        <b>Dep. Match</b>
      </p>
    </div>
    <div id="small_rectangle">
      <div>
        <v-row class="firstCheck">
          <v-icon
            large
            :disabled="disabled || isControlDisabled"
            @click="changeToGreen(0)"
            :style="[
              tableItems[0].checkClicked
                ? { color: 'rgba(110, 165, 84, 1) !important' }
                : { color: 'rgba(0, 0, 0, 0.2)' }
            ]"
          >
            mdi-check-circle
          </v-icon>
          <v-icon
            large
            :disabled="disabled || isControlDisabled"
            @click="changeToRed(0)"
            style="margin-left: 75%; margin-top:-59%;"
            :style="[
              tableItems[0].crossClicked
                ? { color: 'rgba(255, 0, 0, 1) !important' }
                : { color: 'rgba(0, 0, 0, 0.2)' }
            ]"
          >
            mdi-close-circle
          </v-icon>
        </v-row>
        <v-row class="secondCheck">
          <v-icon
            large
            :disabled="disabled || isControlDisabled"
            @click="changeToGreen(1)"
            :style="[
              tableItems[1].checkClicked
                ? { color: 'rgba(110, 165, 84, 1) !important' }
                : { color: 'rgba(0, 0, 0, 0.2)' }
            ]"
          >
            mdi-check-circle
          </v-icon>
          <v-icon
            large
            :disabled="disabled || isControlDisabled"
            @click="changeToRed(1)"
            style="margin-left: 75%; margin-top:-59%;"
            :style="[
              tableItems[1].crossClicked
                ? { color: 'rgba(255, 0, 0, 1) !important' }
                : { color: 'rgba(0, 0, 0, 0.2)' }
            ]"
          >
            mdi-close-circle
          </v-icon>
        </v-row>
        <v-row class="thirdCheck">
          <v-icon
            large
            :disabled="disabled || isControlDisabled"
            @click="changeToGreen(2)"
            :style="[
              tableItems[2].checkClicked
                ? { color: 'rgba(110, 165, 84, 1) !important' }
                : { color: 'rgba(0, 0, 0, 0.2)' }
            ]"
          >
            mdi-check-circle
          </v-icon>
          <v-icon
            large
            :disabled="disabled || isControlDisabled"
            @click="changeToRed(2)"
            style="margin-left: 75%; margin-top:-59%;"
            :style="[
              tableItems[2].crossClicked
                ? { color: 'rgba(255, 0, 0, 1) !important' }
                : { color: 'rgba(0, 0, 0, 0.2)' }
            ]"
          >
            mdi-close-circle
          </v-icon>
        </v-row>
      </div>
    </div>
    <div id="optionRectangle1">
      <v-row v-if="tableItems[0].crossClicked">
        <v-col cols="12" sm="4" md="4">
          <!-- <p class="tags"><b>Checks</b></p> -->
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-layout>
            <v-menu
              v-model="firstDepositDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
            >
              <template v-slot:activator="{ on }">
                <div>
                  <v-text-field
                    placeholder="Deposit Date"
                    dense
                    outlined
                    readonly
                    v-model="tableItems[0].checksDate"
                    v-on="on"
                    append-icon="mdi-calendar-month"
                    hide-details
                    class="dateField"
                    @change="updateAudit()"
                    :disabled="disabled || isControlDisabled"
                  >
                  </v-text-field>
                </div>
              </template>
              <v-date-picker
                v-model="tableItems[0].checksDate"
                @input="firstDepositDateMenu = false"
                @change="updateAudit()"
                :disabled="disabled || isControlDisabled"
              >
              </v-date-picker>
            </v-menu>
          </v-layout>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <currency-input
            class="currencyField"
            currency="USD"
            v-model="tableItems[0].checksAmount"
            @change="updateAudit()"
            :disabled="disabled || isControlDisabled"
          ></currency-input>
        </v-col>
      </v-row>
      <v-row v-if="!tableItems[0].crossClicked" style="padding-top:15%;">
      </v-row>
      <v-row v-if="tableItems[1].crossClicked">
        <v-col cols="12" sm="4" md="4">
          <!-- <p class="tags"><b>Cash</b></p> -->
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-layout>
            <v-menu
              v-model="secondDepositDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
            >
              <template v-slot:activator="{ on }">
                <div>
                  <v-text-field
                    placeholder="Deposit Date"
                    dense
                    outlined
                    readonly
                    v-model="tableItems[1].cashDate"
                    v-on="on"
                    append-icon="mdi-calendar-month"
                    hide-details
                    class="dateField"
                    @change="updateAudit()"
                    :disabled="disabled || isControlDisabled"
                  >
                  </v-text-field>
                </div>
              </template>
              <v-date-picker
                v-model="tableItems[1].cashDate"
                @input="secondDepositDateMenu = false"
                @change="updateAudit()"
                :disabled="disabled || isControlDisabled"
              >
              </v-date-picker>
            </v-menu>
          </v-layout>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <currency-input
            class="currencyField"
            currency="USD"
            v-model="tableItems[1].cashAmount"
            @change="updateAudit()"
            :disabled="disabled || isControlDisabled"
          ></currency-input>
        </v-col>
      </v-row>
      <v-row v-if="!tableItems[1].crossClicked" style="padding-top:15%;">
      </v-row>
      <v-row v-if="tableItems[2].crossClicked">
        <v-col cols="12" sm="4" md="4"> </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-layout>
            <v-menu
              v-model="thirdDepositDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
            >
              <template v-slot:activator="{ on }">
                <div>
                  <v-text-field
                    placeholder="Deposit Date"
                    dense
                    outlined
                    readonly
                    v-model="tableItems[2].depositDate"
                    v-on="on"
                    append-icon="mdi-calendar-month"
                    hide-details
                    class="dateField"
                    @change="updateAudit()"
                    :disabled="disabled || isControlDisabled"
                  >
                  </v-text-field>
                </div>
              </template>
              <v-date-picker
                v-model="tableItems[2].depositDate"
                @input="thirdDepositDateMenu = false"
                @change="updateAudit()"
                :disabled="disabled || isControlDisabled"
              >
              </v-date-picker>
            </v-menu>
          </v-layout>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <currency-input
            class="currencyField"
            currency="USD"
            v-model="tableItems[2].depositAmount"
            @change="updateAudit()"
            :disabled="disabled || isControlDisabled"
          ></currency-input>
        </v-col>
      </v-row>
    </div>
    <div id="thirdRectangle">
      <p class="text" style="padding-left: 35%; padding-top: 5%;">
        <b>Date & Amount should match Consignment Log (CL)</b>
      </p>
    </div>
    <div id="fourthRectangle">
      <v-row class="fourthCheck">
        <v-icon
          large
          @click="changeToGreen(3)"
          :disabled="disabled || isControlDisabled"
          :style="[
            tableItems[3].checkClicked
              ? { color: 'rgba(110, 165, 84, 1) !important' }
              : { color: 'rgba(0, 0, 0, 0.2)' }
          ]"
        >
          mdi-check-circle
        </v-icon>
        <v-icon
          large
          :disabled="disabled || isControlDisabled"
          @click="changeToRed(3)"
          style="margin-left: 45%; margin-top:-35%;"
          :style="[
            tableItems[3].crossClicked
              ? { color: 'rgba(255, 0, 0, 1) !important' }
              : { color: 'rgba(0, 0, 0, 0.2)' }
          ]"
        >
          mdi-close-circle
        </v-icon>
      </v-row>
    </div>
    <div id="finalOptionRectangle">
      <v-row v-if="tableItems[3].crossClicked">
        <v-col cols="12" sm="4" md="4"> </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-layout>
            <v-menu
              v-model="fourthDepositDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
            >
              <template v-slot:activator="{ on }">
                <div>
                  <v-text-field
                    placeholder="Deposit Date"
                    dense
                    outlined
                    readonly
                    v-model="tableItems[3].depositDate"
                    v-on="on"
                    append-icon="mdi-calendar-month"
                    hide-details
                    class="dateField"
                    @change="updateAudit()"
                    :disabled="disabled || isControlDisabled"
                  >
                  </v-text-field>
                </div>
              </template>
              <v-date-picker
                v-model="tableItems[3].depositDate"
                @input="fourthDepositDateMenu = false"
                @change="updateAudit()"
                :disabled="disabled || isControlDisabled"
              >
              </v-date-picker>
            </v-menu>
          </v-layout>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <currency-input
            class="currencyField"
            currency="USD"
            v-model="tableItems[3].depositAmount"
            @change="updateAudit()"
            :disabled="disabled || isControlDisabled"
          ></currency-input>
        </v-col>
      </v-row>
    </div>
    <!-- <v-row>
        <v-btn class="saveButton" v-on:click="updateAudit()">
          <v-icon>mdi-check-all</v-icon>
        </v-btn>
      </v-row> -->
    <!-- </v-col> -->
  </v-col>
</template>
<style scoped>
.saveButton {
  margin-left: 34.5em;
  margin-top: 5px;
}

.firstCheck {
  margin-left: 15% !important;
  margin-top: 20% !important;
}

.secondCheck {
  margin-left: 15% !important;
  margin-top: 65% !important;
}

.thirdCheck {
  margin-left: 15% !important;
  margin-top: 60% !important;
}

.fourthCheck {
  margin-left: 8% !important;
  margin-top: -17% !important;
}

#first_rectangle {
  width: 540px;
  height: 50px;
  background: rgba(196, 196, 196, 0.5);
}

#small_rectangle {
  display: flex;
  width: 101px;
  height: 211px;
  padding: 4px, 8px, 4px, 8px;
  background: rgba(196, 196, 196, 0.3);
  vertical-align: middle;
  margin-top: 5px;
}

#optionRectangle1 {
  height: 211px;
  width: 435px;
  background: rgba(196, 196, 196, 0.3);
  margin-left: 6.5em;
  margin-top: -13.2em;
}

#thirdRectangle {
  width: 540px;
  height: 70px;
  background: rgba(196, 196, 196, 0.3);
  margin-top: 5px;
  margin-bottom: 5px;
}

#fourthRectangle {
  width: 101px;
  height: 63px;
  background: rgba(196, 196, 196, 0.3);
  padding-top: 30px;
}

#finalOptionRectangle {
  width: 436px;
  height: 63px;
  background: rgba(196, 196, 196, 0.3);
  margin-top: -3.95em;
  margin-left: 6.5em;
}

.text {
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
}

.tags {
  float: right;
  position: absolute;
  margin-top: 6px;
  margin-left: 4em;
}

.dateField {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;
  border: 5px;
  left: -25px;
  width: 145px;
}

.currencyField {
  width: 145px;
  height: 39px;
  background: white;
  border-radius: 5px;
  margin-left: -23px;
  padding: 10px;
  box-shadow: 0 0 0 0.5pt gray;
}
</style>

<script>
export default {
  props: ["index", "value", "disabled"],
  data() {
    return {
      tableItems: [],
      tempDisabled: false,
      firstDepositDateMenu: false,
      secondDepositDateMenu: false,
      thirdDepositDateMenu: false,
      fourthDepositDateMenu: false
    };
  },
  computed: {
    isControlDisabled() {
      const AUDIT_SUBITEM_INDEX = 3;
      const WORKDATE_INDEX = 0;
      return (
        this.value.subitems[AUDIT_SUBITEM_INDEX][this.index] == null ||
        this.value.subitems[AUDIT_SUBITEM_INDEX][this.index][WORKDATE_INDEX]
          .workDateVal == null ||
        this.value.subitems[AUDIT_SUBITEM_INDEX][this.index][WORKDATE_INDEX]
          .workDateVal === ""
      );
    }
  },
  methods: {
    updateAudit() {
      this.tempDisabled = true;
      this.$emit("update", this.tableItems, this.index);
    },
    changeToGreen(val) {
      if (this.tableItems[val].crossClicked) {
        //need to turn cross back to grey
        this.tableItems[val].crossClicked = false;
      }

      if (!this.tableItems[val].checkClicked) {
        this.tableItems[val].checkClicked = true;
        this.updateAudit();
      }
    },
    changeToRed(val) {
      if (this.tableItems[val].checkClicked) {
        this.tableItems[val].checkClicked = false;
      }

      if (!this.tableItems[val].crossClicked) {
        this.tableItems[val].crossClicked = true;
        this.updateAudit();
      }
    }
  },
  watch: {
    $route() {
      this.updateAudit();
    }
  },
  created() {
    if (
      this.value.subitems[4] != null &&
      this.value.subitems[4][this.index] != null &&
      this.value.subitems[4][this.index].length != 0
    ) {
      this.tableItems = this.value.subitems[4][this.index];
    } else {
      this.tableItems = [
        {
          checkClicked: false,
          crossClicked: false,
          checksDate: "",
          checksAmount: 0
        },
        {
          checkClicked: false,
          crossClicked: false,
          cashDate: "",
          cashAmount: 0
        },
        {
          checkClicked: false,
          crossClicked: false,
          depositDate: "",
          depositAmount: 0
        },
        {
          checkClicked: false,
          crossClicked: false,
          depositDate: "",
          depositAmount: 0
        }
      ];
    }
  }
};
</script>
