<template>
  <div>
    <audit-base
      :onlyUser="true"
      :user="user"
      :auditPeriodTo="auditPeriodTo"
      :auditPeriodFrom="auditPeriodFrom"
      :location="location"
      :reviewHistory="this.value.reviewHistory"
    >
    </audit-base>
    <div class="body-padding">
      <checklist
        :incompleteAnswer="incompleteAnswer"
        :activities="value.activities"
      ></checklist>
      <v-divider></v-divider>
      <v-col>
        <v-row v-if="!completed"
          >You must complete all appendix procedures before you can
          {{ submitButtonLabel.toLowerCase() }} your audit</v-row
        >
        <v-row
          v-if="isSupervisor && value.statusCode !== 'VERIFIED'"
          class="warning mt-2"
        >
          A Tech Advisor must complete verification of the audit before it can
          be compiled.
        </v-row>
        <v-row>
          <v-checkbox
            v-if="!completed"
            v-model="confirmation"
            label="I confirm that I have reviewed all audit materials and have accurately and ethically responded to the appendix questions in this audit."
          ></v-checkbox>
        </v-row>
        <v-row v-if="completed">
          <v-alert class="text warning">
            Audit has successfully been {{ submitTextLabel }}
          </v-alert>
        </v-row>
        <v-row>
          <v-btn
            v-if="!completed"
            :disabled="!confirmed"
            color="primary"
            @click="submit"
            >{{ submitButtonLabel }} Audit</v-btn
          >
          <v-btn
            v-if="isManager"
            :disabled="!completed"
            title="Audit must be completed before a report can be generated"
            color="primary"
            class="ml-10"
            @click="printPdf"
          >
            Download Audit
            <v-progress-circular
              v-if="pdfInProgress"
              indeterminate
              color="white"
              class="ml-5"
            ></v-progress-circular>
          </v-btn>
        </v-row>
      </v-col>
    </div>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="snackbarColor"
      :style="{ 'white-space': 'pre-line' }"
      right
    >
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<style scoped>
.body-padding {
  padding-top: 300px;
  padding-left: 70px;
  padding-right: 40px;
  padding-bottom: 40px;
}

.text {
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  background: transparent;
}
</style>
<script>
import Checklist from "../utils/Checklist.vue";
import AuditBase from "./slots/AuditBase";
import { auditService, auditStatusService } from "@/_services/audit.service";
import { pdfService } from "@/_services/pdf.service";
import { authenticationService } from "@/_auth/authentication.service";
import { RoleCheck } from "@/_auth/role";
import { router } from "@/router";

export default {
  props: ["value"],
  components: {
    AuditBase,
    Checklist
  },
  data: () => ({
    confirmation: false,
    auditPeriodFrom: null,
    auditPeriodTo: null,
    completed: false,
    pdfInProgress: false,

    user: null,
    location: null,
    incompleteAnswer: {},

    snackbar: false,
    timeout: -1,
    snackbarColor: "blue-grey",
    snackbarText: "Submitting..."
  }),
  computed: {
    confirmed() {
      return this.confirmation;
    },
    isLead() {
      return RoleCheck.isLead(authenticationService.currentUserValue);
    },
    isManager() {
      return RoleCheck.isManager(authenticationService.currentUserValue);
    },
    isTechAdvisor() {
      return RoleCheck.isTechAdvisor(authenticationService.currentUserValue);
    },
    isSupervisor() {
      return RoleCheck.isSupervisor(authenticationService.currentUserValue);
    },
    submitButtonLabel() {
      if (this.isSupervisor) {
        return "Compile";
      } else if (this.isLead) {
        return "Submit";
      } else if (this.isTechAdvisor) {
        return "Verify";
      } else {
        return "Complete";
      }
    },
    submitTextLabel() {
      if (this.isSupervisor) {
        return "compiled, you may logout now.";
      } else if (this.isLead) {
        return "submitted, you may logout now.";
      } else if (this.isTechAdvisor) {
        return "verified, you may logout now.";
      } else {
        return "completed. You may now download your audit report PDF.";
      }
    }
  },
  methods: {
    initalizePage() {
      this.auditPeriodFrom = this.value.auditPeriodFrom;
      this.auditPeriodTo = this.value.auditPeriodTo;
      this.user = `${authenticationService.currentUserValue.firstName} ${authenticationService.currentUserValue.lastName}`;
      this.location = this.value.officeName;

      if (this.value.statusCode === "COMPLETED") {
        this.completed = true;
      }
    },
    printPdf() {
      this.pdfInProgress = true;
      pdfService
        .createTestPdf(this.value)
        .then(() => (this.pdfInProgress = false))
        .catch(error => {
          console.log("Error generating PDF: " + error);
          this.pdfInProgress = false;
        });
    },
    submit() {
      this.snackbar = true;
      this.timeout = -1;
      this.snackbarColor = "blue-grey";
      this.snackbarText = "Submitting...";

      if (this.isLead) {
        this.submitAuditForLead();
      } else if (this.isSupervisor) {
        this.compileAudit();
      } else if (this.isManager) {
        this.completeAudit();
      }
    },
    completeAudit() {
      auditService
        .completeAudit(this.$route.params.id)
        .then(r => {
          console.log(r);
          this.completed = true;
          this.snackbarColor = "green";
          this.snackbarText = "Submitted!";
          this.timeout = 2000;
          this.completed = true;
        })
        .catch(e => {
          this.snackbarColor = "red";
          this.snackbarText = e.message;
        });
    },
    compileAudit() {
      auditService
        .compileAudit(this.$route.params.id)
        .then(() => {
          this.completed = true;
          this.snackbarColor = "green";
          this.snackbarText = "Submitted!";
          this.timeout = 2000;
          this.completed = true;
          this.incompleteAnswer = {};
          setTimeout(() => router.push({ path: "/" }), 5000);
        })
        .catch(e => {
          this.incompleteAnswer = auditStatusService.appendixWithErrors(e);
          auditService.checklistSubj.next(this.incompleteAnswer);
          this.snackbarColor = "red";
          this.snackbarText = e.message;
        });
    },
    submitAuditForLead() {
      auditService
        .submitAudit(this.$route.params.id)
        .then(() => {
          this.completed = true;
          this.snackbarColor = "green";
          this.snackbarText = "Submitted!";
          this.timeout = 2000;
          this.incompleteAnswer = {};
          setTimeout(() => router.push({ path: "/" }), 5000);
        })
        .catch(e => {
          this.incompleteAnswer = auditStatusService.appendixWithErrors(e);
          auditService.checklistSubj.next(this.incompleteAnswer);
          this.snackbarColor = "red";
          this.snackbarText = e.message;
        });
    }
  },
  created() {
    this.initalizePage();
  }
};
</script>
