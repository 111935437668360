<template>
  <div>
    <basic-question
      v-if="supervisor"
      @update="updateAns"
      questionLabel="Adherence"
      v-model="value"
      :disabled="disabled"
    ></basic-question>
    <additional-actions-required-base>
      <template #row1>
        <v-chip color="#8C8C8C" text-color="white" class="mb-5"
          >For completion by Auditor:
        </v-chip>
        <div v-html="value.subitems[1].text"></div>
        <basic-question
          :disabled="disabled"
          questionLabel="Complete"
          v-model="value.subitems[1]"
          @update="updateSubAns"
        ></basic-question>
      </template>
    </additional-actions-required-base>
  </div>
</template>
<script>
import AdditionalActionsRequiredBase from "../../slots/AdditionalActionsRequiredBase";
import BasicQuestion from "../BasicQuestion";
import { authenticationService } from "@/_auth/authentication.service";
import { RoleCheck } from "@/_auth/role";

export default {
  props: ["value", "disabled"],
  components: {
    AdditionalActionsRequiredBase,
    BasicQuestion
  },
  computed: {
    lead() {
      return RoleCheck.isLead(authenticationService.currentUserValue);
    },
    supervisor() {
      return RoleCheck.isSupervisor(authenticationService.currentUserValue);
    }
  },
  methods: {
    updateSubAns(updates) {
      this.$emit("update", [
        { suffix: "subitems/1/answer", value: updates[0].value },
        this.answered()
      ]);
    },
    updateAns(updates) {
      this.$emit("update", updates);
    },
    answered() {
      return { suffix: "answered", value: true };
    }
  }
};
</script>
