var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',[(_vm.view != 'general')?_c('div',_vm._l((_vm.appendixData),function(data,appendixIndex){return _c('v-row',{key:data.appendix},[_c('v-col',[_c('v-row',{staticClass:"headers flex-nowrap"},[_c('div',{staticClass:"circle circle-color-exp"},[_vm._v(_vm._s(data.appendix))]),_vm._v(" "+_vm._s(data.name))]),(_vm.view != 'procedure')?_c('v-row',[_c('v-col',[_c('v-row',{staticClass:"headers"},[_vm._v("Appendix")]),_vm._l((_vm.filterByRole(
                _vm.appendixElement[data.appendix][_vm.summaryType],
                'leadauditor'
              )),function(elem){return _c('v-row',{key:`leadauditor-${_vm.getCommentOrAttachmentIdentifier(
                  _vm.summaryType,
                  elem
                )}`},[(_vm.summaryType == 'comments')?_c('comment-base',{attrs:{"comment":elem}}):_vm._e(),(_vm.summaryType == 'attachments')?_c('attachment-base',{attrs:{"attachment":elem},on:{"remove":toRemove => _vm.handeAttachmentRemove(toRemove, appendixIndex)}}):_vm._e()],1)}),_vm._l((_vm.filterByRole(
                _vm.appendixElement[data.appendix][_vm.summaryType],
                'techadvisor'
              )),function(elem){return _c('v-row',{key:`techadvisor-${_vm.getCommentOrAttachmentIdentifier(
                  _vm.summaryType,
                  elem
                )}`},[(_vm.summaryType == 'comments')?_c('comment-base',{attrs:{"comment":elem}}):_vm._e(),(_vm.summaryType == 'attachments')?_c('attachment-base',{attrs:{"attachment":elem},on:{"remove":toRemove => _vm.handeAttachmentRemove(toRemove, appendixIndex)}}):_vm._e()],1)}),_vm._l((_vm.filterByRole(
                _vm.appendixElement[data.appendix][_vm.summaryType],
                'branchsupervisor'
              )),function(elem){return _c('v-row',{key:`branchsupervisor-${_vm.getCommentOrAttachmentIdentifier(
                  _vm.summaryType,
                  elem
                )}`},[(_vm.summaryType == 'comments')?_c('comment-base',{attrs:{"comment":elem}}):_vm._e(),(_vm.summaryType == 'attachments')?_c('attachment-base',{attrs:{"attachment":elem},on:{"remove":toRemove => _vm.handeAttachmentRemove(toRemove, appendixIndex)}}):_vm._e()],1)}),_vm._l((_vm.filterByRole(
                _vm.appendixElement[data.appendix][_vm.summaryType],
                'manager'
              )),function(elem){return _c('v-row',{key:`manager-${_vm.getCommentOrAttachmentIdentifier(
                  _vm.summaryType,
                  elem
                )}`},[(_vm.summaryType == 'comments')?_c('comment-base',{attrs:{"comment":elem}}):_vm._e(),(_vm.summaryType == 'attachments')?_c('attachment-base',{attrs:{"attachment":elem},on:{"remove":toRemove => _vm.handeAttachmentRemove(toRemove, appendixIndex)}}):_vm._e()],1)}),(_vm.appendixElement[data.appendix][_vm.summaryType].length == 0)?_c('v-row',[_vm._v("No appendix "+_vm._s(_vm.summaryType))]):_vm._e()],2)],1):_vm._e(),(_vm.view != 'appendix')?_c('v-row',[_c('v-col',[_c('v-row',{staticClass:"headers"},[_vm._v("Procedure")]),(
                _vm.appendixElement[data.appendix].procedure[_vm.summaryType]
                  .length == 0
              )?_c('v-row',[_vm._v("No procedure "+_vm._s(_vm.summaryType))]):_vm._e(),_vm._l((_vm.appendixElement[data.appendix]
                .procedure[_vm.summaryType]),function(elem,index){return _c('v-row',{key:`leadauditor-${index}`},[_c('v-col',[_c('v-row',[_c('span',{domProps:{"innerHTML":_vm._s(`${elem.identifier}. ${elem.text}`)}})]),_vm._l((_vm.filterProcedureByRole(elem.elem, 'leadauditor')),function(e){return _c('v-row',{key:`leadauditor-${_vm.getCommentOrAttachmentIdentifier(
                      _vm.summaryType,
                      e
                    )}`},[(_vm.summaryType == 'comments')?_c('comment-base',{attrs:{"comment":e}}):_vm._e(),(_vm.summaryType == 'attachments')?_c('attachment-base',{attrs:{"attachment":e},on:{"remove":toRemove =>
                        _vm.handeAttachmentRemove(
                          toRemove,
                          appendixIndex,
                          elem.questionIndex
                        )}}):_vm._e()],1)}),_vm._l((_vm.filterProcedureByRole(elem.elem, 'techadvisor')),function(e){return _c('v-row',{key:`techadvisor-${_vm.getCommentOrAttachmentIdentifier(
                      _vm.summaryType,
                      e
                    )}`},[(_vm.summaryType == 'comments')?_c('comment-base',{attrs:{"comment":e}}):_vm._e(),(_vm.summaryType == 'attachments')?_c('attachment-base',{attrs:{"attachment":e},on:{"remove":toRemove =>
                        _vm.handeAttachmentRemove(
                          toRemove,
                          appendixIndex,
                          elem.questionIndex
                        )}}):_vm._e()],1)}),_vm._l((_vm.filterProcedureByRole(
                    elem.elem,
                    'branchsupervisor'
                  )),function(e){return _c('v-row',{key:`branchsupervisor-${_vm.getCommentOrAttachmentIdentifier(
                      _vm.summaryType,
                      e
                    )}`},[(_vm.summaryType == 'comments')?_c('comment-base',{attrs:{"comment":e}}):_vm._e(),(_vm.summaryType == 'attachments')?_c('attachment-base',{attrs:{"attachment":e},on:{"remove":toRemove =>
                        _vm.handeAttachmentRemove(
                          toRemove,
                          appendixIndex,
                          elem.questionIndex
                        )}}):_vm._e()],1)}),_vm._l((_vm.filterProcedureByRole(elem.elem, 'manager')),function(e){return _c('v-row',{key:`manager-${_vm.getCommentOrAttachmentIdentifier(
                      _vm.summaryType,
                      e
                    )}`},[(_vm.summaryType == 'comments')?_c('comment-base',{attrs:{"comment":e}}):_vm._e(),(_vm.summaryType == 'attachments')?_c('attachment-base',{attrs:{"attachment":e},on:{"remove":toRemove =>
                        _vm.handeAttachmentRemove(
                          toRemove,
                          appendixIndex,
                          elem.questionIndex
                        )}}):_vm._e()],1)})],2)],1)})],2)],1):_vm._e(),_c('v-divider')],1)],1)}),1):_vm._e(),_c('v-row',{class:_vm.view == 'general' || _vm.view == 'all' ? '' : 'general-hidden'},[_c('v-col',[_c('v-row',{staticClass:"headers",attrs:{"id":"general"}},[_vm._v(" General ")]),(_vm.general.length == 0)?_c('div',{staticClass:"mb-4"},[_vm._v(" No general "+_vm._s(_vm.summaryType)+" ")]):_vm._e(),_vm._l((_vm.general),function(elem){return _c('v-row',{key:_vm.getCommentOrAttachmentIdentifier(_vm.summaryType, elem)},[(_vm.summaryType == 'comments')?_c('comment-base',{attrs:{"comment":elem}}):_vm._e(),(_vm.summaryType == 'attachments')?_c('attachment-base',{attrs:{"attachment":elem},on:{"remove":toRemove => _vm.handeAttachmentRemove(toRemove)}}):_vm._e()],1)}),(_vm.summaryType == 'comments')?_c('div',{class:_vm.view == 'general' ? '' : 'general-hidden'},[_c('v-row',[_c('v-textarea',{ref:"commentBox",attrs:{"background-color":"white","outlined":"","disabled":_vm.submitting},model:{value:(_vm.commentDraft),callback:function ($$v) {_vm.commentDraft=$$v},expression:"commentDraft"}})],1),_c('v-row',[_c('v-col',[_c('v-btn',{staticStyle:{"background-color":"white","margin-top":"10px"},attrs:{"outlined":"","color":"#0098DA","disabled":_vm.submitting},on:{"click":_vm.submitNewComment}},[(_vm.submitting)?_c('div',[_vm._v("Submitting...")]):_c('div',[_vm._v("Submit")])])],1)],1)],1):_vm._e()],2)],1),_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }