<template>
  <v-container fill-height justify-center>
    <v-card class="" elevation="" max-width="500px" v-if="true">
      <v-card-title primary-title> Begin a New Audit </v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12">
            <OfficeList
              v-model="selectedOffice"
              sort="alpha"
              :context="isLead ? leadStartAudit : null"
              v-if="!selectedOffice"
            ></OfficeList>
            <v-row
              v-if="selectedOffice"
              no-gutters
              @click="
                selectedOffice = null;
                lastErrorText = null;
              "
            >
              <v-col cols="12">
                <b>{{ selectedOffice.officeName }}</b>
              </v-col>
              <v-col cols="12">
                <span
                  v-html="selectedOffice.address.replace('\n', '<br/>')"
                ></span>
              </v-col>
              <v-col cols="12"><hr /></v-col>
            </v-row>
          </v-col>
          <v-col cols="12"> Date: {{ currentDate }} </v-col>
          <v-col cols="12" class="text--primary">
            Auditor: {{ currentUser.firstName }} {{ currentUser.lastName }}
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          :disabled="!selectedOffice"
          color="success"
          @click="startNewAudit"
        >
          Confirm Audit Start
        </v-btn>
        <span class="error--text" v-if="lastErrorText">{{
          lastErrorText
        }}</span>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { router } from "@/router";
import OfficeList from "@/components/OfficeList";
import { auditService } from "@/_services/audit.service";

export default {
  components: { OfficeList },
  data: () => ({
    showSelector: true,
    selectedOffice: null,
    currentDate: new Date().toDateString(),
    lastErrorText: null
  }),
  computed: {
    currentUser() {
      return this.$root.$children[0].currentUser;
    },
    isLead() {
      return this.$root.$children[0].currentUser.role.includes("Lead");
    },
    leadStartAudit() {
      return "leadStartAudit";
    }
  },
  methods: {
    startNewAudit() {
      this.lastErrorText = null;
      auditService
        .startNewAudit(this.selectedOffice.id)
        .then(response => {
          let newAuditId = response.auditId;
          router.push(`/performaudit/${newAuditId}`, () => {});
        })
        .catch(reason => {
          if (reason && reason.message) {
            this.lastErrorText = reason.message;
          } else {
            this.lastErrorText = "Sorry, an unexpected error has occurred";
          }
        });
      // TODO: Add a loading element here
    }
  }
};
</script>
