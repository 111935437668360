<template>
  <v-container>
    <v-row v-if="!attachment.attachmentKey"></v-row>
    <v-row v-else>
      <v-col cols="6">
        <b>{{ calculateDate() }} | {{ calculateUser }}</b>
        <v-row>
          <v-col
            ><p>{{ getFilename(attachment.attachmentKey) }}</p></v-col
          >
        </v-row>
      </v-col>
      <v-col cols="6" align="right">
        <v-row>
          <v-col cols="10">
            <div v-if="imageLoaded" class="expandable-image-container">
              <expandable-image :src="imageUrl"></expandable-image>
            </div>
            <div class="placeholder" v-if="!imageLoaded">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
          </v-col>
          <v-col cols="2">
            <v-btn
              color="#0098DA"
              title="Remove Attachment"
              @click="removeAttachment(attachment)"
              icon
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<style>
.placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  width: 100%;
  max-width: 250px;
}
.expandable-image-container,
.expandable-image-container .expandable-image img {
  height: 150px;
  width: 100%;
  max-width: 250px;
}
</style>
<script>
import { fileDownloadService } from '@/_services/file-download.service';

export default {
  props: ['attachment'],

  data: () => ({
    imageUrl: String,
    imageLoaded: false
  }),

  computed: {
    calculateUser() {
      if (
        'undefined' !== typeof this.attachment &&
        'undefined' !== typeof this.attachment['attacherName']
      ) {
        if (this.attachment.attacherName.length > 0) {
          return this.attachment.attacherName;
        }
      }
      return 'Unknown';
    }
  },
  created() {
    if (this.attachment.attachmentKey) {
      fileDownloadService
        .presignedDownload(this.attachment.attachmentKey)
        .then(response => {
          this.imageUrl = response;
          this.imageLoaded = true;
        });
    }
  },
  methods: {
    getFilename(attachmentKey) {
      if (attachmentKey) {
        var parts = attachmentKey.split('/');
        return parts[parts.length - 1];
      }
      return '';
    },
    addZero(val) {
      if (val <= 9) {
        return `0${val}`;
      }
      return val;
    },
    calculateDate() {
      if (
        'undefined' !== typeof this.attachment &&
        'undefined' !== typeof this.attachment['attachDate']
      ) {
        if (this.attachment.attachDate.length > 0) {
          var date = new Date(this.attachment.attachDate);
          return `${this.addZero(date.getMonth() + 1)}/${this.addZero(
            date.getDate()
          )}/${date.getFullYear()} ${this.addZero(
            date.getHours()
          )}:${this.addZero(date.getMinutes())}`;
        }
      }
      return 'Unknown';
    },
    removeAttachment(attachment) {
      this.$emit('remove', attachment);
    }
  }
};
</script>
