<template>
  <div>
    <basic-question
      v-model="value"
      @update="update"
      :disabled="disabled"
    ></basic-question>
    <v-col style="margin:0;margin-left:10px;">
      <v-row style="margin:0;border: 4px solid transparent; padding:10px">
        <div>
          (Sample should include all bases for offices with multiple bases)
          <br />
          Verify that the field employee's supervisor and BOS are notified of
          any late submission of payments or out-of-balance conditions
          (overages/shortages) of $25 or more.
        </div>
        <!-- <div>
          <v-row style="margin:0; padding-left:10px">
            <div style="padding:20px">Adherence</div>
            <v-radio-group v-model="subAns1" @change="updateSubAns1" row>
              <v-radio
                v-for="(answerChoice, index) in ['YES', 'NO', 'N/A']"
                :key="index"
                :label="answerChoice"
                :value="answerChoice"
              ></v-radio>
            </v-radio-group>
          </v-row>
        </div> -->
      </v-row>
      <!-- <template> -->
      <v-row style="margin:0;border: 4px solid transparent; padding:10px">
        <div>
          Note use of "Notification Completed" stamp on the applicable envelope
          (or register for missing envelope) contains date, TPID, discrepancy
          and contact names.
        </div>
        <!-- <div>
          <v-row style="margin:0; padding-left:10px">
            <div style="padding:20px">Adherence</div>
            <v-radio-group v-model="subAns2" @change="updateSubAns2" row>
              <v-radio
                v-for="(answerChoice, index) in ['YES', 'NO', 'N/A']"
                :key="index"
                :label="answerChoice"
                :value="answerChoice"
              ></v-radio>
            </v-radio-group>
          </v-row>
        </div> -->
      </v-row>
      <v-col style="margin:0;border: 4px solid transparent; padding:10px">
        <div><b>Note dates of random sample:</b></div>
        <v-row
          class="justify-center align-center flex-nowrap"
          style="padding-left: 10px padding-right: 10px; height: 60px;"
          v-for="(numRows, index) in numMonths"
          :key="numRows"
        >
          <v-col cols="3" class="flex-shrink-0">
            <div style="text-align:center">
              Month {{ dateRanges[index][0].substr(0, 7) }}:
            </div>
          </v-col>
          <c-nine-date-picker
            :disabled="disabled"
            :initialDate1="initialDate((numRows - 1) * 2)"
            :initialDate2="initialDate((numRows - 1) * 2 + 1)"
            :index1="(numRows - 1) * 2"
            :index2="(numRows - 1) * 2 + 1"
            :minDate="dateRanges[index][0].toString()"
            :maxDate="dateRanges[index][1].toString()"
            @updateDate="updateDate"
          ></c-nine-date-picker>
        </v-row>
      </v-col>
    </v-col>
  </div>
</template>
<style scoped>
.v-application .align-center {
  align-items: flex-start !important;
}
</style>
<script>
// import AdditionalActionsRequiredBase from "../../slots/AdditionalActionsRequiredBase";
import BasicQuestion from "../BasicQuestion";
import CNineDatePicker from "../../../utils/CNineDatePicker";
export default {
  props: ["value", "auditFrom", "auditTo", "disabled"],
  data: () => ({
    subAns1Path: "subitems/1/answer",
    subAns1: null,
    subAns2Path: "subitems/2/answer",
    subAns2: null,
    subAnsDatesPath: "subitems/3/dates",
    dates: [],
    dateRanges: [],
    numMonths: 0
  }),
  components: {
    // AdditionalActionsRequiredBase,
    BasicQuestion,
    CNineDatePicker
  },
  computed: {},
  methods: {
    update(input) {
      this.answer = input[0].value;
      this.$emit("update", [input[0], this.answered()]);
    },
    updateSubAns1() {
      this.$emit("update", [{ suffix: this.subAns1Path, value: this.subAns1 }]);
    },
    updateSubAns2() {
      this.$emit("update", [{ suffix: this.subAns2Path, value: this.subAns2 }]);
    },
    updateDate(input, index) {
      this.dates[index] = input.toString();
      this.$emit("update", [
        { suffix: this.subAnsDatesPath, value: JSON.stringify(this.dates) },
        this.answered()
      ]);
    },
    answered() {
      if (
        this.value.answer != null &&
        (this.answer == "N/A" ||
          this.value.answer == "N/A" ||
          this.dates.filter(function(e) {
            return e != null && e != "";
          }).length > 0)
      ) {
        return { suffix: "answered", value: true };
      } else {
        return { suffix: "answered", value: false };
      }
    },
    handleMonths() {
      var months;
      var from = this.convertDate(this.auditFrom);
      var to = this.convertDate(this.auditTo);
      months = (to.getFullYear() - from.getFullYear()) * 12;
      months -= from.getMonth();
      months += to.getMonth();
      this.numMonths = months + 1;
      var month = 0;
      while (month < this.numMonths) {
        var y = from.getFullYear(),
          m = from.getMonth();
        var firstDay = new Date(y, m + month);
        firstDay = firstDay < from ? from : firstDay;
        var lastDay = new Date(y, m + month + 1, 0);
        lastDay = lastDay > to ? to : lastDay;
        this.dateRanges.push([
          firstDay.toISOString().substr(0, 10),
          lastDay.toISOString().substr(0, 10)
        ]);
        month++;
      }
    },
    initialDate(index) {
      if (this.dates[index] != null) {
        return this.dates[index].substr(0, 10);
      }
      return null;
    },
    convertDate(date) {
      var month = parseInt(date.substr(5, 2)) - 1;
      var day = parseInt(date.substr(8, 2));
      var year = parseInt(date.substr(0, 4));
      return new Date(year, month, day);
    }
  },
  created() {
    if ("undefined" !== typeof this.value.subitems[3]["dates"]) {
      var jsonDates = JSON.parse(this.value.subitems[3]["dates"]);
      this.dates = jsonDates;
    }

    if ("undefined" !== typeof this.value["subitems"]) {
      this.subAns1 = this.value.subitems[1].answer;
    }

    if ("undefined" !== typeof this.value["subitems"]) {
      this.subAns2 = this.value.subitems[2].answer;
    }

    this.handleMonths();
  }
};
</script>
