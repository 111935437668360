<template>
  <v-container>
    <v-row class="section-header">
      New audit?
      <div id="start-here" class="space">Start here.</div>
    </v-row>
    <v-spacer></v-spacer>
    <template>
      <v-row>
        What office will you be auditing?
      </v-row>
      <v-row>
        <v-col>
          <officeList
            class="selector-size"
            sort="alpha"
            v-model="selectedOffice"
          ></officeList>
        </v-col>
      </v-row>
      <v-icon v-if="this.selectedOffice" style="margin-top: -1.5em;"
        >mdi-map-marker</v-icon
      >
      <p style="margin-top: -2.5em; margin-left: 2em;">
        {{ this.officeAddress }}
      </p>
    </template>

    <v-fade-transition>
      <div v-if="canSelectDate" cols="12">
        <v-row>
          Please provide a date range for the audit:
        </v-row>
        <!-- <audit-period></audit-period> -->
        <v-row>
          <v-col>
            <v-menu
              dense
              ref="dateRangeMenu"
              v-model="dateRangeMenu"
              :close-on-content-click="false"
              transition="scale-transiton"
              offset-y
              max-width="290x"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  ref="dateRange"
                  v-model="dateRangeText"
                  placeholder="to - from date range"
                  append-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  @click:append="dateRangeMenu = true"
                  @focus="dateRangeMenu = true"
                  class="selector-size"
                  outlined
                  readonly
                >
                </v-text-field>
              </template>
              <v-date-picker
                @click:date="changeCalendarDate"
                dense
                v-model="selectedDates"
                range
                :min="minAuditDate"
                :picker-date.sync="shownDate"
                ref="calendar"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </div>
    </v-fade-transition>

    <v-fade-transition>
      <div v-if="canStartAudit">
        <v-row>
          For your reference, this audit will be marked with a start date of
          {{ currentDate }}
        </v-row>
        <v-row class="button-margin">
          <v-btn
            color="primary"
            large
            @click="
              startNewAudit();
              snackbar = true;
            "
            >Start Audit
          </v-btn>
          <v-snackbar v-model="snackbar" v-if="errorMessage">
            {{ errorMessage }}
            <template v-slot:action="{ attrs }">
              <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </v-row>
      </div>
    </v-fade-transition>
  </v-container>
</template>
<style scoped>
.section-header {
  font-weight: 800;
  font-size: 24px;
}
.section-header #start-here {
  color: #0098da;
}
.selector-size {
  max-width: 300px;
}
.space {
  margin-left: 4px;
}
.disabled-text {
  color: lightgray;
}
.button-margin {
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
<script>
import officeList from "../components/OfficeList";
import { auditService } from "@/_services/audit.service";
import { router } from "@/router";
import { officeService } from "@/_services/office.service";
// import AuditPeriod from "../components/utils/AuditPeriod";
export default {
  name: "startNewAudit",
  data: () => ({
    selectedOffice: null,
    selectedDates: [],
    dateRangeMenu: false,
    maxAuditDate: new Date().toISOString().slice(0, 10),
    shownDate: null,
    auditFrequency: 6,
    errorMessage: null,
    officeAddress: null,
    officeError: null,
    snackbar: false
  }),
  components: {
    officeList
    // AuditPeriod
  },
  asyncComputed: {
    async getAddress() {
      if (this.selectedOffice) {
        let officeId = this.selectedOffice.id;
        this.officeAddress = await officeService.getOfficeAddressByOfficeId(
          officeId
        );
      }
    }
  },
  computed: {
    dateRangeText() {
      if (this.selectedDates.length > 0) {
        // TODO: compare dates and have oldest first in selectedDates arr
        var retStr = "";
        if (this.selectedDates.length > 0 && this.selectedDates[0] != "") {
          retStr = this.selectedDates[0].replaceAll("-", "/") + " - ";
        }
        if (this.selectedDates.length > 1 && this.selectedDates[1] != "") {
          var date0 = new Date(this.selectedDates[0]);
          var date1 = new Date(this.selectedDates[1]);
          if (date0 > date1) {
            retStr = this.selectedDates[1].replaceAll("-", "/") + " - ";
            retStr += this.selectedDates[0].replaceAll("-", "/");
          } else {
            retStr += this.selectedDates[1].replaceAll("-", "/");
          }
        }
        return retStr;
      }
      return "";
    },
    canSelectDate() {
      if (this.selectedOffice != null) {
        return true;
      }
      return false;
    },
    canStartAudit() {
      // TODO: add more date validation
      if (this.selectedOffice != null && this.selectedDates.length == 2) {
        return true;
      }
      return false;
    },
    currentDate() {
      return new Date().toLocaleDateString();
    },
    minAuditDate() {
      let minDate = new Date();
      minDate.setUTCMonth(minDate.getUTCMonth() - 12);
      minDate.setUTCDate(1);
      return minDate.toISOString().slice(0, 10);
    },
    auditFrequencyList() {
      let list = [
        {
          text: this.$vuetify.breakpoint.width < 450 ? "12 mo" : "12 months",
          value: 12
        },
        {
          text: this.$vuetify.breakpoint.width < 450 ? "6 mo" : "6 months",
          value: 6
        },
        {
          text: this.$vuetify.breakpoint.width < 450 ? "3 mo" : "3 months",
          value: 3
        }
      ];
      return list;
    }
  },
  methods: {
    openDatePicker() {
      if (this.$refs.dateRange) {
        this.$refs.dateRange.$refs.input.click();
      }
    },
    changeCalendarDate() {
      if (this.selectedDates.length == 1) {
        const calendar = this.$refs.calendar;
        let newDate = calendar.pickerDate;
        let split = newDate.split("-");
        let date = new Date(split[0], split[1] - 1, 1);
        let aheadDate = new Date(date.setMonth(date.getMonth() + 5));
        aheadDate = aheadDate.toLocaleDateString();
        let splitFinalDate = aheadDate.split("/");
        this.shownDate = splitFinalDate[2] + "-" + splitFinalDate[0];
      }
      return;
    },
    startNewAudit() {
      this.lastErrorText = null;
      if (this.officeAlreadyUsed()) {
        this.errorMessage = "Chosen office already has an audit in progress";
        return;
      }
      auditService
        .startNewAudit(this.selectedOffice.id)
        .then(response => {
          let newAuditId = response.auditId;
          // TODO: Remove call to updateAudit, this was a hack for a demo
          // ideally would like to set up a backend call that will update with date range
          auditService
            .updateAudit(newAuditId, [
              {
                op: "replace",
                path: "/auditPeriodFrom",
                value: this.selectedDates[0]
              },
              {
                op: "replace",
                path: "/auditPeriodTo",
                value: this.selectedDates[1]
              }
            ])
            .then(() => {
              router.push(`/performaudit/${newAuditId}`, () => {});
              router.push(
                {
                  name: "appendix",
                  params: { id: newAuditId, appendixId: "A" }
                },
                () => {}
              );
            })
            .catch(reason => {
              this.lastErrorText = reason.message;
            });
          // router.push(`/performaudit/${newAuditId}`);
        })
        .catch(reason => {
          if (reason && reason.message) {
            this.lastErrorText = reason.message;
          } else {
            this.lastErrorText = "Sorry, an unexpected error has occurred";
            // alert(`Sorry, an unexpected error has occurred: \n${reason}`);
            this.errorMessage = reason.split(",")[0];
          }
        });
      this.errorMessage = null;
      // TODO: Add a loading element here
    },
    officeAlreadyUsed() {
      this.errorMessage = null;
      let auditList = this.$parent.$refs.auditList.auditList;
      if (auditList) {
        var r = auditList.some(i =>
          i.officeName.includes(this.selectedOffice.officeName)
        );
      }
      return r;
    },
    cleanupAuditOptions() {
      /// Set shown date based on auditFrequency
      let d = new Date();
      d.setMonth(d.getMonth() - 1);
      if (
        this.selectedDates == null ||
        this.selectedDates.length < 1 ||
        this.selectedDates.length >= 2
      ) {
        /// Automatically start initial date at auditFrequecy months before previous month (+1 since it is inclusive)
        d.setMonth(d.getMonth() - this.auditFrequency + 1);
      }
      this.shownDate = d.toISOString().slice(0, 7);
    }
  },
  mounted() {
    this.cleanupAuditOptions();
  }
  // TODO: If a lead has a single audit opened within the last 4 days, they should default to viewing that rather than hitting home screen.
  // However, they still need to be able to hit the home-screen at will, so we will need to put together a good algorithm for when to redirect and when to show home page.
  // For now, not adding this automation
};
</script>
