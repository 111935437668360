<template>
  <v-container pa-0 ma-0>
    <v-autocomplete
      outlined
      @change="onOfficeChange"
      :items="shownOfficeList"
      return-object
      item-text="officeName"
      placeholder="i.e. San Pedro"
      :filter="filterList"
      :no-data-text="loadingText"
      :loading="currentlyLoading"
    ></v-autocomplete>
  </v-container>
</template>

<script>
import { officeService } from "@/_services/office.service";
import { authenticationService } from "@/_auth/authentication.service";

export default {
  props: {
    sort: String,
    context: {
      type: String,
      default: "leadStartAudit"
    }
  },
  data: () => ({
    officeList: [],
    loadingText: null,
    currentlyLoading: false
  }),
  computed: {
    shownOfficeList() {
      if (this.context == "leadStartAudit") {
        let userAreaCode = authenticationService.currentUserValue.areaCode;
        var officeListForAudits = [];
        if (userAreaCode == "all") {
          officeListForAudits = this.officeList;
        } else {
          this.officeList.forEach(office => {
            let officeInsideOfLeadsArea = office.areaCode == userAreaCode;
            if (officeInsideOfLeadsArea) {
              office.disabled = true;
            }
            officeListForAudits.push(office);
          });
        }
      }
      return officeListForAudits;
    }
  },
  methods: {
    onOfficeChange(newValue) {
      // let office = this.officeList.find(o => o.id == newValue);
      // this.$emit("input", office);
      this.$emit("input", newValue);
    },
    reSortOfficeList() {
      if (this.sort == "alpha") {
        this.officeList.sort((a, b) => {
          return a.officeName.localeCompare(b.officeName);
        });
      } else {
        this.officeList.sort((a, b) => {
          return a.id - b.id;
        });
      }
    },
    filterList(item, queryText, itemText) {
      return itemText.toLowerCase().startsWith(queryText.toLowerCase());
    }
  },
  watch: {
    sort: function(newSort, oldSort) {
      oldSort;
      newSort;
      this.reSortOfficeList();
    }
  },
  created() {
    this.loadingText = "Loading offices...";
    this.currentlyLoading = true;
    officeService
      .getAll()
      .then(offices => {
        if (offices == null || offices.length == 0) {
          this.currentlyLoading = false;
          this.loadingText = "Error loading offices";
        } else {
          this.officeList = offices;
        }
      })
      .then(() => {
        this.reSortOfficeList();
        this.loadingText = "Office not found";
        this.currentlyLoading = false;
      })
      .catch(() => {
        this.currentlyLoading = false;
        this.loadingText = "Error loading offices";
      });
  }
};
</script>
