<template>
  <v-row class="align-self-center">
    <v-col cols="4" class="d-none d-sm-block">
      <v-row>
        <v-img
          src="/img/Sempra_Login_Image_1.png"
          class="sideImages"
          style="
            top: 0px;"
        >
        </v-img>
      </v-row>
      <v-row>
        <v-img
          src="/img/Sempra_Login_Image_2.png"
          class="sideImages"
          style="
            top: 45%;"
        >
        </v-img>
      </v-row>
    </v-col>
    <v-col cols="8" style="padding: 75px; color: white;">
      <div style="font-size:30px">Inter-Office <b>Audits</b></div>
      <div style="font-size:24px; padding-top: 70px">Login</div>
      <v-btn
        v-if="!showLogin"
        style="margin-top: 50px; margin-bottom: 50px"
        @click="microsoftLogin"
        >Microsoft Login</v-btn
      >
      <v-btn v-else style="margin-top: 25px" @click="microsoftLogin"
        >Microsoft Login</v-btn
      >
      <v-text-field
        v-if="showLogin"
        dense
        label="Username"
        v-model="username"
        hint="ie. name@account.com"
        color="white"
        style="border-color: white; margin-top:100px;width:400px"
        dark
        outlined
      ></v-text-field>
      <v-text-field
        v-if="showLogin"
        type="password"
        dense
        label="Password"
        v-model="password"
        color="white"
        style="border-color: white; margin-top:10px;width:400px"
        dark
        outlined
      ></v-text-field>
      <v-btn
        v-if="showLogin"
        color="primary"
        @click="userLoggingIn"
        :class="{ 'disable-btn': !activateLoginButton }"
        >Login</v-btn
      >
      <v-img style="width: 300px; margin-top: 50px" src="/img/socalwhite.png">
      </v-img>
      <div style="width: 300px; margin-top:10px">
        © 2021 Southern California Gas Company. All copyright and trademark
        rights reserved.
      </div>
    </v-col>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="snackbar">
      {{ this.errTxt }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>
<style scoped>
amplify-authenticator {
  --container-align: start;
  --container-height: auto;
  --box-shadow: none;
}
.disable-btn {
  background-color: lightgray;
  color: grey;
}
.v-text-field--outlined >>> fieldset {
  border-color: white;
}
.container {
  display: flex;
}

.buttons {
  background: #0098da !important;
  color: white;
  border-radius: 5px;
  position: absolute;
  font-family: Lato;
  font-size: 12px;
}

.buttons-login {
  top: 40em;
  margin-left: 18.5em;
}

.v-btn--disabled {
  background-color: gray !important;
}

.buttons-Microsoft {
  margin-left: 11.8em;
  top: 18em;
}

.textField {
  position: absolute;
  max-width: 600px;
  min-width: 300px;
}

.text {
  font-family: Lato;
  position: absolute;
  width: 65px;
  height: 30px;
}

.sideImages {
  position: absolute;
  width: 30%;
  height: 55%;
  left: -5%;
}

.SoCalLogo {
  display: flex;
  position: absolute;
  width: 240px;
  height: 150px;
  left: 40%;
  bottom: 15%;
}
.copyright {
  display: flex;
  position: absolute;
  width: 235px;
  left: 40%;
  bottom: 5%;
}

@media screen and (max-width: 767px) {
  .SoCalLogo {
    left: 3%;
    bottom: 5%;
  }
}
</style>
<script>
import { onAuthUIStateChange } from "@aws-amplify/ui-components";
import { Auth } from "aws-amplify";
import { authenticationService } from "@/_auth/authentication.service";
import { router } from "@/router";

export default {
  data() {
    return {
      valid: false,
      submitted: false,
      username: "",
      password: "",
      overlay: false,
      returnUrl: "",
      user: undefined,
      authState: undefined,
      snackbar: false,
      errTxt: "",
      showLogin: false
    };
  },
  created() {
    if (process.env.VUE_APP_ENVIRONMENT == "prd") {
      this.showLogin = false;
    } else {
      this.showLogin = true;
    }
    onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData;
      router.push(this.returnUrl, () => {});
    });

    this.returnUrl = this.$route.query.returnUrl || "/";
  },
  beforeDestroy() {
    return onAuthUIStateChange;
  },

  methods: {
    async userLoggingIn() {
      console.log("log in user");
      if (this.activateLoginButton) {
        this.errTxt = "Username or Password empty.";
        this.snackbar = true;
        return;
      }
      this.overlay = true;
      Auth.signIn(this.username.toLowerCase(), this.password)
        .then(() => {
          Auth.currentAuthenticatedUser()
            .then(async () => {
              await authenticationService
                .updateUserInfo()
                .then(() => {
                  router.push(this.returnUrl, () => {});
                })
                .catch(e => {
                  this.errTxt = e.message;
                  this.snackbar = true;
                  console.log(e);
                });
            })
            .catch(e => {
              this.errTxt = e.message;
              this.snackbar = true;
              console.log(`Login Failed:\n${e}`);
            });
        })
        .catch(e => {
          this.errTxt = e.message;
          this.snackbar = true;
          console.log(`Login Failed:\n${e}`);
        })
        .finally(() => {
          this.overlay = false;
        });
    },

    async microsoftLogin() {
      this.overlay = true;
      Auth.federatedSignIn({
        provider: "ActiveDirectory"
      });
    }
  },
  computed: {
    activateLoginButton() {
      if (this.password.length == 0 || this.username.length == 0) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
