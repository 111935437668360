<template>
  <div style="width:100%">
    <v-tabs background-color="transparent" v-model="tab" optional>
      <v-tab @click="expandTabSection = true">
        <v-badge v-if="commentList.length > 0" :content="commentList.length">
          <v-icon color="rgba(0, 0, 0, 0.6)"
            >mdi-comment-processing-outline</v-icon
          >
        </v-badge>
        <v-icon color="rgba(0, 0, 0, 0.6)" v-else
          >mdi-comment-processing-outline</v-icon
        >
      </v-tab>

      <v-tab @click="expandTabSection = true">
        <v-badge
          v-if="attachmentList.length > 0"
          :content="(attachmentList.length - attachmentList.filter(item => !item.attachmentKey).length).toString()"
        >
          <v-icon color="rgba(0, 0, 0, 0.6)">mdi-note-plus-outline</v-icon>
        </v-badge>
        <v-icon color="rgba(0, 0, 0, 0.6)" v-else>mdi-note-plus-outline</v-icon>
      </v-tab>
    </v-tabs>
    <v-row v-if="expandTabSection" style="margin-left:30px;margin-right:30px">
      <v-col
        class="flex-shrink-0"
        style="background-color:transparent;"
        cols="12"
      >
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card style="padding:20px">
              <!-- List out existing comments -->
              <div v-if="commentList.length == 0">
                <div class="text-style"><b>Appendix Comments</b></div>
                <div class="text-style">
                  There are currently no Appendix Level Comments
                </div>
              </div>
              <div
                class="comment"
                v-for="(comment, index) in commentList"
                :key="index"
                v-else
              >
                <comment-base
                  :enableEdit="true"
                  @edit="editComment($event, index)"
                  @delete="deleteComment(index)"
                  :enableDelete="true"
                  :comment="comment"
                ></comment-base>
              </div>
              <!-- Add more comments -->
              <div :class="commenting ? '' : 'hidden-textbox'">
                <v-textarea
                  ref="commentBox"
                  v-model="commentDraft"
                  background-color="white"
                  :disabled="submitting"
                  outlined
                ></v-textarea>
                <v-row>
                  <v-col
                    ><v-btn
                      outlined
                      color="#0098DA"
                      style="background-color: white"
                      @click="submitNewComment"
                      :disabled="submitting"
                    >
                      <div v-if="submitting">Submitting...</div>
                      <div v-else>Submit</div>
                    </v-btn></v-col
                  >
                  <v-col
                    ><v-btn
                      outlined
                      color="#0098DA"
                      style="background-color: white; margin:0px;"
                      @click="commentToggle"
                      >Cancel</v-btn
                    ></v-col
                  >
                </v-row>
              </div>
              <v-btn
                v-if="!commenting"
                outlined
                color="#0098DA"
                style="background-color: white; margin-top:10px;"
                @click="commentToggle"
              >
                New Comment
              </v-btn>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <!-- List out existing attachments -->
            <v-card style="padding:20px;">
              <div v-if="attachmentList.length == 0">
                <div class="text-style"><b>Appendix Attachments</b></div>
                <div class="text-style">
                  There are currently no Appendix Level Attachments
                </div>
              </div>
              <div
                class="attachment"
                v-for="(attachment, index) in attachmentList"
                :key="attachment.attachmentKey"
                v-else
              >
                <attachment-base
                  :attachment="attachment"
                  @remove="toRemove => handeAttachmentRemove(toRemove, index)"
                ></attachment-base>
              </div>
              <FileUpload
                :attachmentList="attachmentList"
                :appendixIndex="appendixIndex"
                :counter="true"
                @attachmentAdded="attachmentAdded"
              />
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<style scoped>
.hidden-textbox {
  height: 0;
  width: 0;
  opacity: 0;
}
.v-window {
  overflow: visible;
}
.v-icon {
  color: #0098da;
}
.v-tabs {
  padding-left: 30px;
  padding-right: 30px;
  background-color: transparent;
}
.v-tab {
  height: 60px;
}
.text-style {
  margin-bottom: 20px;
}
</style>
<script>
import { auditService } from '@/_services/audit.service';
import { authenticationService } from '@/_auth/authentication.service';
import CommentBase from './slots/CommentBase';
import AttachmentBase from './slots/AttachmentBase';
import FileUpload from '@/components/FileUpload';

export default {
  props: ['value', 'appendixIndex', 'currentUser'],
  components: { CommentBase, AttachmentBase, FileUpload },
  data: () => ({
    tab: null,
    newComment: null,
    commenting: false,
    commentDraft: null,
    submitting: false,
    expandTabSection: false,
    commentList: [],
    attachmentList: [],
    overlay: false
  }),
  computed: {},
  methods: {
    closeTabs() {
      this.expandTabSection = false;
    },
    commentToggle() {
      this.commenting = !this.commenting;
      if (this.commenting) {
        this.$refs.commentBox.focus();
      }
    },
    submitNewComment() {
      if (this.commentDraft !== null) {
        if (this.commentDraft.length > 0) {
          this.submitting = true;
          // check to see if call successful
          // var appendixId = this.$route.params.appendixId;
          var path = `/activities/${this.appendixIndex}/comments`;
          var op = 'add';
          var auditid = this.$route.params.id;
          var currentTime = new Date().toString();
          var value = {
            commentDate: currentTime,
            commenterName: `${authenticationService.currentUserValue.firstName} ${authenticationService.currentUserValue.lastName}`,
            commenter: authenticationService.currentUserValue,
            comment: this.commentDraft
          };
          var objList = [];
          if (this.commentList.length == 0) {
            var object = {
              op: op,
              path: path,
              value: []
            };
            objList.push(object);
          }
          objList.push({
            op: op,
            path: path + '/',
            value: value
          });
          auditService
            .updateAudit(auditid, objList)
            .then(response => {
              console.log(response);
              // this.value.push(value);
              this.commentList.push(value);
              this.commentDraft = null;
              this.submitting = false;
            })
            .catch(error => {
              this.errorText = error;
              console.error(error);
              console.log(
                `Warning unable to submit comment for the following reasons:\n${error}\nPlease try again later.`
              );
              this.submitting = false;
            });
        }
      }
    },
    editComment(updates, index) {
      // this.commentList[index].comment = updates;
      this.overlay = true;
      let commentIndex = this.commentList.length - index - 1;
      var path = `/activities/${this.appendixIndex}/comments/${commentIndex}`;
      var objVal = this.commentList[index];
      objVal.comment = updates;
      objVal.lastEdited = new Date().toString();
      auditService
        .updateAudit(this.$route.params.id, [
          {
            op: 'replace',
            path: path,
            value: objVal
          }
        ])
        .then(response => {
          console.log(response);
          this.commentList[index] = objVal;
          this.overlay = false;
        })
        .catch(error => {
          this.errorText = error;
          console.error(error);
          console.log(
            `Warning unable to submit comment for the following reasons:\n${error}\nPlease try again later.`
          );
          this.submitting = false;
          this.updateComments();
          this.overlay = false;
        });
    },
    deleteComment(index) {
      this.overlay = true;
      let commentIndex = this.commentList.length - index - 1;
      var path = `/activities/${this.appendixIndex}/comments/${commentIndex}`;
      auditService
        .updateAudit(this.$route.params.id, [
          {
            op: 'remove',
            path: path,
            value: this.value.comments[commentIndex]
          }
        ])
        .then(response => {
          console.log(response);
          this.commentList.splice(index, 1);
          this.overlay = false;
        })
        .catch(error => {
          this.errorText = error;
          console.error(error);
          console.log(
            `Warning unable to submit comment for the following reasons:\n${error}\nPlease try again later.`
          );
          this.submitting = false;
          this.overlay = false;
        });
    },
    attachmentAdded(attachment) {
      this.attachmentList.push(attachment);
    },
    /**
     * Handler function which takes care of attachment removal
     * @param {*} toRemove - attachment object which we are about to remove
     * @param {*} index - index from attachmentList array - this array is reversed version of original array from db
     */
    handeAttachmentRemove(toRemove, index) {
      this.overlay = true;
      // get index of attachment that we are about to remove
      const toRemoveIndex = this.attachmentList.length - index - 1;
      const auditId = this.$route.params.id;
      const jsonPath = `/activities/${this.appendixIndex}/attachments/${toRemoveIndex}`;
      // execute Backend request
      auditService
        .removeAttachment(auditId, jsonPath, toRemove.attachmentKey)
        .then(() => {
          this.attachmentList.splice(index, 1);
        })
        .catch(error => {
          this.errorText = error;
          console.error(
            `Warning unable to remove attachment for the following reasons:\n${error}\nPlease try again later.`,
            error
          );
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    updateAppendixComments() {
      if (
        'undefined' !== typeof this.value.comments &&
        Array.isArray(this.value.comments)
      ) {
        this.commentList = this.value.comments.slice().reverse();
      } else {
        this.commentList = [];
        this.value.comments = [];
      }
    },
    updateAppendixAttachments() {
      if (
        'undefined' !== typeof this.value.attachments &&
        Array.isArray(this.value.attachments)
      ) {
        this.attachmentList = this.value.attachments.slice().reverse();
      } else {
        this.attachmentList = [];
        this.value.attachments = [];
      }
    }
  },
  watch: {
    $route() {
      this.updateAppendixComments();
      this.updateAppendixAttachments();
    }
  },
  created() {
    this.updateAppendixComments();
    this.updateAppendixAttachments();
  }
};
</script>
