<template>
  <div>
    <appendix
      v-if="view == 'appendix' && value"
      v-model="value"
      :taVerified="verified"
      @updateAudit="updateAudit"
      @verifyAudit="verifyAudit"
    ></appendix>
    <comment-summary
      v-if="view == 'comment-summary' && value"
      v-model="value"
    ></comment-summary>
    <attachment-summary
      v-if="view == 'attachment-summary' && value"
      v-model="value"
      @updateAudit="updateAudit"
    ></attachment-summary>
    <checklist-summary
      v-if="view == 'checklist-summary' && value"
      v-model="value"
    ></checklist-summary>
    <!-- <div v-else>
            TODO: HANDLE THIS ERROR IN PERFORM AUDIT
            <h1>ERROR: could not retrieve audit information correctly :( </h1>
            <h3>Please make sure your url is correct or call your system administrator.</h3>
        </div> -->
    <v-snackbar
      v-model="showSnackbar"
      :color="snackbarColor"
      :timeout="timeout"
      right
    >
      {{ this.progress }}
      <template v-if="closeBtn" v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          text
          @click="
            showSnackbar = false;
            closeBtn = false;
          "
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import Appendix from "./Appendix";
import CommentSummary from "./CommentSummary";
import AttachmentSummary from "./AttachmentSummary";
import ChecklistSummary from "./ChecklistSummary";
import { auditService } from "@/_services/audit.service";
import { router } from "@/router";

export default {
  props: ["value", "view"],
  components: {
    Appendix,
    CommentSummary,
    AttachmentSummary,
    ChecklistSummary
  },
  data() {
    return {
      showSnackbar: false,
      timeout: -1,
      snackbarColor: "blue-grey",
      progress: "Updating...",
      closeBtn: false,
      verified: false
    };
  },
  methods: {
    verifyAudit() {
      this.progress = "Verifying...";
      this.snackbarColor = "blue-grey";
      this.timeout = -1;
      this.showSnackbar = true;
      auditService
        .verifyAudit(this.$route.params.id)
        .then(r => {
          console.log(r);
          this.snackbarColor = "green";
          this.progress = "Verified! Redirecting to home page shortly";
          this.timeout = "5000";
          this.verified = true;
          setTimeout(() => router.push({ path: "/" }), 5000);
        })
        .catch(e => {
          this.snackbarColor = "red";
          this.progress = `Error: ${e.message}`;
          this.timeout = "-1";
          this.closeBtn = true;
          console.log(e);
        });
    },
    updateAudit(updates, path, op, auditid) {
      this.progress = "Updating...";
      this.snackbarColor = "blue-grey";
      this.timeout = -1;
      this.showSnackbar = true;
      auditService
        .updateAudit(
          auditid,
          updates.map(e => {
            return {
              op: op,
              path: path + "/" + e.suffix,
              value: e.value
            };
          })
        )
        .then(r => {
          console.log(r);
          this.snackbarColor = "green";
          this.progress = "Updated!";
          this.timeout = "2000";
        })
        .catch(e => {
          this.snackbarColor = "red";
          this.progress = "Unable to update at this time";
          this.timeout = "2000";
          console.log(e);
        });
    }
  }
};
</script>
