export const getUserRole = role => {
  switch (role) {
    case 'leadauditor':
      return 'Lead Auditor';
    case 'techadvisor':
      return 'Tech Advisor';
    case 'branchsupervisor':
      return 'Branch Supervisor';
    case 'manager':
      return 'Manager';
    default:
      return '';
  }
};
