export const UPDATED_TEXT = {
  A2: {
    text:
      "Verify that designated employee confirms that all batches are closed prior to inputting Bank Final."
  },
  A5: {
    text:
      "Check deposit slips, confirmation receipts and End of Shift report dates.  Verify copy of deposit slips confirmation receipts and End of Shift reports are retained in the originating office for one year plus current month."
  },
  A7: {
    text:
      "Select twelve random End of Shift reports and confirmation receipts (6 cash & 6 checks); for one final deposit from each month within audit period. Verify totals on all cash End of Shift reports and check confirmation receipts match totals listed on the Bank Final receipt."
  },
  A10: {
    text:
      "Examine the corresponding consignment log for TPID by banker, courier signature, or courier badge #, route #, date, time of pick-up by courier and ensure all information is present"
  },
  A11: {
    text:
      "Attach all applicable supporting documentation (Deposit Slip, Confirmation Receipt, End of Shift report, Current Content report, Courier Pick-up report, Bank Final receipt, Consignment Log) to the audit."
  },
  C5: {
    text:
      'Verify that envelopes are matched to registers and each envelope is opened and balanced individually. The envelopes should be date stamped "RECEIVED" by the employee who is responsible for opening the container and removing the contents. The amounts on each envelope should be verified, noted with TPID and date stamped "PAID" by person processing payment.',
    subitems: [
      {
        text:
          '"<b style="text-decoration: underline;">Missing envelopes</b>: Register should be noted with TPID, date stamped "RECEIVED" and discrepancy notification completed by person responsible for processing the payment.'
      },
      {
        text:
          '<b style="text-decoration: underline;">Missing registers</b>: Envelope should be noted with TPID, date stamped "PAID" and payment stubs receipt is placed in field envelope by person responsible for processing the payment.'
      }
    ]
  },
  D3: {
    text:
      "Verify subfunds are $500 each and PEP Currency Counter tape for start-up fund is processed at the beginning of the shift and present at each position."
  },
  E25: {
    text:
      "Verify sensitive documents are placed in the shred bin no later than the end of each business day or locked away in a secure location until they can be placed in the shred bin."
  }
};
