<template>
  <div>
    <audit-base
      :user="currentUser"
      :auditPeriodTo="this.value.auditPeriodTo"
      :auditPeriodFrom="this.value.auditPeriodFrom"
      :location="this.value.officeName"
      :onlyUser="true"
      :reviewHistory="this.value.reviewHistory"
    >
    </audit-base>
    <summary-filter-base
      v-model="value"
      summaryType="comments"
    ></summary-filter-base>
  </div>
</template>
<style scoped>
.body-padding {
  padding-top: 300px;
  padding-left: 70px;
  padding-right: 40px;
  padding-bottom: 40px;
}
</style>
<script>
import AuditBase from "./slots/AuditBase";
import { authenticationService } from "@/_auth/authentication.service";
import SummaryFilterBase from "./slots/SummaryFilterBase";
export default {
  props: ["value"],
  components: {
    AuditBase,
    SummaryFilterBase
  },
  data() {
    return {
      generalComments: [],
      appendixComments: [],
      procedureComments: []
    };
  },
  computed: {
    currentUser() {
      return `${authenticationService.currentUserValue.firstName} ${authenticationService.currentUserValue.lastName}`;
    }
  },
  methods: {
    updatePage() {
      // for(let appIndex in this.value.activities){
      //   var activity = this.value.activities[appIndex];
      //   this.appendixComments.push({
      //     appendix: activity.appendix,
      //     comments: activity.comments
      //   })
      //   // console.log(activity);
      //   var questions = activity.questions;
      //   for(let qIndex in questions){
      //     var question = questions[qIndex];
      //     this.procedureComments.push({
      //       identifier: question.identifier,
      //       index: qIndex,
      //       text: question.text
      //     })
      //   }
      // }
    }
  },
  created() {
    this.updatePage();
  }
};
</script>
