<template>
  <div>
    <audit-base
      :user="currentUser"
      :auditPeriodTo="this.value.auditPeriodTo"
      :auditPeriodFrom="this.value.auditPeriodFrom"
      :location="this.value.officeName"
      :onlyUser="true"
      :reviewHistory="this.value.reviewHistory"
    >
    </audit-base>
    <summary-filter-base
      v-model="value"
      summaryType="attachments"
    ></summary-filter-base>
  </div>
</template>
<style scoped>
.body-padding {
  padding-top: 300px;
  padding-left: 70px;
  padding-right: 40px;
  padding-bottom: 40px;
}
.heading {
  font-weight: 600;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.6);
}
.main-btn {
  background-color: white !important;
  color: #0098da;
  height: 40px !important;
  border-color: #0098da !important;
  font-size: 15px !important;
}
.v-btn--active {
  background-color: #0098da !important;
  color: white !important;
}
.v-window {
  width: 100%;
}
</style>
<script>
import AuditBase from "./slots/AuditBase";
import { authenticationService } from "@/_auth/authentication.service";
import SummaryFilterBase from "./slots/SummaryFilterBase";
export default {
  props: ["value"],
  components: {
    AuditBase,
    SummaryFilterBase
  },
  computed: {
    currentUser() {
      return `${authenticationService.currentUserValue.firstName} ${authenticationService.currentUserValue.lastName}`;
    }
  },
  methods: {
    updatePage() {
    }
  },
  created() {
    this.updatePage();
  }
};
</script>
