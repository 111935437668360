import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { fileDownloadService } from './file-download.service';

export const pdfService = {
  createTestPdf,
  printQuestionA11,
};

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

var styles = {
  text: {
    color: '#012a57'
  },
  pageHeader: {
    margin: [20, 20, 20, 0]
  },
  bold: {
    bold: true
  },
  header: {
    fontSize: 18,
    bold: true,
    margin: [0, 0, 0, 10],
    color: '#0298d9'
  },
  subheader: {
    fontSize: 16,
    bold: true,
    margin: [0, 10, 0, 10],
    color: '#0298d9'
  },
  subheader2: {
    bold: true,
    fontSize: 14,
    margin: [0, 10, 10, 0],
    color: '#0298d9'
  },
  tableExample: {
    margin: [0, 10, 0, 15]
  },
  tableHeader: {
    bold: true,
    fontSize: 13,
    color: '#012a57'
  },
  marginBottom: {
    margin: [0, 0, 0, 20]
  },
  marginRight: {
    margin: [10, 0, 40, 0]
  }
};

async function createTestPdf(audit) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  var content = [];
  var header = { text: 'Audit Report', style: 'header', alignment: 'center' };
  content.push(header);

  const startDate = new Date(audit.auditPeriodFrom).toLocaleDateString();
  const endDate = new Date(audit.auditPeriodTo).toLocaleDateString();
  content.push({
    text: `Office Site: ${audit.officeName}`,
    style: 'tableHeader'
  });
  content.push({ text: 'Auditor Name: ' + audit.auditorName, style: 'text' });
  content.push({
    text: `Audit Period: ${startDate} - ${endDate}`,
    style: 'text'
  });

  await processActivities(audit, content);
  processGeneralComments(audit, content);
  await processGeneralAttachments(audit, content);

  // Generate PDF
  var pdf = pdfMake.createPdf({
    content: content,
    styles: styles,
    pageSize: 'A4',
    footer: function(currentPage, pageCount) {
      return {
        text: currentPage.toString() + ' of ' + pageCount,
        alignment: 'right',
        style: 'marginRight'
      };
    },
    header: function(currentPage) {
      if (currentPage > 1) {
        return {
          text: `${audit.officeName}   -    ${audit.auditorName}    -    ${startDate} to ${endDate}`,
          alignment: 'right',
          style: 'pageHeader'
        };
      }
    },
    background: function() {
      return {
        canvas: [
          {
            type: 'rect',
            x: 0,
            y: 0,
            w: 595.28,
            h: 841.89,
            color: '#f7f7f7'
          }
        ]
      };
    }
  });
  pdf.download(`${audit.officeName}-${startDate}-${endDate}-audit.pdf`);
}

async function processGeneralAttachments(audit, content) {
  if (audit.attachments && audit.attachments.length > 0) {
    content.push({
      text: 'Audit Attached Documents',
      style: 'subheader',
      pageBreak: 'before'
    });
    var attachments = audit.attachments.filter(item => item.attachmentKey);

    for (var i = 0; i < attachments.length; i++) {
      content.push({ text: `Attachment ${i + 1}`, style: 'subheader2' });
      content.push(`Attacher: ${attachments[i].attacherName}`);
      content.push(
        `Date: ${new Date(attachments[i].attachDate).toLocaleString()}`
      );
      content.push({
        text: `Filename: ${parseFilename(attachments[i].attachmentKey)}`,
        style: 'marginBottom'
      });

      var url = await fileDownloadService.presignedDownload(
        attachments[i].attachmentKey
      );
      var base64Str = await getBase64ImageFromURL(url);

      if (i === attachments.length - 1) {
        content.push({
          image: base64Str,
          maxHeight: 600,
          width: 520
        });
      } else {
        content.push({
          image: base64Str,
          maxHeight: 600,
          width: 520,
          pageBreak: 'after'
        });
      }
    }
  }
}

function processGeneralComments(audit, content) {
  if (audit.comments && audit.comments.length > 0) {
    content.push({
      text: 'Audit Comments',
      style: 'subheader',
      pageBreak: 'before'
    });

    var generalCommentRows = [];

    audit.comments.forEach(comment => {
      generalCommentRows.push([
        comment.commenterName +
          '\n' +
          new Date(comment.commentDate).toLocaleDateString() +
          '\n' +
          new Date(comment.commentDate).toLocaleTimeString(),
        comment.comment
      ]);
    });

    var table = {
      style: 'tableExample',
      table: {
        headerRows: 1,
        body: [
          [
            { text: 'Information', style: 'tableHeader' },
            { text: 'Comment', style: 'tableHeader' }
          ],
          ...generalCommentRows
        ]
      },
      layout: 'lightHorizontalLines'
    };
    content.push(table);
  }
}

async function processActivities(audit, content) {
  for (var i = 0; i < audit.activities.length; i++) {
    var activity = audit.activities[i];
    if (i === 0) {
      content.push({
        text: `Appendix ${activity.appendix}`,
        style: 'subheader'
      });
    } else {
      content.push({
        text: `Appendix ${activity.appendix}`,
        style: 'subheader',
        pageBreak: 'before'
      });
    }
    content.push({ text: `${activity.name}`, style: 'tableHeader' });
    content.push(activity.objective);
    processActivityComments(activity, content);
    await processActivityAttachments(activity, content);
    await processQuestions(activity, content);
  }
}

async function processActivityAttachments(activity, content) {
  if (activity.attachments && activity.attachments.length > 0) {
    content.push({
      text: 'Appendix Attachments',
      style: 'subheader',
      pageBreak: 'before'
    });
    var attachments = activity.attachments.filter(item => item.attachmentKey);

    for (var j = 0; j < attachments.length; j++) {
      content.push({ text: `Attachment ${j + 1}`, style: 'subheader2' });
      content.push(`Attacher: ${attachments[j].attacherName}`);
      content.push(
        `Date: ${new Date(attachments[j].attachDate).toLocaleString()}`
      );
      content.push({
        text: `Filename: ${parseFilename(attachments[j].attachmentKey)}`,
        style: 'marginBottom'
      });

      var url = await fileDownloadService.presignedDownload(
        attachments[j].attachmentKey
      );
      var base64Str = await getBase64ImageFromURL(url);
      content.push({
        image: base64Str,
        maxHeight: 600,
        width: 520,
        pageBreak: 'after'
      });
    }
  }
}

function processActivityComments(activity, content) {
  if (activity.comments && activity.comments.length > 0) {
    content.push({ text: `Appendix Comments`, style: 'subheader2' });
    var appendixCommentRows = [];
    activity.comments.forEach(comment => {
      appendixCommentRows.push([
        comment.commenterName +
          '\n' +
          new Date(comment.commentDate).toLocaleDateString() +
          '\n' +
          new Date(comment.commentDate).toLocaleTimeString(),
        comment.comment
      ]);
    });

    var table = {
      style: 'tableExample',
      table: {
        widths: [100, '*'],
        headerRows: 1,
        body: [
          [
            { text: 'Info', style: 'tableHeader' },
            { text: 'Comment', style: 'tableHeader' }
          ],
          ...appendixCommentRows
        ]
      },
      layout: 'lightHorizontalLines'
    };
    content.push(table);
  }
}

async function processQuestions(activity, content) {
  for (var i = 0; i < activity.questions.length; i++) {
    var question = activity.questions[i];
    var answerText = question.answer ? question.answer : '';

    if (question.answerFromToDates && question.answerFromToDates.length > 0) {
      answerText = answerText + '\n\n' + question.answerFromToDates[0];
      if (question.answerFromToDates.length === 2) {
        answerText = answerText + ' - ' + question.answerFromToDates[1];
      }
    }

    var questionTableBody;

    if (activity.appendix === 'C' && question.identifier === '9') {
      answerText = appendC9Dates(question, answerText);
      questionTableBody = [
        [
          { text: 'Question ' + question.identifier, style: 'tableHeader' },
          { text: 'Answer', style: 'tableHeader' }
        ],
        [
          question.text ? removeMarkup(question.text) : '',
          { text: answerText, style: 'bold' }
        ]
      ];
    } else if (activity.appendix === 'A' && question.identifier === '11') {
      var answers = createA11AnswerTable(question);

      questionTableBody = [
        [
          {
            text: 'Question ' + question.identifier,
            colSpan: 2,
            style: 'tableHeader'
          }
        ],
        [
          { text: question.text ? removeMarkup(question.text) : '', colSpan: 2 }
        ],
        [{ text: answerText, style: 'bold', colSpan: 2 }],
        [{ text: `Branch Location: ${question.locationId}`, colSpan: 2 }],
        [
          {
            table: {
              style: 'tableExample',
              keepWithHeaderRows: 1,
              headerRows: 1,
              body: answers
            },
            colSpan: 2
          }
        ]
      ];
    } else {
      questionTableBody = [
        [
          { text: 'Question ' + question.identifier, style: 'tableHeader' },
          { text: 'Answer', style: 'tableHeader' }
        ],
        [
          question.text ? removeMarkup(question.text) : '',
          { text: answerText, style: 'bold' }
        ]
      ];
    }

    var table = {
      style: 'tableExample',
      table: {
        widths: ['*', 200],
        keepWithHeaderRows: 1,
        headerRows: 1,
        body: questionTableBody
      }
    };
    content.push(table);
    processQuestionComments(question, content);
    await processQuestionAttachments(question, content);
  }
}

function createA11AnswerTable(question) {
  let a10AuditorAnswers = question.subitems[3];
  let a10TechAnswers = question.subitems[4];
  var answers = [];

  // A10 Header
  answers.push([
    '',
    { text: 'Date', style: 'tableHeader' },
    { text: 'Amount', style: 'tableHeader' },
    { text: 'Dep. Matched', style: 'tableHeader' },
    { text: 'Deposit Date', style: 'tableHeader' },
    { text: 'Deposit Amount', style: 'tableHeader' }
  ]);
  
  // Construct A10 Body
  for (let i = 0; i < a10AuditorAnswers.length; i++) {
    try {
      if (a10AuditorAnswers[i] && a10AuditorAnswers[i].length > 0) {
        answers.push([
          { text: 'Work of Date', style: 'bold' },
          { text: a10AuditorAnswers[i][0].workDateVal, colSpan: 5 },
          '',
          '',
          '',
          ''
        ]);
        answers.push([
          { text: 'Checks', style: 'bold' },
          a10AuditorAnswers[i][1].firstDepositDateVal,
          formatter.format(a10AuditorAnswers[i][1].firstDepositAmount),
          a10TechAnswers[i][0].checkClicked === true ? 'YES' : 'NO',
          a10TechAnswers[i][0].checksDate,
          formatter.format(a10TechAnswers[i][0].checksAmount)
        ]);
        answers.push([
          { text: 'Cash', style: 'bold' },
          '',
          formatter.format(a10AuditorAnswers[i][2].secondDepositAmount),
          a10TechAnswers[i][1].checkClicked === true ? 'YES' : 'NO',
          a10TechAnswers[i][1].cashDate,
          formatter.format(a10TechAnswers[i][1].cashAmount)
        ]);
        answers.push([
          { text: 'Daily Vault Drop', style: 'bold' },
          '',
          formatter.format(a10AuditorAnswers[i][3].thirdDepositAmount),
          a10TechAnswers[i][2].checkClicked === true ? 'YES' : 'NO',
          a10TechAnswers[i][2].depositDate,
          formatter.format(a10TechAnswers[i][2].depositAmount)
        ]);
        answers.push([
          { text: 'Courier Pick-up', style: 'bold' },
          a10AuditorAnswers[i][4].secondDepositDateVal,
          {
            text: formatter.format(a10AuditorAnswers[i][4].fourthDepositAmount),
            colSpan: 4
          },
          '',
          '',
          ''
        ]);
        answers.push([
          { text: 'Vault Drop Dep. Slip', style: 'bold' },
          a10AuditorAnswers[i][5].thirdDepositDateVal,
          formatter.format(a10AuditorAnswers[i][5].fifthDepositAmount),
          a10TechAnswers[i][3].checkClicked === true ? 'YES' : 'NO',
          a10TechAnswers[i][3].depositDate,
          formatter.format(a10TechAnswers[i][3].depositAmount)
        ]);

        if (i < a10AuditorAnswers.length - 1) {
          answers.push([' ', ' ', ' ', ' ', ' ', ' ']);
        }
      }
    } catch {
      answers.push([
        ' ',
        ' ',
        ' ',
        ' ',
        ' ',
        { text: 'Incomplete or Empty consignment log', style: 'bold' }
      ]);
    }
  }
  return answers;
}

function appendC9Dates(question, answerText) {
  if (question.subitems[3].dates) {
    let dates = JSON.parse(question.subitems[3].dates);
    answerText = answerText + '\n\n' + 'Note dates of random samples:\n\n';

    for (var j = 0; j <= dates.length; j++) {
      if (j % 2 === 0 && (dates[j] || (j !== dates.length && dates[j + 1]))) {
        var monthDate;
        if (dates[j]) {
          monthDate = dates[j].substring(0, 7);
        } else if (dates[j + 1]) {
          monthDate = dates[j + 1].substring(0, 7);
        }

        answerText = `${answerText}Month of ${monthDate}:\n `;
      }

      if (j % 2 === 0) {
        if (dates[j]) {
          answerText += `  ${dates[j]} `;
        }
        if (j !== dates.length && !dates[j + 1]) {
          answerText += '\n';
        }
      } else if (dates[j]) {
        answerText += `${dates[j]}\n`;
      }
    }
  }
  return answerText;
}

async function processQuestionAttachments(question, content) {
  if (question.attachments && question.attachments.length > 0) {
    content.push({
      text: 'Question Attachments',
      style: 'subheader',
      pageBreak: 'before'
    });
    var attachments = question.attachments.filter(item => item.attachmentKey);

    for (var j = 0; j < attachments.length; j++) {
      content.push({ text: `Attachment ${j + 1}`, style: 'subheader2' });
      content.push(`Attacher: ${attachments[j].attacherName}`);
      content.push(
        `Date: ${new Date(attachments[j].attachDate).toLocaleString()}`
      );
      content.push({
        text: `Filename: ${parseFilename(attachments[j].attachmentKey)}`,
        style: 'marginBottom'
      });

      var url = await fileDownloadService.presignedDownload(
        attachments[j].attachmentKey
      );
      var base64Str = await getBase64ImageFromURL(url);
      content.push({
        image: base64Str,
        maxHeight: 600,
        width: 520,
        pageBreak: 'after'
      });
    }
  }
}

function processQuestionComments(question, content) {
  if (question.comments && question.comments.length > 0) {
    var questionCommentRows = [];
    content.push({ text: 'Question Comments', style: 'tableHeader' });
    question.comments.forEach(comment => {
      questionCommentRows.push([
        comment.commenterName +
          '\n' +
          new Date(comment.commentDate).toLocaleDateString() +
          '\n' +
          new Date(comment.commentDate).toLocaleTimeString(),
        comment.comment
      ]);
    });
    var questionCommentTable = {
      style: 'tableExample',
      table: {
        headerRows: 1,
        body: [
          [
            { text: 'Info', style: 'tableHeader' },
            { text: 'Comment', style: 'tableHeader' }
          ],
          ...questionCommentRows
        ]
      },
      layout: 'lightHorizontalLines'
    };
    content.push(questionCommentTable);
  }
}

function removeMarkup(text) {
  const regex = /<[^>]*>/g;
  return text.replace(regex, '');
}

function parseFilename(attachmentKey) {
  if (attachmentKey) {
    var parts = attachmentKey.split('/');
    return parts[parts.length - 1];
  }
  return '';
}

function getBase64ImageFromURL(url) {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.setAttribute('crossOrigin', 'anonymous');
    img.onload = () => {
      var canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, img.width, img.height);
      var dataURL = canvas.toDataURL('image/jpeg', 0.7);
      resolve(dataURL);
    };
    img.onerror = error => {
      reject(error);
    };
    img.src = url;
  });
}

async function printQuestionA11(question, audit) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const content = [];
  const answers = createA11AnswerTable(question);
  const answerText = question.answer ? question.answer : '';
  const questionTableBody = [
    [
      {
        text: 'Question ' + question.identifier,
        colSpan: 2,
        style: 'tableHeader'
      }
    ],
    [
      { text: question.text ? removeMarkup(question.text) : '', colSpan: 2 }
    ],
    [{ text: answerText, style: 'bold', colSpan: 2 }],
    [{ text: `Branch Location: ${question.locationId}`, colSpan: 2 }],
    [
      {
        table: {
          style: 'tableExample',
          keepWithHeaderRows: 1,
          headerRows: 1,
          body: answers
        },
        colSpan: 2
      }
    ]
  ];

  const table = {
    style: 'tableExample',
    table: {
      widths: ['*', 200],
      keepWithHeaderRows: 1,
      headerRows: 1,
      body: questionTableBody
    }
  };
  content.push(table);

  // Generate PDF
  const pdf = pdfMake.createPdf({
    content: content,
    styles: styles,
    pageSize: "A4",
    header: function() {
        return {
          text: `${audit.location}  -  ${audit.leadAuditor}  -  ${audit.startDate}  to  ${audit.endDate}`,
          alignment: 'right',
          style: 'pageHeader'
        };
    },
    background: function() {
      return {
        canvas: [
          {
            type: "rect",
            x: 0,
            y: 0,
            w: 595.28,
            h: 841.89,
            color: "#f7f7f7"
          }
        ]
      };
    }
  });
  pdf.download(`${audit.location}-${audit.startDate}-${audit.endDate}_appendix-A-question-11.pdf`);
}
