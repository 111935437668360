<template>
  <v-card elevation="0" :class="getThemeColor">
    <v-row>
      <v-col class="question">
        <v-row noGutters>
          <v-col>
            <v-card :class="getThemeColor + 'question-fyi ma-1 mx-2'">
              For completion by Branch Office Supervisor:
            </v-card>
            <span :class="'indented-question'">
              {{ identifier }}
              <span
                :class="allowEdit ? '' : 'text--disabled'"
                v-html="value.text.replace(/\r?\n/g, '<br/>')"
              ></span>
            </span>
          </v-col>
        </v-row>
        <v-row v-if="includeRequired" noGutters
          ><v-col align-self="center" offset="1" offset-sm="2" offset-md="3">
            <v-radio-group row v-model="value.answerRequired">
              <span :class="allowEdit ? '' : 'text--disabled'">Required?</span>
              <v-radio
                :disabled="!allowEdit"
                v-for="(answerChoice, index) in ['YES', 'NO']"
                :key="index"
                :label="answerChoice"
                :value="answerChoice"
              ></v-radio>
            </v-radio-group> </v-col
        ></v-row>
      </v-col>
      <v-col cols="4" sm="2" md="2" lg="2" xl="1">
        <v-radio-group v-model="value.answer" class="mt-0 pt-0">
          <span :class="allowEdit ? '' : 'text--disabled'">{{
            adherenceDisplayedWord
          }}</span>
          <v-radio
            :disabled="!allowEdit"
            v-for="(answerChoice, index) in ['YES', 'NO', 'N/A']"
            :key="index"
            :label="answerChoice"
            :value="answerChoice"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
  </v-card>
</template>

<style lang="less" scoped>
.question-fyi {
  // text-align: left;
  &:extend(.question);
  font-weight: bold;
  span {
    white-space: pre-wrap;
  }
}
</style>

<script>
import { Role } from "@/_auth/role";
export default {
  props: { value: Object, includeRequired: Boolean },
  data: () => ({}),
  computed: {
    currentUser() {
      return this.$root.$children[0].currentUser;
    },
    allowEdit() {
      if (this.currentUser.role.includes(Role.Supervisor)) {
        return true;
      }
      return false;
    },
    identifier() {
      if (!this.value.identifier) {
        return null;
      }
      if (this.value.identifier.length > 0) {
        let rv = this.value.identifier;
        if (this.value.identifier != "=>") {
          rv += ".";
        }
        return rv;
      }
      return null;
    },
    adherenceDisplayedWord() {
      if (this.$vuetify.breakpoint.width < 280) {
        return "";
      } else if (this.$vuetify.breakpoint.width < 320) {
        return "Adheres?";
      }
      return "Adherence:";
    },
    getThemeColor() {
      if (this.$vuetify.theme.dark) {
        return "grey darken-3";
      } else {
        return "grey lighten-3";
      }
    }
  },
  methods: {}
};
</script>
