<template>
  <div>
    <!-- CONSIDER: move auditBase to Audit? -->
    <audit-base
      :user="this.currentUserName"
      :auditPeriodTo="this.auditPeriodTo"
      :auditPeriodFrom="this.auditPeriodFrom"
      :location="this.officeName"
      :reviewHistory="this.value.reviewHistory"
    >
      <template #info-icon>{{
        currentAuditActivities[appendixIndex].appendix
      }}</template>
      <template #info-header>
        {{ currentAuditActivities[appendixIndex].name }}
      </template>
      <template #info>
        {{ currentAuditActivities[appendixIndex].objective }}
      </template>
      <template #user>
        {{ currentUserName }}
      </template>
      <template #audit-period>
        {{ auditPeriodTo }} - {{ auditPeriodFrom }}
      </template>
      <template #location>
        {{ officeName }}
      </template>
    </audit-base>

    <div class="body-padding">
      <v-expand-transition>
        <v-col v-if="!skip">
          <v-row style="margin-bottom:30px" class="appendix-heading"
            >Appendix Comments and Attachments</v-row
          >
          <v-row>
            <!-- Appendix Comments/Attachments -->
            <appendix-comments
              ref="appendixComments"
              v-model="currentAuditActivities[appendixIndex]"
              :isManager="isManager"
              :appendixIndex="appendixIndex"
              :currentUser="currentUserName"
            ></appendix-comments>
          </v-row>
        </v-col>
      </v-expand-transition>

      <div class="appendix-heading">Procedures</div>
      <div v-for="(data, index) in currentAppendixQuestions" :key="index">
        <template v-if="isTechAdvisorCheck(data.identifier)">
          <!-- SKIP QUSTIONS -->
          <template v-if="data.questionType == 'SKIP'">
            <div v-html="data.text"></div>
            <v-checkbox
              style="padding-left: 20px; margin: 0px"
              label="Skip Question"
              v-model="skip"
              :disabled="!isLead"
              @change="toggleSkip"
            ></v-checkbox>
          </template>

          <v-expand-transition>
            <div v-if="!skip">
              <!-- Other Questions without identifiers -->
              <template
                v-if="data.identifier == '' && data.questionType != 'SKIP'"
              >
                <div v-html="data.text"></div>
              </template>

              <!-- Other Questions -->
              <question-base
                ref="questionBase"
                v-else-if="data.questionType != 'SKIP' && !isE15(data)"
                v-model="currentAppendixQuestions[index]"
                :currentUser="value.auditorName"
                :appendixIndex="appendixIndex"
                :questionIndex="index"
                :isManager="isManager"
                :noComments="noComments(data)"
                :auditFrom="auditPeriodFrom"
                :auditTo="auditPeriodTo"
                :location="officeName"
              >
                <template #main>
                  <!-- <v-row
                    v-if="isA11AndAuditNotVerified(data) && !isManager"
                    style="margin-left: 0em;"
                  >
                    <v-chip color="#FBDD3E" text-color="black"
                      >Cannot be answered until audit is verified</v-chip
                    >
                  </v-row> -->
                  <v-row
                    v-if="
                      !isSupervisor &&
                        currentAppendixQuestions[index].questionType.includes(
                          'SUPERVISOR'
                        )
                    "
                    style="padding-left: 30px"
                  >
                    <v-chip
                      v-if="!isA11(data)"
                      color="#8C8C8C"
                      text-color="white"
                      >For completion by Branch Office Supervisor:</v-chip
                    >
                  </v-row>
                  <v-row style="margin:0; padding:10px" class="flex-nowrap">
                    <div style="padding-right: 5px">
                      {{ identifier(data) }}.
                    </div>
                    <div v-html="text(data)"></div>
                  </v-row>
                  <div
                    style="margin:0; padding:10px"
                    class="flex-nowrap"
                    v-if="data.questionType !== 'FYI'"
                  >
                    <question
                      @updateAudit="updateAudit"
                      :value="data"
                      :auditFrom="auditPeriodFrom"
                      :auditTo="auditPeriodTo"
                      :appendix="appendixIndex"
                      :index="index"
                      :officeId="officeId"
                      :disabled="isManager"
                    ></question>
                  </div>
                </template>
              </question-base>
            </div>
          </v-expand-transition>
        </template>
      </div>
      <v-divider></v-divider>

      <v-row style="margin:0; padding-top: 10px">
        <v-btn v-if="!isTechAdvisor" color="primary" @click="continueAudit"
          ><template v-if="appendixId != 'G'">Continue</template
          ><template v-else>Review</template> Audit</v-btn
        >
        <v-btn
          v-if="isTechAdvisor"
          color="primary"
          @click="techAdvisorSubmit"
          :disabled="taVerified"
          >Verify Audit</v-btn
        >
      </v-row>
      <v-row v-if="taVerified">
        <v-alert class="text warning">
          Audit has successfully been verified.
        </v-alert>
      </v-row>
    </div>
    <v-snackbar
      v-model="showSnackbar"
      :color="snackbarColor"
      :timeout="timeout"
      right
    >
      {{ this.progress }}
      <template v-if="closeBtn" v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          text
          @click="
            showSnackbar = false;
            closeBtn = false;
          "
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>
<style scoped>
.body-padding {
  padding-top: 300px;
  padding-left: 70px;
  padding-right: 40px;
  padding-bottom: 40px;
}
.appendix-heading {
  font-size: 24px;
  font-weight: 800;
  color: rgba(0, 0, 0, 0.6);
}
.text {
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  background: transparent;
}
</style>
<script>
import AuditBase from "./slots/AuditBase";
import QuestionBase from "../new-audits/slots/QuestionBase";
import Question from "./Question";
import { router } from "@/router";
import AppendixComments from "./AppendixComments";
import { authenticationService } from "@/_auth/authentication.service";
import { RoleCheck } from "@/_auth/role";
import { UPDATED_TEXT } from "../../local-audit-data/updatedText";
import { auditService } from "@/_services/audit.service";

export default {
  props: ["value", "taVerified"],
  components: {
    AuditBase,
    QuestionBase,
    Question,
    AppendixComments
  },
  data() {
    return {
      appendixId: "A",
      appendixIndex: null,
      currentAuditActivities: null,
      currentUserName: null,
      dateStarted: null,
      officeName: null,
      officeId: null,
      lastSavedAudit: null,
      auditPeriodTo: null,
      auditPeriodFrom: null,
      skip: null,
      isSupervisor: false,
      isManager: false,
      updateQueue: [],
      showSnackbar: false,
      timeout: -1,
      snackbarColor: "blue-grey",
      progress: "Updating...",
      closeBtn: false
    };
  },
  computed: {
    isTechAdvisor() {
      return RoleCheck.isTechAdvisor(authenticationService.currentUserValue);
    },

    isValidAppendix() {
      var firstQuestionIdentifier = this.currentAuditActivities[
        this.appendixIndex
      ].questions[0].identifier;
      return firstQuestionIdentifier.length > 0;
    },
    invalidAppendixHTML() {
      var htmlContent = ``;
      var header = true;
      var index = 0;
      var questionList = this.currentAuditActivities[this.appendixIndex]
        .questions;
      while (
        questionList[index].identifier.length == 0 &&
        index < questionList.length
      ) {
        if (header) {
          htmlContent += `<div style="font-size: 24px;font-weight: 800;color: rgba(0, 0, 0, 0.6);">${questionList[index].text}</div>`;
        } else {
          htmlContent += `<div>${questionList[index].text}</div>`;
        }
        header = false;
        index++;
      }
      return htmlContent;
    },
    currentAppendixQuestions() {
      var questions = this.currentAuditActivities[this.appendixIndex].questions;
      return questions;
    }
  },
  watch: {
    $route() {
      this.updatePage();
      if (this.skip == null) {
        this.skip = false;
        this.toggleSkip();
      }
    }
  },
  methods: {
    techAdvisorSubmit() {
      this.$emit("verifyAudit");
    },
    noComments(data) {
      if (this.isLead) {
        return (
          `${this.appendixId.toLowerCase()}${data.identifier}` != "e1" &&
          `${this.appendixId.toLowerCase()}${data.identifier}` != "a11" &&
          data.questionType.includes("SUPERVISOR")
        );
      } else if (this.isSupervisor) {
        return false;
      }
    },
    // TODO: remove (keeping this logic for now just in case);
    isA11AndAuditNotVerified() {
      // let isA11 =
      //   `${this.appendixId.toLowerCase()}${data.identifier}` === "a11";
      let auditNotVerfied = this.value.statusCode !== "VERIFIED";
      return auditNotVerfied;
    },
    isA11(data) {
      return `${this.appendixId.toLowerCase()}${data.identifier}` === "a11";
    },
    isTechAdvisorCheck(identifier) {
      if (!this.isTechAdvisor) {
        return true;
      } else if (
        this.isTechAdvisor &&
        `${this.appendixId.toLowerCase()}${identifier}` == "a11"
      ) {
        return true;
      } else {
        return false;
      }
    },
    toggleSkip() {
      var path = `/activities/${this.appendixIndex}/questions/0`;
      var op = "replace";
      var auditid = this.$route.params.id;
      var update = [{ suffix: "answer", value: this.skip }];
      this.updateAudit(update, path, op, auditid);
    },
    closeCommentBox() {
      if (this.$refs.questionBase) {
        this.$refs.questionBase.forEach(question => question.closeTabSection());
      }

      if (this.$refs.appendixComments) {
        this.$refs.appendixComments.closeTabs();
      }
    },
    updatePage() {
      this.appendixId = this.$route.params.appendixId.toUpperCase();
      this.currentAuditActivities = this.value.activities;
      this.currentUserName = `${authenticationService.currentUserValue.firstName} ${authenticationService.currentUserValue.lastName}`;
      this.dateStarted = this.value.dateStarted;
      this.officeName = this.value.officeName;
      this.officeId = this.value.officeId;
      this.checkFromToDateOrder();
      this.auditPeriodTo = this.value.auditPeriodTo;
      this.auditPeriodFrom = this.value.auditPeriodFrom;
      this.closeCommentBox();
      this.appendixIndex = this.appendixId.charCodeAt(0) - "A".charCodeAt(0);

      if (this.$refs.questionBase) {
        this.$refs.questionBase.forEach(question => question.closeTabSection());
      }

      if ("undefined" == typeof this.value.comments) {
        this.value.comments = [];
      }
      if (
        this.value.activities[this.appendixIndex].questions[0].questionType ==
        "SKIP"
      ) {
        this.skip = this.value.activities[
          this.appendixIndex
        ].questions[0].answer;
      } else {
        this.skip = false;
      }
      this.isSupervisor = RoleCheck.isSupervisor(
        authenticationService.currentUserValue
      );
      this.isManager = RoleCheck.isManager(
        authenticationService.currentUserValue
      );
      this.isLead = RoleCheck.isLead(authenticationService.currentUserValue);
    },
    continueAudit() {
      if (this.appendixId != "G") {
        var nextAppendix = String.fromCharCode(
          this.appendixId.charCodeAt(0) + 1
        );
        router.push(
          { name: "appendix", params: { appendixId: nextAppendix } },
          () => {}
        );
      } else {
        router.push({ name: "checklist-summary" }, () => {});
      }
    },
    checkFromToDateOrder() {
      if (this.value.auditPeriodTo < this.value.auditPeriodFrom) {
        let temp = this.value.auditPeriodFrom;
        this.value.auditPeriodFrom = this.value.auditPeriodTo;
        this.value.auditPeriodTo = temp;
      }
    },
    updateAudit(updates, path, op, auditid) {
      // push to updateQueue
      this.updateQueue.push({
        updates: updates,
        path: path,
        op: op,
        auditid: auditid
      });
      while (this.updateQueue.length > 0) {
        // pop from updateQueue
        let elem = this.updateQueue[0];
        this.updateQueue.shift();
        this.progress = "Updating...";
        this.snackbarColor = "blue-grey";
        this.timeout = -1;
        this.showSnackbar = true;
        auditService
          .updateAudit(
            elem.auditid,
            elem.updates.map(e => {
              return {
                op: elem.op,
                path: elem.path + "/" + e.suffix,
                value: e.value
              };
            })
          )
          .then(r => {
            console.log('audit updated', r);
            this.snackbarColor = "green";
            this.progress = "Updated!";
            this.timeout = "2000";
          })
          .catch(e => {
            this.snackbarColor = "red";
            this.progress = "Unable to update at this time";
            this.timeout = "2000";
            console.log(e);
            // retry after 15s timeouts
            setTimeout(() => {
                this.snackbarColor = "red";
                this.timeout = "15000";
              }, 2000);
            for (let pause = 1; pause <= 15; pause += 1) {
              setTimeout(() => {
                this.progress = `Changes are not saved. Retry in ${Math.abs(16-pause)}s`;
              }, (pause*1000 + 1000));
            }
            setTimeout(() => this.updateAudit(updates, path, op, auditid), 17000);
          });
      }
      // this.$emit("updateAudit", updates, path, op, auditid);
    },
    identifier(data) {
      // var key = this.$route.params.appendixId.toUpperCase() + data.identifier;
      if (this.$route.params.appendixId.toUpperCase() === "E") {
        if (data.identifier > 15) {
          return data.identifier - 1;
        }
      }
      return data.identifier;
    },
    text(data) {
      var key = this.$route.params.appendixId.toUpperCase() + data.identifier;
      if (key in UPDATED_TEXT) {
        return UPDATED_TEXT[key].text.replace(/\r?\n/g, "<br/>");
      } else {
        return data.text.replace(/\r?\n/g, "<br/>");
      }
    },
    isE15(data) {
      var key = this.$route.params.appendixId.toUpperCase() + data.identifier;
      return key === "E15";
    }
  },
  created() {
    this.updatePage();
  }
};
</script>
