<template>
  <div>
    <basic-question
      v-model="value"
      @update="update"
      :disabled="disabled"
    ></basic-question>
    <additional-actions-required-base :disabled="disabled">
      <template #row1>
        <br />
        <div style="margin-left: 5%;" v-html="value.subitems[1].text"></div>
      </template>
    </additional-actions-required-base>
    <basic-question
      :disabled="disabled"
      questionLabel="Completed"
      v-model="value.subitems[1]"
      @update="updateSubAns"
    ></basic-question>
  </div>
</template>
<script>
import AdditionalActionsRequiredBase from "../../slots/AdditionalActionsRequiredBase";
import BasicQuestion from "../BasicQuestion";
export default {
  props: ["value", "disabled"],
  components: {
    AdditionalActionsRequiredBase,
    BasicQuestion
  },
  data() {
    return {
      ans: null,
      subAns2: null
    };
  },
  methods: {
    updateSubAns(updates) {
      this.subAns2 = updates[0].value;
      this.$emit("update", [
        { suffix: "subitems/1/answer", value: updates[0].value },
        this.answered()
      ]);
    },
    update(updates) {
      this.ans = updates[0].value;
      this.$emit("update", [updates[0], this.answered()]);
    },
    answered() {
      if (
        (this.value.answer != null || this.ans != null) &&
        (this.value.subitems[1].answer != null || this.subAns2 != null)
      ) {
        return { suffix: "answered", value: true };
      } else {
        return { suffix: "answered", value: false };
      }
    }
  }
};
</script>
