<template>
  <div>
    <div v-if="buttonMode">
      <v-progress-circular
        v-if="loading"
        indeterminate
        color="primary"
        class="mr-5"
      ></v-progress-circular>
      <v-btn
        class="main-btn"
        large
        outlined
        @click="$refs.fileUploadInput.$refs.input.click()"
        >{{ label }}</v-btn
      >
    </div>

    <v-card class="mx-auto" outlined :class="buttonMode ? 'invisible' : ''">
      <v-row align="center">
        <v-col cols="11">
          <v-file-input
            show-size
            :disabled="loading"
            :label="label"
            accept="image/*"
            progress
            v-model="fileName"
            :counter="counter"
            @change="fileUploaded"
            :error="!!fileSizeError"
            :error-messages="getErrorMessage()"
            ref="fileUploadInput"
          ></v-file-input>
        </v-col>
        <v-col cols="1">
          <v-progress-circular
            v-if="loading"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<style>
.main-btn {
  background-color: white !important;
  color: #0098da !important;
  height: 40px !important;
  border-color: #0098da !important;
  font-size: 15px !important;
}

.invisible {
  display: none;
}
</style>
<script>
import { fileUploadService } from "@/_services/file-upload.service";
import { auditService } from "@/_services/audit.service";
import { authenticationService } from "@/_auth/authentication.service";

export default {
  name: "FileUpload",

  data() {
    return {
      loading: false,
      fileName: null,
      fileSizeError: false,
      fileTypeError: false
    };
  },
  props: {
    label: {
      default: "Upload attachment",
      type: String
    },
    appendixIndex: Number,
    questionIndex: Number,
    counter: {
      default: false,
      type: Boolean
    },
    attachmentList: {
      default: function() {
        return [];
      },
      type: Array
    },
    buttonMode: {
      default: false,
      type: Boolean
    }
  },

  methods: {
    getErrorMessage() {
      if (this.fileSizeError) {
        return "Error: File size should not exceed 5MB";
      } else if (this.fileTypeError) {
        return "Error: File must be an image.";
      }
    },

    fileUploaded(file) {
      this.fileSizeError = false;
      this.fileTypeError = false;

      if (file == null) {
        return;
      }

      if (!file.type.startsWith("image/")) {
        this.fileTypeError = true;
        console.error("Error: File must be an image.");
        return;
      }

      // File size should not exceed 5MB
      if (file && file.size > 5000000) {
        this.fileSizeError = true;
        console.error("Error: File size should not exceed 5MB.");
      } else {
        this.loading = true;
        const auditId = this.$route.params.id;

        fileUploadService
          .presignedUpload(file)
          .then(result => {
            const currentUser = authenticationService.currentUserValue;

            const path = this.getUpdatePath();
            const op = "add";
            const currentTime = new Date().toString();
            const value = {
              attachDate: currentTime,
              attacherName: `${currentUser.firstName} ${currentUser.lastName}`,
              attachmentKey: result,
              attacher: currentUser
            };
            var objList = [];
            if (this.attachmentList.length == 0) {
              var object = {
                op: op,
                path: path,
                value: []
              };
              objList.push(object);
            }
            objList.push({
              op: op,
              path: path + "/",
              value: value
            });

            auditService
              .updateAudit(auditId, objList)
              .then(() => this.$emit("attachmentAdded", value))
              .catch(error => console.log(error));
          })
          .catch(err => console.log(err))
          .finally(() => {
            this.fileName = null;
            this.loading = false;
          });
      }
    },
    getUpdatePath() {
      if (
        "undefined" !== typeof this.appendixIndex &&
        "undefined" !== typeof this.questionIndex
      ) {
        return `/activities/${this.appendixIndex}/questions/${this.questionIndex}/attachments`;
      } else if ("undefined" !== typeof this.appendixIndex) {
        return `/activities/${this.appendixIndex}/attachments`;
      } else {
        return `/attachments`;
      }
    }
  }
};
</script>
