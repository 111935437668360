<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on">mdi-pencil</v-icon>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Update Audit Period</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row style="color: red" class="mb-7">
            <p>
              Verify that all date ranges entered for this audit period are also
              updated. The page will refresh after the audit period has
              successfully changed.
            </p>
            <p>
              Review Appendix C, Question 9 as answers will be reset to
              accommodate for the new audit period.
            </p>
          </v-row>
          <v-row>
            <v-col>
              <v-menu
                dense
                ref="dateRangeMenu"
                v-model="dateRangeMenu"
                :close-on-content-click="false"
                transition="scale-transiton"
                offset-y
                max-width="290x"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    ref="dateRange"
                    v-model="dateRangeText"
                    placeholder="to - from date range"
                    append-icon="mdi-calendar"
                    @click:append="dateRangeMenu = true"
                    @focus="dateRangeMenu = true"
                    v-bind="attrs"
                    v-on="on"
                    class="selector-size"
                    outlined
                    readonly
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  @click:date="changeCalendarDate"
                  dense
                  v-model="selectedDates"
                  range
                  :min="minAuditDate"
                  :picker-date.sync="shownDate"
                  ref="calendar"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row v-if="showError">
            <p class="error-message">
              An error occurred while attempting to update the audit period.
              Please select a valid date range and try again.
            </p>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="cancelUpdate">
          Close
        </v-btn>
        <v-btn color="blue darken-1" text @click="updateAuditDates">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style scoped>
.v-icon {
  color: white;
}
.error-message {
  color: red;
  font-weight: bold;
}
</style>
<script>
import { auditService } from "@/_services/audit.service";

export default {
  data: () => ({
    dialog: false,
    dateRangeMenu: false,
    shownDate: null,
    selectedDates: [],
    showError: false
  }),
  computed: {
    dateRangeText() {
      if (this.selectedDates.length > 0) {
        // TODO: compare dates and have oldest first in selectedDates arr
        var retStr = "";
        if (this.selectedDates.length > 0 && this.selectedDates[0] != "") {
          retStr = this.selectedDates[0].replaceAll("-", "/") + " - ";
        }
        if (this.selectedDates.length > 1 && this.selectedDates[1] != "") {
          var date0 = new Date(this.selectedDates[0]);
          var date1 = new Date(this.selectedDates[1]);
          if (date0 > date1) {
            retStr = this.selectedDates[1].replaceAll("-", "/") + " - ";
            retStr += this.selectedDates[0].replaceAll("-", "/");
          } else {
            retStr += this.selectedDates[1].replaceAll("-", "/");
          }
        }
        return retStr;
      }
      return "";
    },
    minAuditDate() {
      let minDate = new Date();
      minDate.setUTCMonth(minDate.getUTCMonth() - 12);
      minDate.setUTCDate(1);
      return minDate.toISOString().slice(0, 10);
    }
  },
  methods: {
    changeCalendarDate() {
      if (this.selectedDates.length == 1) {
        const calendar = this.$refs.calendar;
        let newDate = calendar.pickerDate;
        let split = newDate.split("-");
        let date = new Date(split[0], split[1] - 1, 1);
        let aheadDate = new Date(date.setMonth(date.getMonth() + 5));
        aheadDate = aheadDate.toLocaleDateString();
        let splitFinalDate = aheadDate.split("/");
        this.shownDate = splitFinalDate[2] + "-" + splitFinalDate[0];
      }
      return;
    },
    updateAuditDates() {
      const auditId = this.$route.params.id;
      auditService
        .updateAudit(auditId, [
          {
            op: "replace",
            path: "/auditPeriodFrom",
            value: this.selectedDates[0]
          },
          {
            op: "replace",
            path: "/auditPeriodTo",
            value: this.selectedDates[1]
          },
          {
            op: "replace",
            path: "/activities/2/questions/10/subitems/3/dates",
            value: "[]"
          }
        ])
        .then(() => {
          this.dialog = false;
          this.showError = false;
          this.$router.go();
        })
        .catch(reason => {
          console.log("Error: " + reason);
          this.showError = true;
        });
    },
    cancelUpdate() {
      this.shownDate = null;
      this.selectedDates = [];
      this.dialog = false;
    }
  }
};
</script>
