<template v-if="value">
  <v-card :elevation="hasFocus ? 10 : 0">
    <template v-if="value.questionType == 'SKIP'">
      <SkipQuestion v-model="value"></SkipQuestion>
    </template>
    <template v-if="value.questionType == 'BASIC'">
      <BasicQuestion v-model="value"></BasicQuestion>
    </template>
    <template v-if="value.questionType == 'FYI'">
      <FyiQuestion v-model="value"></FyiQuestion>
    </template>
    <template v-if="value.questionType == 'SUPERVISOR'">
      <SupervisorQuestion v-model="value"></SupervisorQuestion>
    </template>
    <template v-if="value.questionType == 'SUPERVISOR-REQUIRED'">
      <SupervisorQuestion v-model="value" includeRequired></SupervisorQuestion>
    </template>
    <template v-if="value.questionType == 'TASK'">
      <TaskQuestion v-model="value"></TaskQuestion>
    </template>
    <template v-if="value.questionType == 'FROMTO'">
      <FromToQuestion
        v-model="value"
        :audit-period="auditPeriod"
      ></FromToQuestion>
    </template>
    <template v-if="value.questionType == 'DATE-GRID'">
      <DateGridQuestion
        v-model="value"
        :audit-period="auditPeriod"
        v-on="$listeners"
      ></DateGridQuestion>
    </template>
    <template v-if="value.subitems && value.subitems.length > 0">
      <template v-for="(question, index) in value.subitems">
        <!-- <v-divider :key="'divider' + index"></v-divider> -->
        <Question
          class="pl-5"
          :key="index"
          :isManager="disabled"
          v-model="value.subitems[index]"
          :audit-period="auditPeriod"
          v-on="$listeners"
        ></Question>
      </template>
    </template>
    <template>
      <Comment v-model="value.comments"></Comment>
    </template>
  </v-card>
</template>

<style lang="less">
.question {
  /* font-size: 10px; */
  text-align: left;
}
.indented-question {
  //   // Base non-changed values:
  //   // text-indent: 0px;
  //   // padding-left: 12px;
  display: inline-block;
  text-indent: -16px;
  padding-left: 28px;
}
</style>

<script>
import SkipQuestion from "@/components/audit/questiontype/SkipQuestion";
import BasicQuestion from "@/components/audit/questiontype/BasicQuestion";
import FyiQuestion from "@/components/audit/questiontype/FyiQuestion";
import SupervisorQuestion from "@/components/audit/questiontype/SupervisorQuestion";
import TaskQuestion from "@/components/audit/questiontype/TaskQuestion";
import FromToQuestion from "@/components/audit/questiontype/FromToQuestion";
import DateGridQuestion from "@/components/audit/questiontype/DateGridQuestion";
import Comment from "./Comment";
import Question from "./Question";

export default {
  name: "Question",
  props: ["value", "auditPeriod", "hasFocus", "disabled"],
  components: {
    Question,
    SkipQuestion,
    BasicQuestion,
    FyiQuestion,
    SupervisorQuestion,
    TaskQuestion,
    FromToQuestion,
    DateGridQuestion,
    Comment
  },
  data: () => ({}),
  computed: {
    answer() {
      return this.value.answer;
    },
    answerRequired() {
      // optional element in questions that is only used in SUPERVISOR-REQUIRED type right now
      return this.value.answerRequired;
    },
    answerFromToDates() {
      // optional element in questions that is used by FROMTOT type
      return this.value.answerFromToDates;
    },
    isSatisfied() {
      //TODO: Figure out if the question has been answered and is valid
      return false;
    }
  },
  watch: {
    answer() {
      this.triggerSave();
    },
    answerRequired() {
      this.triggerSave();
    },
    answerFromToDates() {
      if (
        this.answerFromToDates &&
        this.answerFromToDates.length == 2 &&
        this.answerFromToDates[1] != null
      ) {
        this.triggerSave();
      }
    },
    answerGrid() {
      this.triggerSave();
    }
  },
  methods: {
    triggerSave() {
      this.$emit("trigger-save");
    }
  }
};
</script>
