<template>
  <v-container>
    <v-card v-if="app.layout == 'old-app-layout'">
      <v-row>
        <v-col>
          <v-btn color="success" :to="{ path: '/startAudit' }"
            >Begin New Audit</v-btn
          >
        </v-col>
      </v-row>
    </v-card>

    <template v-if="app.layout == 'new-app-layout'" cols="12">
      <!-- Starting New Audit -->
      <v-row @click="disableEA = true">
        <startNewAudit></startNewAudit>
      </v-row>
      <!-- Existing Audits -->
      <v-row @click="disableEA = false">
        <auditList ref="auditList" :disabled="disableEA"></auditList>
      </v-row>
    </template>
  </v-container>
</template>
<script>
import commonMixin from "@/mixins/common";
import auditList from "../components/AuditList";
import startNewAudit from "../components/StartNewAudit";

export default {
  name: "Home_Lead",
  data: () => {
    return {
      disableEA: false
    };
  },
  mixins: [commonMixin],
  components: {
    auditList,
    startNewAudit
  }
};
</script>
