import { handleResponse } from "@/_auth/handle-response";
// import { requestOptions } from "@/_auth/request-options";
import { Auth } from "aws-amplify";

export const userService = {
  getAll,
  getById
};

async function getAll() {
  let url = `${process.env.VUE_APP_AUTH_BASE}${process.env.VUE_APP_AUTH_GET_USER_URI}`;
  // return fetch(url, requestOptions.get()).then(handleResponse);
  const response = await fetch(url, {
    method: "GET",

    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
      "Content-Type": "application/json"
    }
  }).then(handleResponse);
  return await response;
}

let cachedUserList = [];
async function getById(id) {
  let cachedUser = cachedUserList.find(u => (u.id = id));
  if (cachedUser == undefined) {
    let url = `${process.env.VUE_APP_AUTH_BASE}${process.env.VUE_APP_AUTH_GET_USER_URI}/${id}`;
    // cachedUser = fetch(url, requestOptions.get())
    cachedUser = await fetch(url, {
      method: "GET",

      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Content-Type": "application/json"
      }
    })
      .then(handleResponse)
      .then(u => {
        cachedUserList.push(u);
        return u;
      });
  } else {
    cachedUser = new Promise(resolve => resolve(cachedUser));
  }
  return cachedUser;
}
