<template>
  <v-card elevation="0">
    <v-row>
      <v-col class="question">
        <v-row>
          <v-col>
            {{ identifier }}
            <span v-html="value.text.replace(/\r?\n/g, '<br/>')"></span>
          </v-col>
        </v-row>
        <v-row noGutters>
          <v-col>
            <v-simple-table>
              <tbody>
                <tr v-if="gridData.length == 0">
                  <td colspan="2">
                    Please enter audit period to see date entry grid.
                  </td>
                </tr>
                <tr
                  v-for="(line, index) in gridData"
                  :key="index"
                  :class="
                    !monthsInAuditPeriod.includes(line.label) ? 'red' : ''
                  "
                >
                  <td width="100px">
                    {{ line.label }}
                  </td>
                  <td v-for="(l, index2) in [0, 1]" :key="index + '-' + index2">
                    <v-menu
                      dense
                      v-model="showGridDatePicker[index + '-' + index2]"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          clearable
                          v-model="line.value[index2]"
                          label=""
                          :prepend-icon="
                            $vuetify.breakpoint.xs ? '' : 'mdi-calendar'
                          "
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          @input="triggerDateGridChange(index, index2)"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        dense
                        v-model="line.value[index2]"
                        :min="line.label"
                        :max="line.label + '-31'"
                        :picker-date="line.label"
                        @change="triggerDateGridChange(index, index2)"
                      ></v-date-picker>
                    </v-menu>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4" sm="2" md="2" lg="2" xl="1">
        <v-radio-group v-model="value.answer" class="mt-0 pt-0">
          <span>{{ adherenceDisplayedWord }}</span>
          <v-radio
            v-for="(answerChoice, index) in ['YES', 'NO', 'N/A']"
            :key="index"
            :label="answerChoice"
            :value="answerChoice"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
  </v-card>
</template>

<style lang="less"></style>

<script>
export default {
  props: ["value", "auditPeriod"],
  data: () => ({
    showGridDatePicker: [[]]
  }),
  computed: {
    identifier() {
      if (!this.value.identifier) {
        return null;
      }
      if (this.value.identifier.length > 0) {
        let rv = this.value.identifier;
        if (this.value.identifier != "=>") {
          rv += ".";
        }
        return rv;
      }
      return null;
    },
    adherenceDisplayedWord() {
      if (this.$vuetify.breakpoint.width < 280) {
        return "";
      } else if (this.$vuetify.breakpoint.width < 320) {
        return "Adheres?";
      }
      return "Adherence:";
    },
    gridData() {
      var grid = [];
      this.monthsInAuditPeriod.forEach(month =>
        grid.push({ label: month, value: [null, null] })
      );
      if (this.value && this.value.answerGrid && this.value.answerGrid.length) {
        this.value.answerGrid.forEach(answer => {
          if (
            answer.label &&
            answer.value &&
            Array.isArray(answer.value) &&
            answer.value.length > 0
          ) {
            let answerValue = JSON.parse(JSON.stringify(answer.value));
            if (
              answerValue &&
              answerValue.length &&
              answerValue.some(element => element)
            ) {
              let index = grid.findIndex(
                element => element.label == answer.label
              );
              if (index >= 0) {
                if (answer.value[1]) {
                  let firstDay = answer.value[0];
                  if (firstDay) {
                    firstDay = firstDay.split("-")[2];
                  }
                  let secondDay = answer.value[1].split("-")[2];
                  if (firstDay > secondDay || firstDay == null) {
                    let placeHolder = answer.value[0];
                    answer.value[0] = answer.value[1];
                    answer.value[1] = placeHolder;
                  }
                }
                grid[index].value = answer.value;
              } else {
                grid.push({ label: answer.label, value: answer.value });
              }
            }
          }
        });
      }
      return grid;
    },

    monthsInAuditPeriod() {
      if (!this.auditPeriod || this.auditPeriod.length != 2) {
        return [];
      }
      var start = this.auditPeriod[0].split("-");
      var end = this.auditPeriod[1].split("-");
      var startYear = parseInt(start[0]);
      var endYear = parseInt(end[0]);
      var dates = [];

      for (var i = startYear; i <= endYear; i++) {
        var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
        var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
        for (
          var j = startMon;
          j <= endMonth;
          j = j > 12 ? j % 12 || 11 : j + 1
        ) {
          var month = j + 1;
          var displayMonth = month < 10 ? "0" + month : month;
          dates.push([i, displayMonth].join("-"));
        }
      }
      return dates;
    }
  },
  methods: {
    triggerDateGridChange(index1, index2) {
      this.showGridDatePicker[index1 + "-" + index2] = false;
      this.value.answerGrid = this.gridData;
      this.$emit("trigger-save");
    }
  }
};
</script>
