<template>
  <v-container>
    <template v-if="app.layout == 'new-app-layout'" cols="12">
      <!-- Existing Audits -->
      <v-row @click="disableEA = false">
        <auditList :disabled="disableEA"></auditList>
      </v-row>
    </template>
  </v-container>
</template>
<script>
import commonMixin from "@/mixins/common";
import auditList from "../components/AuditList";

export default {
  name: "Home_Manager",
  data: () => {
    return {
      disableEA: false
    };
  },
  mixins: [commonMixin],
  components: {
    auditList
  }
};
</script>
