<template>
  <v-container>
    <v-row>
      <auditList></auditList>
    </v-row>
  </v-container>
</template>
<script>
import auditList from "../components/AuditList";

export default {
  components: {
    auditList
  }
};
</script>
