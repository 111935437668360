import axios from "axios";
import { Auth } from "aws-amplify";
import { handleResponse } from "@/_auth/handle-response";

export const fileUploadService = {
  presignedUpload
};

function sanitizeString(string) {
  const specialCharRegex = /[^a-zA-Z0-9/.-]/g;
  return string.replace(specialCharRegex, '');
}

async function presignedUpload(selectedFile) {
  try {
    var uniqid = require("uniqid");
    var fileId = uniqid();
    // encoded to satisfy the checkmarx
    const generatedKey = sanitizeString(`audits/${fileId}/${selectedFile.name}`);
    const presignedData = await getPresignedPostData(selectedFile, generatedKey);
    const url = await uploadFileToS3(presignedData, selectedFile, generatedKey);
    return url;
  } catch (error) {
    console.log("An error occurred while uploading the file:", error);
    Promise.reject(error);
  }
}

async function getPresignedPostData(selectedFile, generatedKey) {
  const url = process.env.VUE_APP_SERVICE_BASE + "/attachment/upload";

  if (selectedFile) {
    let payload = {
      bucket: process.env.VUE_APP_ATTACHMENT_BUCKET,
      key: generatedKey
    };

    return await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    })
      .then(handleResponse)
      .then(response => {
        return response;
      });
  }
}

/**
 * Upload file to S3 with previously received presigned object.
 * @param presignedObject
 * @param file
 * @returns {Promise<any>}
 **/
async function uploadFileToS3(presignedObject, selectedFile, generatedKey) {
  return new Promise((resolve, reject) => {
    const data = new FormData();
    const endpoint = presignedObject.presignedData.url;
    // const bucket = presignedObject.presignedData.fields["bucket"];

    Object.keys(presignedObject.presignedData.fields).forEach(key => {
      data.append(key, presignedObject.presignedData.fields[key]);
    });

    data.append("file", selectedFile);

    axios({
      method: "post",
      url: endpoint,
      data: data
    })
      .then(response =>
        response.status === 204
          ? resolve(generatedKey)
          : reject("An error prevented the file to be uploaded")
      )
      .catch(error => reject(error.message));
  });
}
