<template>
  <div>
    <v-list-item
      v-for="activity in activities"
      class="pl-0 mb-3 ml-3"
      :key="activity.appendix"
      @click.stop="appendixClicked(activity.appendix)"
    >
      <v-row align-center>
        <v-col cols="1">
          <v-avatar
            v-if="!submitted"
            size="36"
            color="#0298da"
            class="text-center mx-auto"
          >
            <span class="white--text">{{ activity.appendix }}</span>
          </v-avatar>
          <v-badge
            color="orange"
            icon="mdi-exclamation"
            left
            overlap
            class="ml-2"
            v-else-if="activity.appendix in appendixWithErrors"
          >
            <v-avatar size="36" color="#0298da" class="text-center mx-auto">
              <span class="white--text">{{ activity.appendix }}</span>
            </v-avatar>
          </v-badge>
          <v-badge
            color="green"
            icon="mdi-check"
            left
            overlap
            class="ml-2"
            v-else
          >
            <v-avatar size="36" color="#0298da" class="text-center mx-auto">
              <span class="white--text">{{ activity.appendix }}</span>
            </v-avatar>
          </v-badge>
        </v-col>
        <v-col cols="11">
          <p white class="ml-3">
            {{ activity.name }}
          </p>
        </v-col>
      </v-row>
    </v-list-item>
    <v-list-item
      v-for="view in summaryViews"
      :key="view.name"
      link
      class="ml-2"
      :to="{ name: view.link }"
    >
      <v-row>
        <v-col cols="1">
          <v-icon>{{ view.icon }} </v-icon>
        </v-col>
        <v-col cols="11">
          <p white>{{ view.name }}</p>
        </v-col>
      </v-row>
    </v-list-item>
  </div>
</template>
<script>
import { router } from "@/router";
import { auditService, auditStatusService } from "@/_services/audit.service";

export default {
  name: "Checklist",
  props: ["activities"],
  data: () => ({
    submitted: false,
    appendixWithErrors: {},
    summaryViews: [
      {
        name: "Audit Comments",
        icon: "mdi-comment-text-outline",
        link: "comment-summary"
      },
      {
        name: "Audit Attached Documents",
        icon: "mdi-text-box-multiple-outline",
        link: "attachment-summary"
      },
      {
        name: "Check and Submit Audit",
        icon: "mdi-checkbox-marked-circle",
        link: "checklist-summary"
      }
    ]
  }),
  methods: {
    appendixClicked(id) {
      router.push({ name: "appendix", params: { appendixId: id } }, () => {});
    }
  },
  created() {
    this.subscription = auditStatusService
      .getAuditStatus()
      .subscribe(status => {
        this.appendixWithErrors = auditStatusService.appendixWithErrors(status);
        this.submitted = auditService.submittedValue;
      });
  }
};
</script>
