<template>
  <v-card v-if="value" height="100%" class="smaller">
    <!-- <v-tabs v-if="uiType == 'A'" v-model="visibleTab">
      <v-tab
        v-for="appendix in value.activities"
        :key="appendix.appendix"
        :href="'#' + appendix.appendix"
        >{{ appendix.name }}</v-tab
      >
    </v-tabs>
    <v-tabs v-if="uiType == 'B'" v-model="visibleTab">
      <v-tab
        v-for="appendix in value.activities"
        :key="appendix.appendix"
        :href="'#' + appendix.appendix"
        >{{ appendix.appendix }}</v-tab
      >
    </v-tabs> -->
    <v-select
      class="mx-3"
      v-if="uiType == 'C'"
      :items="value.activities"
      :item-text="item => item.appendix + ': ' + item.name"
      item-value="appendix"
      v-model="visibleTab"
      label="Appendix"
    ></v-select>
    <!-- <v-btn fab text elevation="2" v-if="uiType == 'D'" absolute top right>Appendix A</v-btn>
    <v-speed-dial
      v-if="uiType == 'D'"
      style="z-index: 999"
      fixed
      v-model="appendixFab"
      top
      right
      direction="bottom"
      transition="slide-y-transition"
    >
      <template v-slot:activator>
        <v-btn v-model="appendixFab" color="accent" fab>{{ visibleTab }}</v-btn>
      </template>
      <template v-for="appendix in value.activities">
        <v-btn
          :key="appendix.appendix"
          fab
          small
          color="primary"
          @click="visibleTab = appendix.appendix"
        >
          {{ appendix.appendix }}
        </v-btn>
      </template>
    </v-speed-dial> -->

    <v-tabs-items v-model="visibleTab">
      <v-tab-item
        v-for="(appendix, index) in value.activities"
        :key="appendix.appendix"
        :value="appendix.appendix"
      >
        <Appendix
          v-model="value.activities[index]"
          :AuditMetaDetails="auditMetaDetails"
          :AuditPeriodFrom="value.auditPeriodFrom"
          :AuditPeriodTo="value.auditPeriodTo"
          v-on="$listeners"
          @update:auditPeriod="setAuditPeriod($event)"
        ></Appendix>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import Appendix from "@/components/audit/Appendix";
import { userService } from "@/_auth/user.service";
import { officeService } from "@/_services/office.service";
export default {
  props: ["value"],
  components: { Appendix },
  data: () => ({
    visibleTab: null,
    appendixFab: false
  }),
  computed: {
    uiType() {
      return this.$root.$children[0].uiType;
    }
  },
  asyncComputed: {
    async branchOfficeName() {
      if (this.value == null) {
        return "";
      } else if (this.value.officeName) {
        return this.value.officeName;
      }
      return await officeService.getOfficeNameByOfficeId(this.value.officeId);
    },
    async auditorName() {
      if (this.value == null) {
        return "";
      } else if (this.value.auditorName) {
        return this.value.auditorName;
      }
      var auditor = await userService.getById(this.value.auditorId);
      return auditor.firstName + " " + auditor.lastName;
    },
    async auditMetaDetails() {
      return await {
        branchOfficeName: this.branchOfficeName,
        auditorName: this.auditorName,
        auditStatusCode: (this.value || {}).statusCode,
        auditorId: (this.value || {}).auditorId
      };
    }
  },
  methods: {
    setAuditPeriod(value) {
      this.value.auditPeriodFrom = value.AuditPeriodFrom;
      this.value.auditPeriodTo = value.AuditPeriodTo;
      if (value.AuditPeriodFrom && value.AuditPeriodTo) {
        this.$emit("trigger-save");
      }
    }
  },
  mounted: function() {
    // TODO: This should remember and jump to last in-progress section
    this.visibleTab = "A";
  }
};
</script>

<style>
.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
  > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
  .v-slide-group__prev {
  display: none !important;
}
</style>
