/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

// DEV (gtpin)
// const awsmobile = {
//     "aws_project_region": "us-west-2",
//     "aws_dynamodb_all_tables_region": "us-west-2",
//     "aws_dynamodb_table_schemas": [
//         {
//             "tableName": "scg-gtpin-dev-wus2-dydb-intoffaud-table",
//             "region": "us-west-2"
//         }
//     ],
//     "aws_user_files_s3_bucket": "scg-gtpin-dev-wus2-s3-intoffaud",
//     "aws_user_files_s3_bucket_region": "us-west-2",
//     "aws_cognito_identity_pool_id": "us-west-2:c8d145eb-f5fb-4ae2-a406-8a86fb470c0c",
//     "aws_cognito_region": "us-west-2",
//     "aws_user_pools_id": "us-west-2_HxSctU3xZ",
//     "aws_user_pools_web_client_id": "4gic901qavg1lhmt7kpslmmte1",
//     "oauth": {
//         "domain": "dev-af-gtpin-socalgas.auth.us-west-2.amazoncognito.com",
//         "scope": [
           
//             "email",
//             "openid",
//             "aws.cognito.signin.user.admin",
       
//         ],
//         "redirectSignIn": "https://dev.af-gtpin.socalgas.com/",
//         "redirectSignOut": "https://dev.af-gtpin.socalgas.com/",
//         "responseType": "token",
//         "client_id": "6lk819lgffurp2jh32er55vbju"
//     },
//     "aws_cloud_logic_custom": [
//         {
//             "name": "scg-gtpin-dev-wus2-api-gw-intoffaud",
//             "endpoint": "https://9t2uvpa496.execute-api.us-west-2.amazonaws.com/dev",
//             "region": "us-west-2"
//         }
//     ]
// };

// TST
const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_dynamodb_all_tables_region": "us-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "scg-gtpin-prd-wus2-dydb-ioa-table",
            "region": "us-west-2"
        }
    ],
    //"aws_cognito_identity_pool_id": "us-west-2:800925d4-8e47-42af-9ee1-699c7f6b6f04",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_HvkGVBlGY",
    "aws_user_pools_web_client_id": "1tgfurpmsjg0b0mjjlqni4t9u",
    "oauth": {
        "domain": "prd-af-gtpin.auth.us-west-2.amazoncognito.com",
        "scope": [
          
            "email",
            "openid",
            "aws.cognito.signin.user.admin",
      
        ],
        "redirectSignIn": "https://af-gtpin.socalgas.com/",
        "redirectSignOut": "https://af-gtpin.socalgas.com/",
        "responseType": "token",
        "client_id": "6560g2lka64ok6nfvqb5jpcjrm"
    },
    "aws_cloud_logic_custom": [
        {
            "name": "scg-gtpin-prd-wus2-api-gw-ioa",
            "endpoint": "https://d8bcikrj4d.execute-api.us-west-2.amazonaws.com/prd",
            "region": "us-west-2"
        }
    ]
};

export default awsmobile;
