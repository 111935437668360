<template v-if="auditList.length == 0">
  <div>
    <div v-if="auditListLoaded">
      <v-container>
        <v-row
          :style="{ color: textColor('black') }"
          class="section-header section-margin"
          cols="12"
        >
          <span v-if="!isManager">Existing Audits</span>
          <span v-if="isManager">Approved Audits</span>
        </v-row>

        <template cols="12">
          <v-row
            v-if="outstandingAuditsCount == 0"
            :style="{ color: textColor('black') }"
            >You have no outstanding {{ auditPlural }}
          </v-row>
          <v-row
            v-if="outstandingAuditsCount > 0"
            :style="{ color: textColor('black') }"
          >
            You have
            <a strong :style="{ color: textColor('red') }" id="space"
              >{{ outstandingAuditsCount }}
            </a>
            outstanding {{ auditPlural }}
          </v-row>
          <v-row v-if="auditList.length > 0" class="section-margin">
            <v-simple-table style="border: 1px solid lightgrey;">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" @click="sortAudits('dateStarted')">
                      <a :style="{ color: textColor('black') }">Start Date</a>
                      <v-icon
                        v-if="
                          sorting.columnName === 'dateStarted' &&
                            sorting.direction === 'desc'
                        "
                        small
                        :style="{ color: textColor('gray'), cursor: 'pointer' }"
                        class="space"
                        >mdi-arrow-down-thin</v-icon
                      >
                      <v-icon
                        v-if="
                          sorting.columnName === 'dateStarted' &&
                            sorting.direction === 'asc'
                        "
                        small
                        :style="{ color: textColor('gray'), cursor: 'pointer' }"
                        class="space"
                        >mdi-arrow-up-thin</v-icon
                      >
                    </th>
                    <th class="text-left" @click="sortAudits('officeName')">
                      <a :style="{ color: textColor('black') }">Branch Office</a>
                      <v-icon
                        v-if="
                          sorting.columnName === 'officeName' &&
                            sorting.direction === 'desc'
                        "
                        small
                        :style="{ color: textColor('gray') }"
                        class="space"
                        >mdi-arrow-down-thin</v-icon
                      >
                      <v-icon
                        v-if="
                          sorting.columnName === 'officeName' &&
                            sorting.direction === 'asc'
                        "
                        small
                        :style="{ color: textColor('gray') }"
                        class="space"
                        >mdi-arrow-up-thin</v-icon
                      >
                    </th>
                    <th v-if="isManager || isSupervisor" class="text-left">
                      <a :style="{ color: textColor('black') }">Status</a>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="audit in sortedAuditList"
                    :key="audit.auditId"
                    @click="testAuditNav(audit.auditId)"
                  >
                    <td>
                      {{ formatDateForList(audit.dateStarted) }}
                    </td>
                    <td>
                      <a>{{ audit.officeName }}</a>
                    </td>
                    <td v-if="isManager || isSupervisor">
                      {{ audit.statusCode }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-row>
        </template>

        <v-row
          v-if="!isManager"
          :style="{ color: textColor('black') }"
          class="section-margin"
        >
          <v-icon :style="{ color: textColor('black') }" class="space"
            >mdi-eye-outline</v-icon
          >
          You will not see past audits once they are marked complete.
        </v-row>
        <v-row>
          <!-- <v-btn @click.prevent="clearDevAudits">Clear</v-btn> -->
        </v-row>
      </v-container>
    </div>
    <h3 v-if="!auditListLoaded">
      Loading audit list...
    </h3>
  </div>
</template>

<style scoped>
.section-header {
  font-weight: 800;
  font-size: 24px;
}
#space {
  margin-left: 3px;
  margin-right: 3px;
}
.section-margin {
  margin-top: 10px;
  margin-bottom: 10px;
}
.v-data-table {
  width: 400px;
}
</style>

<script>
import { authenticationService } from "@/_auth/authentication.service";
import { RoleCheck } from "@/_auth/role";
import { auditService } from "@/_services/audit.service";
import moment from "moment";
import NProgress from "nprogress";
import { router } from "@/router";

export default {
  name: "AuditList",
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {},
  data() {
    return {
      currentUser: authenticationService.currentUserValue,
      auditListLoaded: false,
      auditList: [],
      outstandingAuditsCount: 0,
      sorting: {
        columnName: "dateStarted",
        direction: "asc"
      }
    };
  },
  computed: {
    isLead() {
      return RoleCheck.isLead(this.currentUser);
    },
    isSupervisor() {
      return RoleCheck.isSupervisor(this.currentUser);
    },
    isManager() {
      return RoleCheck.isManager(this.currentUser);
    },
    isTechAdvisor() {
      return RoleCheck.isTechAdvisor(this.currentUser);
    },
    sortedAuditList() {
      const activeAudits = [];
      const completedAudits = [];
      this.auditList.forEach(audit => {
        if (audit.statusCode === "COMPLETED") {
          completedAudits.push(audit);
        } else {
          activeAudits.push(audit);
        }
      });

      return [
        ...activeAudits.sort((audit1, audit2) =>
          this.sorting.direction === "asc"
            ? audit1[this.sorting.columnName].localeCompare(
                audit2[this.sorting.columnName]
              )
            : audit2[this.sorting.columnName].localeCompare(
                audit1[this.sorting.columnName]
              )
        ),
        ...completedAudits.sort((audit1, audit2) =>
          this.sorting.direction === "asc"
            ? audit1[this.sorting.columnName].localeCompare(
                audit2[this.sorting.columnName]
              )
            : audit2[this.sorting.columnName].localeCompare(
                audit1[this.sorting.columnName]
              )
        )
      ];
    },
    auditPlural() {
      return this.auditList.length < 2 ? "audit" : "audits";
    }
  },
  methods: {
    async refreshAuditList() {
      NProgress.start();
      await auditService
        .getAll(true)
        .then(audits => {
          this.auditList = audits;
          this.outstandingAuditsCount = audits.filter(audit => audit.statusCode !== "COMPLETED").length;
          NProgress.done();
          this.auditListLoaded = true;
        })
        .catch(error => {
          console.log(
            "An error occurred while retrieving the list of audits: " + error
          );
          NProgress.done();
          this.auditListLoaded = true;
          this.auditList = [];
          this.outstandingAuditsCount = 0;
        });
    },
    formatDateForList(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    openAudit(auditId) {
      if (this.disabled == false) {
        router.push({ path: `/performaudit/${auditId}` }, () => {});
      }
    },
    //TODO: remove after finalized
    testAuditNav(id) {
      // router.push({ path: `/performaudit/${id}`, query: { nav: "A" } });
      router.push(
        { name: "appendix", params: { id: id, appendixId: "A" } },
        () => {}
      );
    },
    textColor(lightEnabledColor) {
      var dark = this.$vuetify.theme.dark;
      if (!this.disabled && dark) {
        return "white";
      } else if (this.disabled && !dark) {
        return "#3d3d3d";
      } else if (this.disabled && dark) {
        return "lightgrey";
      }
      return `${lightEnabledColor} !important`;
    },
    sortAudits(columnName) {
      if (columnName === this.sorting.columnName) {
        this.sorting.direction =
          this.sorting.direction === "asc" ? "desc" : "asc";
      } else {
        this.sorting = {
          columnName,
          direction: "asc"
        };
      }
    }
  },
  created() {
    this.refreshAuditList();
    // this.isSandbox();
  }
};
</script>
