<template>
  <v-col style="padding-top:0px;">
    <v-row class="template">
      <v-col>
        <div id="workDate_rectangle">
          <v-row style="margin-left:0px">
            <v-col>
              <b>Work of Date</b>
            </v-col>
            <v-col>
              <v-layout>
                <v-menu
                  v-model="workDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on }">
                    <div>
                      <v-text-field
                        placeholder="Work of Date"
                        dense
                        outlined
                        :disabled="disabled"
                        readonly
                        v-model="tableItems[0].workDateVal"
                        v-on="on"
                        @click:append="workDateMenu = true"
                        @focus="workDateMenu = true"
                        append-icon="mdi-calendar-month"
                        hide-details
                        style="background:white; margin-top: -4%; margin-left: -45%;"
                        @change="updateAudit()"
                      >
                      </v-text-field>
                    </div>
                  </template>
                  <v-date-picker
                    v-model="tableItems[0].workDateVal"
                    @input="workDateMenu = false"
                    :disabled="disabled"
                    @change="updateAudit()"
                  >
                  </v-date-picker>
                </v-menu>
              </v-layout>
            </v-col>
            <v-col>
              <v-tooltip bottom :open-on-click="true" id="tooltip">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <p><b>Work of Date: </b>PEP Work of Date</p>
                <br />
                <p><b>Deposit Date: </b>Checks Processed in Remote Deposit</p>
                <p><b>Checks: </b>PEP Final Checks Amount</p>
                <p><b>Cash: </b>PEP Final Cash Amount</p>
                <p>
                  <b>Daily Vault Drop: </b>Total Daily Smart Safe Vault Drop(s)
                  for all users
                </p>
                <br />
                <p>
                  <b>Deposit Date: </b>Courier Pick-up Date of armored courier
                  pick-up
                </p>
                <p>
                  <b>Courier Pick-up: </b>Total Currency pick-up by armored
                  courier (Bill Acceport)
                </p>
                <br />
                <p>
                  <b>Deposit Date: </b>Vault Drop Dep Slip Date of armored
                  courier pick-up
                </p>
                <p>
                  <b>Vault Drop Dep. Slip: </b>Total Coin pick-up by armored
                  courier (Vault Drops)
                </p>
              </v-tooltip>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <div id="firstDepositRectangle">
        <v-row>
          <v-col cols="12" sm="4" md="4">
            <p class="tags"><b>Checks</b></p>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-layout>
              <v-menu
                v-model="firstDepositDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <div>
                    <v-text-field
                      placeholder="Deposit Date"
                      dense
                      outlined
                      readonly
                      :disabled="disabled"
                      v-model="tableItems[1].firstDepositDateVal"
                      v-on="on"
                      @click:append="firstDepositDateMenu = true"
                      @focus="firstDepositDateMenu = true"
                      append-icon="mdi-calendar-month"
                      hide-details
                      class="textField"
                      @change="updateAudit()"
                    >
                    </v-text-field>
                  </div>
                </template>
                <v-date-picker
                  :disabled="disabled"
                  v-model="tableItems[1].firstDepositDateVal"
                  @input="firstDepositDateMenu = false"
                  @change="updateAudit()"
                >
                </v-date-picker>
              </v-menu>
            </v-layout>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <currency-input
              class="currencyField"
              :disabled="disabled"
              currency="USD"
              v-model="tableItems[1].firstDepositAmount"
              @change="updateAudit()"
            ></currency-input>
          </v-col>
        </v-row>
        <div style="margin-left: 155px;">
          <hr class="hr" />
        </div>
        <v-row>
          <v-col cols="12" sm="4" md="4">
            <p class="tags"><b>Cash</b></p>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <p class="NA">N/A</p>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <currency-input
              class="currencyField"
              :disabled="disabled"
              currency="USD"
              v-model="tableItems[2].secondDepositAmount"
              @change="updateAudit()"
            ></currency-input>
          </v-col>
        </v-row>
        <div style="margin-left: 155px;">
          <hr class="hr" />
        </div>
        <v-row>
          <v-col cols="12" sm="4" md="4">
            <p class="tags"><b>Daily Vault Drop</b></p>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <p class="NA">N/A</p>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <currency-input
              class="currencyField"
              :disabled="disabled"
              currency="USD"
              v-model="tableItems[3].thirdDepositAmount"
              @change="updateAudit()"
            ></currency-input>
          </v-col>
        </v-row>
      </div>
    </v-row>
    <v-row>
      <div id="secondDepositRectangle">
        <v-row>
          <v-col cols="12" sm="4" md="4">
            <p class="tags"><b>Courier Pick-up</b></p>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-layout>
              <v-menu
                v-model="secondDepositDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <div>
                    <v-text-field
                      placeholder="Deposit Date"
                      dense
                      :disabled="disabled"
                      outlined
                      readonly
                      v-model="tableItems[4].secondDepositDateVal"
                      v-on="on"
                      append-icon="mdi-calendar-month"
                      @click:append="secondDepositDateMenu = true"
                      @focus="secondDepositDateMenu = true"
                      hide-details
                      class="textField"
                      @change="updateAudit()"
                    >
                    </v-text-field>
                  </div>
                </template>
                <v-date-picker
                  :disabled="disabled"
                  v-model="tableItems[4].secondDepositDateVal"
                  @input="secondDepositDateMenu = false"
                  @change="updateAudit()"
                >
                </v-date-picker>
              </v-menu>
            </v-layout>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <currency-input
              class="currencyField"
              currency="USD"
              :disabled="disabled"
              v-model="tableItems[4].fourthDepositAmount"
              @change="updateAudit()"
            ></currency-input>
          </v-col>
        </v-row>
        <div style="margin-left: 155px;">
          <hr class="hr" />
        </div>
        <v-row>
          <v-col cols="12" sm="4" md="4">
            <p class="tags"><b>Vault Drop Dep. Slip</b></p>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-layout>
              <v-menu
                v-model="thirdDepositDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <div>
                    <v-text-field
                      placeholder="Deposit Date"
                      dense
                      outlined
                      :disabled="disabled"
                      readonly
                      v-model="tableItems[5].thirdDepositDateVal"
                      v-on="on"
                      append-icon="mdi-calendar-month"
                      @click:append="thirdDepositDateVal = true"
                      @focus="thirdDepositDateVal = true"
                      hide-details
                      class="textField"
                      @change="updateAudit()"
                    >
                    </v-text-field>
                  </div>
                </template>
                <v-date-picker
                  :disabled="disabled"
                  v-model="tableItems[5].thirdDepositDateVal"
                  @input="thirdDepositDateMenu = false"
                  @change="updateAudit()"
                >
                </v-date-picker>
              </v-menu>
            </v-layout>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <currency-input
              class="currencyField"
              :disabled="disabled"
              currency="USD"
              v-model="tableItems[5].fifthDepositAmount"
              @change="updateAudit()"
            ></currency-input>
          </v-col>
        </v-row>
        <!-- <v-row class="saveButton">
          <v-btn v-on:click="updateAudit()" v-if="!disabled">
            <v-icon>mdi-check-all</v-icon>
          </v-btn>
        </v-row> -->
      </div>
    </v-row>
  </v-col>
</template>

<script>
import { RoleCheck } from "@/_auth/role";
export default {
  props: ["index", "currentUser", "value", "disabled"],
  data() {
    return {
      workDateMenu: false,
      firstDepositDateMenu: false,
      secondDepositDateMenu: false,
      thirdDepositDateMenu: false,
      tableItems: []
    };
  },
  methods: {
    updateAudit() {
      this.$emit("update", this.tableItems, this.index);
    }
  },
  computed: {
    isLead() {
      return RoleCheck.isLead(this.$parent.$parent.currentUser);
    },
    isTechAdvisor() {
      return RoleCheck.isTechAdvisor(this.$parent.$parent.currentUser);
    }
  },
  watch: {
    $route() {
      this.updateAudit();
    }
  },
  created() {
    if (
      this.value.subitems[3] != null &&
      this.value.subitems[3][this.index] != null &&
      this.value.subitems[3][this.index].length != 0
    ) {
      this.tableItems = this.value.subitems[3][this.index];
    } else {
      this.tableItems = [
        { workDateVal: "" },
        { firstDepositDateVal: "", firstDepositAmount: 0 },
        { secondDepositAmount: 0 },
        { thirdDepositAmount: 0 },
        { secondDepositDateVal: "", fourthDepositAmount: 0 },
        { thirdDepositDateVal: "", fifthDepositAmount: 0 }
      ];
    }
  }
};
</script>

<style scoped>
.template {
  margin-left: -4em;
}
#small_number_rectangle {
  display: flex;
  width: 40px;
  height: 50px;
  padding: 4px, 8px, 4px, 8px;
  background: #8c8c8c;
  vertical-align: middle;
}
#workDate_rectangle {
  width: 500px;
  height: 50px;
  background: rgba(196, 196, 196, 0.5);
  margin-left: 45px;
}
#firstDepositRectangle {
  width: 500px;
  height: 210px;
  background: rgba(196, 196, 196, 0.3);
  margin-left: 0.3em;
  margin-top: -8px;
  position: relative;
}

#secondDepositRectangle {
  width: 500px;
  height: 140px;
  background: rgba(196, 196, 196, 0.3);
  margin-left: 0.3em;
  margin-top: 5px;
  position: relative;
}

.tableNumber {
  color: white;
  margin-left: auto;
  margin-right: auto;
  font-family: Lato;
  font-size: 14px;
  line-height: 17px;
  margin-top: 3px;
}
.container {
  position: absolute;
}
.container p {
  position: static;
  top: 0%;
}

.datePicker {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 148px;
  padding: 10px;
  background: #ffffff;
  border-radius: 5px;
  top: 5px;
  left: 8px;
}
#tooltip {
  display: inline-block;
  margin: 30px 90px -3px 50px;
}

.tags {
  float: right;
  position: absolute;
  margin-top: 6px;
}

.textField {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;
  border-radius: 5px;
  left: -15px;
  top: 0px;
  width: 148px;
}
.amountField {
  background: white;
  left: -25px;
  top: 0px;
  width: 160px !important;
}

.hr {
  width: 325px;
}

.NA {
  color: 0, 0, 0, 0.4;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin-left: 40px;
  margin-top: 10px;
}

.currencyField {
  width: 150px;
  height: 40px;
  background: white;
  border-radius: 5px;
  margin-left: -23px;
  padding: 10px;
  box-shadow: 0 0 0 0.5pt gray;
}

.saveButton {
  margin-left: 87%;
  margin-top: 5px;
}
</style>
