<template v-if="value">
  <div>
    <!-- <a-ten
      v-if="getUniqueQuestion == 'a10'"
      v-model="value"
      :disabled="disabled"
      @update="formUpdate"
    ></a-ten> -->
    <a-eleven-10
      v-if="getUniqueQuestion == 'a11'"
      v-model="value"
      :officeId="officeId"
      :disabled="disabled"
      @update="formUpdate"
    ></a-eleven-10>
    <c-five
      v-if="getUniqueQuestion == 'c5'"
      v-model="value"
      :disabled="disabled"
      @update="formUpdate"
    ></c-five>
    <c-nine
      v-else-if="getUniqueQuestion == 'c9'"
      v-model="value"
      :auditFrom="auditFrom"
      :auditTo="auditTo"
      :disabled="disabled"
      @update="formUpdate"
    ></c-nine>
    <e-one
      v-else-if="getUniqueQuestion == 'e1'"
      v-model="value"
      :disabled="disabled"
      @update="formUpdate"
    ></e-one>
    <e-fourteen
      v-else-if="getUniqueQuestion == 'e14'"
      v-model="value"
      :disabled="disabled"
      @update="formUpdate"
    ></e-fourteen>
    <f-six
      v-else-if="getUniqueQuestion == 'f6'"
      v-model="value"
      :disabled="disabled"
      @update="formUpdate"
    ></f-six>
    <div v-else-if="getUniqueQuestion == 'f7'">
      <f-seven
        v-model="value"
        @update="formUpdate"
        :disabled="disabled"
      ></f-seven>
    </div>
    <basic-question
      v-else-if="
        value.questionType == 'BASIC' ||
          ((isSupervisor || isManager) &&
            value.questionType.includes('SUPERVISOR') &&
            getUniqueQuestion != 'a11')
      "
      v-model="value"
      :disabled="disabled"
      @update="formUpdate"
    ></basic-question>
    <basic-with-date-range-question
      v-else-if="value.questionType == 'FROMTO'"
      v-model="value"
      :disabled="disabled"
      @update="formUpdate"
    ></basic-with-date-range-question>
  </div>
</template>
<script>
import BasicQuestion from "./questiontype/BasicQuestion";
import BasicWithDateRangeQuestion from "./questiontype/BasicWithDateRangeQuestion";
// import ATen from "./questiontype/uniqueQuestions/ATen";
// import AEleven from "./questiontype/uniqueQuestions/AEleven";
import CFive from "./questiontype/uniqueQuestions/CFive";
import CNine from "./questiontype/uniqueQuestions/CNine";
import EOne from "./questiontype/uniqueQuestions/EOne";
import EFourteen from "./questiontype/uniqueQuestions/EFourteen";
import FSix from "./questiontype/uniqueQuestions/FSix";
import FSeven from "./questiontype/uniqueQuestions/FSeven";
import { authenticationService } from "@/_auth/authentication.service";
import { RoleCheck } from "@/_auth/role";
import AEleven10 from "./questiontype/uniqueQuestions/AEleven10.vue";
// import { auditService } from "@/_services/audit.service";

export default {
  props: [
    "value",
    "auditFrom",
    "auditTo",
    "appendix",
    "index",
    "officeId",
    "disabled"
  ],
  emits: ["form-save"],
  components: {
    BasicQuestion,
    BasicWithDateRangeQuestion,
    // ATen,
    // AEleven,
    CFive,
    CNine,
    EOne,
    EFourteen,
    FSix,
    FSeven,
    AEleven10
  },
  computed: {
    getUniqueQuestion() {
      return (
        this.$route.params.appendixId.toLowerCase() + this.value.identifier
      );
    },
    isSupervisor() {
      return RoleCheck.isSupervisor(authenticationService.currentUserValue);
    },
    isManager() {
      return RoleCheck.isManager(authenticationService.currentUserValue);
    }
  },
  watch: {},
  methods: {
    formUpdate(updates) {
      var path = `/activities/${this.appendix}/questions/${this.index}`;
      var op = "replace";
      var auditid = this.$route.params.id;
      this.$emit("updateAudit", updates, path, op, auditid);
    }
  }
};
</script>
