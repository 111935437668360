<template>
  <div>
    <v-col class="body-padding">
      <v-row class="heading">{{ sectionTitle }}</v-row>

      <v-row style="padding-top:30px;" class="justify-space-between">
        <v-btn-toggle v-model="view" mandatory>
          <v-btn
            style="border-color: #0098da !important;"
            class="main-btn"
            active-class="toggle-btn"
            value="all"
            >All</v-btn
          >
          <v-btn
            style="border-color: #0098da !important;"
            class="main-btn"
            active-class="toggle-btn"
            value="general"
            >General</v-btn
          >
          <v-btn
            style="border-color: #0098da !important;"
            class="main-btn"
            active-class="toggle-btn"
            value="appendix"
            >Appendix</v-btn
          >
          <v-btn
            style="border-color: #0098da !important;"
            class="main-btn"
            active-class="toggle-btn"
            value="procedure"
            >Procedure</v-btn
          >
        </v-btn-toggle>
        <FileUpload
          v-if="summaryType == 'attachments'"
          class="file-upload-input"
          label="New General Attachment"
          :attachmentList="attachmentList"
          buttonMode
          @attachmentAdded="attachmentAdded"
        />
        <v-btn
          v-if="summaryType == 'comments'"
          @click="addGeneralComment = true"
          class="main-btn"
          large
          outlined
          >New General Comment</v-btn
        >
      </v-row>
      <v-row style="padding-top:80px;">
        <summary-base
          ref="summaryBase"
          v-model="summaryBaseValue"
          :addGeneralComment="addGeneralComment"
          :summaryType="summaryType"
          :view="view"
        ></summary-base>
      </v-row>
    </v-col>
  </div>
</template>
<style scoped>
.body-padding {
  padding-top: 300px;
  padding-left: 70px;
  padding-right: 40px;
  padding-bottom: 40px;
}
.heading {
  font-weight: 600;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.6);
}
.main-btn {
  background-color: white !important;
  color: #0098da;
  height: 40px !important;
  border-color: #0098da !important;
  font-size: 15px !important;
}
.v-btn--active {
  background-color: #0098da !important;
  color: white !important;
}
.v-window {
  width: 100%;
}
</style>
<script>
import SummaryBase from "./SummaryBase";
import FileUpload from "@/components/FileUpload";
import { RoleCheck } from "@/_auth/role";
import { authenticationService } from "@/_auth/authentication.service";

export default {
  props: ["value", "summaryType"],
  components: {
    SummaryBase,
    FileUpload
  },
  data() {
    return {
      view: "procedure",
      addGeneralComment: false,
      summaryBaseValue: this.value
    };
  },
  computed: {
    sectionTitle() {
      if (this.summaryType == "comments") {
        return "Audit Comments";
      }
      return "Audit Attached Documents";
    },
    attachmentList() {
      return this.value.attachments;
    },
    isManager() {
      return RoleCheck.isManager(authenticationService.currentUserValue);
    }
  },
  methods: {
    attachmentAdded(attachment) {
      this.$refs.summaryBase.addAttachment(attachment);
    }
  },
  watch: {
    addGeneralComment() {
      if (this.addGeneralComment) {
        this.view = "general";
        this.addGeneralComment = false;
        this.$refs.summaryBase.focusTextbox();
      }
    }
  },
  created() {}
};
</script>
