<template>
  <div>
    <v-container pa-0 class="fill-width">
      <!-- Uncomment for old view -->
      <!-- <v-container grid-list-xs pa-0> -->
      <Audit
        v-if="app.layout == 'old-app-layout'"
        v-model="currentAudit"
        @trigger-save="save"
      ></Audit>
      <!-- </v-container> -->
      <!-- <v-container pa-0 class="fill-width"> -->
      <!-- TODO: Remove below v-row section - just for testing purposes -->
      <template v-if="app.layout == 'new-app-layout'">
        <!-- <v-row>
        <v-btn
          v-for="path in mockAuditPath"
          :key="path"
          @click="mockRoutes(path)"
        >
          {{ path }}
        </v-btn>
      </v-row> -->
        <!-- <audit-header>
        <template #scrolling-body> -->
        <new-audit
          v-model="currentAudit"
          :view="this.$route.name"
          @trigger-save="save"
        ></new-audit>
        <!-- </template> -->
        <!-- </audit-header> -->
      </template>
      <!-- <router-view></router-view> -->
    </v-container>
  </div>
</template>
<style scoped>
.fill-width {
  max-width: none !important;
}
</style>
<script>
import { router } from "@/router";
import { auditService } from "@/_services/audit.service";
import * as jsonpatch from "fast-json-patch";
import NewAudit from "../components/new-audits/Audit";
import Audit from "../components/audit/Audit";
import commonMixin from "@/mixins/common";
// import AuditHeader from "../components/new-audits/slots/AuditHeader";

export default {
  components: {
    NewAudit,
    Audit
    // AuditHeader
  },
  mixins: [commonMixin],
  data: () => ({
    currentAudit: null,
    // currentView: this.$route.name,
    lastSavedAudit: null,
    mockAuditPath: [
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "comment",
      "attachment",
      "checklist"
    ],
    view: "a"
    // showSelector: true,
    // selectedOfficeId: null,
    // currentDate: new Date().toDateString()
  }),
  computed: {
    currentUser() {
      return this.$root.$children[0].currentUser;
    }
  },
  methods: {
    save() {
      console.log("SAVE TRIGGERED!");
      let patchJson = jsonpatch.compare(this.lastSavedAudit, this.currentAudit);
      auditService
        .updateAudit(this.currentAudit.auditId, patchJson)
        .then(newPatchJson => {
          if (newPatchJson) {
            jsonpatch.applyPatch(this.lastSavedAudit, newPatchJson);

            let patchJson2 = jsonpatch.compare(
              this.currentAudit,
              this.lastSavedAudit
            );
            if (patchJson2) {
              let currentClone = jsonpatch.deepClone(this.currentAudit);
              jsonpatch.applyPatch(currentClone, patchJson2);
              this.currentAudit = currentClone;
              // The above prevents applyPatch from making array changes that can't be detected by Vue observables like the below line allowed
              // jsonpatch.applyPatch(this.currentAudit, patchJson2);
            }
          }
        });
      // TODO: Add handling for if this call fails in any way
    },
    // TODO: remove function
    mockRoutes(path) {
      if (path.length == 1) {
        router.push(
          { name: "appendix", params: { appendixId: path } },
          () => {}
        );
      } else {
        router.push({ name: path + "-summary" }, () => {});
      }
    },
    getCurrentAudit() {
      auditService
        .getById(this.$route.params.id)
        .then(audit => {
          this.currentAudit = audit;
          this.$emit("updateAudit", audit);
        })
        .then(audit => (this.lastSavedAudit = jsonpatch.deepClone(audit)))
        .catch(error => {
          console.log("reroute!", error);
          router.push("/", () => {});
        });
    }
  },
  watch: {
    $route: function(value) {
      this.currentView = value.name;
      auditService
        .getById(this.$route.params.id)
        .then(audit => (this.currentAudit = audit))
        .catch(error => {
          console.log(error);
        });
    }
  },

  created() {
    this.getCurrentAudit();
  }
};
</script>
