<template>
  <v-card class="pa-2" elevation="0">
    <!-- <v-btn fab color="accent" elevation="2" rounded absolute top right>
      <v-icon>mdi-text-box-check-outline</v-icon>
    </v-btn> -->
    <v-row class="smaller">
      <!-- <template v-if="uiType != 'D'">
        <v-col cols="12" sm="9">{{ value.name }}</v-col>
        <v-col cols="12" sm="3">(Appendix {{ value.appendix }})</v-col>
      </template>
      <template v-if="uiType == 'D'">
        <v-col cols="12"
          ><h3>{{ value.name }}</h3></v-col
        >
      </template> -->
      <v-col cols="6" md="4"
        ><v-text-field
          type=""
          dense
          label="Office"
          append-icon=""
          v-model="AuditMetaDetails.branchOfficeName"
          readonly
        ></v-text-field
      ></v-col>
      <v-col cols="6" md="4"
        ><v-text-field
          type=""
          dense
          label="Auditor"
          append-icon=""
          v-model="AuditMetaDetails.auditorName"
          readonly
        ></v-text-field
      ></v-col>
      <v-col cols="12" md="4">
        <v-menu
          dense
          v-model="showAuditPeriodDatePicker"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              v-model="auditPeriodText"
              label="Audit Period"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            dense
            v-model="auditPeriod"
            range
            :min="minAuditDate"
            :max="maxAuditDate"
            :picker-date.sync="shownDate"
            @change="showAuditPeriodDatePicker = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <!-- <v-col cols="5"
        ><v-select
          color
          v-model="auditFrequency"
          :items="auditFrequencyList"
          filled
          :label="$vuetify.breakpoint.width < 450 ? 'Min Audit Freq' : 'Minimum Audit Frequency'"
          @change="cleanupAuditOptions"
        ></v-select>
      </v-col> -->
      <v-col cols="12">
        <v-textarea
          auto-grow
          no-resize
          rows="1"
          readonly
          outlined
          label="Objective"
          v-model="value.objective"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row noGutters>
      <Comment v-model="value.comments"></Comment>
    </v-row>
    <v-row>
      <v-col
        ><template v-if="value && value.questions">
          <template v-for="(question, index) in shownQuestions">
            <v-divider :key="'divider' + index"></v-divider>

            <v-hover v-slot="{ hover }" :key="'question' + index">
              <Question
                :has-focus="hover"
                v-model="value.questions[index]"
                :audit-period="auditPeriod"
                :isManager="isManager"
                v-on="$listeners"
              ></Question>
            </v-hover>
          </template>
        </template>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Question from "@/components/audit/Question";
import Comment from "@/components/audit/Comment";
import { authenticationService } from "@/_auth/authentication.service";
import { RoleCheck } from "@/_auth/role";

export default {
  props: ["value", "AuditMetaDetails", "AuditPeriodFrom", "AuditPeriodTo"],
  data: () => ({
    showAuditPeriodDatePicker: false,
    maxAuditDate: new Date().toISOString().slice(0, 10),
    shownDate: null,
    auditFrequency: 6,
    isManager: true
  }),
  components: { Question, Comment },
  computed: {
    isSatisfied() {
      return this.value.questions.some(question => question.isSatisfied);
    },
    auditPeriod: {
      get: function() {
        if (this.AuditPeriodFrom && this.AuditPeriodTo) {
          return [this.AuditPeriodFrom, this.AuditPeriodTo];
        } else if (this.AuditPeriodFrom) {
          return [this.AuditPeriodFrom];
        } else if (this.AuditPeriodTo) {
          return [this.AuditPeriodTo];
        } else {
          return null;
        }
      },
      set: function(newValue) {
        let value = null;
        if (!newValue) {
          value = { AuditPeriodFrom: null, AuditPeriodTo: null };
        } else if (newValue.length == 1) {
          value = { AuditPeriodFrom: newValue[0], AuditPeriodTo: null };
        } else {
          value = { AuditPeriodFrom: newValue[0], AuditPeriodTo: newValue[1] };
        }
        this.$emit("update:auditPeriod", value);
      }
    },
    auditFrequencyList() {
      let list = [
        {
          text: this.$vuetify.breakpoint.width < 450 ? "12 mo" : "12 months",
          value: 12
        },
        {
          text: this.$vuetify.breakpoint.width < 450 ? "6 mo" : "6 months",
          value: 6
        },
        {
          text: this.$vuetify.breakpoint.width < 450 ? "3 mo" : "3 months",
          value: 3
        }
      ];
      return list;
    },
    uiType() {
      return this.$root.$children[0].uiType;
    },
    auditPeriodText() {
      if (this.auditPeriod == null) {
        return null;
      }
      return this.auditPeriod[0] + " to " + (this.auditPeriod[1] || "???");
    },
    minAuditDate() {
      let minDate = new Date();
      minDate.setUTCMonth(minDate.getUTCMonth() - 12);
      minDate.setUTCDate(1);
      return minDate.toISOString().slice(0, 10);
    },
    appendixNotApplicable() {
      return this.value.questions.some(
        q => q.questionType == "SKIP" && q.answer == "YES"
      );
    },
    shownQuestions() {
      if (this.appendixNotApplicable) {
        return this.value.questions.filter(q => q.questionType == "SKIP");
      } else {
        return this.value.questions;
      }
    }
  },
  watch: {
    auditPeriod() {
      this.cleanupAuditOptions();
    }
  },
  created() {
    if (this.value && !this.value.comments) {
      this.$set(this.value, "comments", []);
    }

    this.isManager = RoleCheck.isManager(
      authenticationService.currentUserValue
    );
  },
  methods: {
    cleanupAuditOptions() {
      /// Be sure audit period is in date order
      if (this.auditPeriod && this.auditPeriod.length == 2) {
        if (this.auditPeriod[0] > this.auditPeriod[1]) {
          this.auditPeriod = [this.auditPeriod[1], this.auditPeriod[0]];
        }
      }

      /// Set shown date based on auditFrequency
      let d = new Date();
      d.setMonth(d.getMonth() - 1);
      if (
        this.auditPeriod == null ||
        this.auditPeriod.length < 1 ||
        this.auditPeriod.length >= 2
      ) {
        /// Automatically start initial date at auditFrequecy months before previous month (+1 since it is inclusive)
        d.setMonth(d.getMonth() - this.auditFrequency + 1);
      }
      this.shownDate = d.toISOString().slice(0, 7);
    },
    cleanseDateArray(dates) {
      var answer = dates;
      if (!answer || !answer.length) {
        return null;
      } else if (answer.length == 1) {
        if (answer[0] == null) {
          return null;
        }
        return answer;
      } else if (answer.length == 2) {
        if (answer[0] == null && answer[1] == null) {
          return null;
        } else if (answer[0] > answer[1] || answer[0] == null) {
          return [answer[1], answer[0]];
        } else {
          return answer;
        }
      }
      return null;
    }
  },
  mounted() {
    this.cleanupAuditOptions();
  }
};
</script>

<style>
.smaller {
  font-size: 14px;
}
</style>
