import Vue from "vue";
import Vuetify from "vuetify/lib";

// import colors from 'vuetify/lib/util/colors';

const SempraColors = {
  EternalBlue: {
    // Primary/corp color, only use this variant
    base: "#004693"
  },
  EnergyRed: {
    // Primary/corp color, only use this variant
    base: "#df134c"
  },
  MiddaySky: {
    // Primary palette color, can use shades
    base: "009bda",
    darken1: "008bc4",
    darken2: "007cae",
    darken3: "006c98",
    darken4: "005d82",
    lighten1: "19a5dd",
    lighten2: "32afe1",
    lighten3: "4cb9e5",
    lighten4: "66c3e8",
    lighten5: "7fcdec"
  },
  CoveBlue: {
    // Primary palette color, can use shades
    base: "52c6d8",
    darken1: "49b2c2",
    darken2: "419eac",
    darken3: "398a97",
    darken4: "317681",
    lighten1: "63cbcb",
    lighten2: "74d1df",
    lighten3: "85d7e3",
    lighten4: "97dce7",
    lighten5: "a8e2eb"
  },
  FieldGreen: {
    // Secondary palette, avoid using more than 2
    base: "#23ae49",
    darken1: "1f9c41",
    darken2: "1c8b3a",
    darken3: "187933",
    darken4: "15682b",
    lighten1: "38b65b",
    lighten2: "4ebe6d",
    lighten3: "65c67f",
    lighten4: "7bce91",
    lighten5: "91d6a4"
  },
  SunburstYellow: {
    // Secondary palette, avoid using more than 2
    base: "#fbdd3e",
    darken1: "e1c637",
    darken2: "c8b031",
    darken3: "af9a2b",
    darken4: "968425",
    lighten1: "fbe051",
    lighten2: "fbe364",
    lighten3: "fce777",
    lighten4: "fcea8b",
    lighten5: "fdee9e"
  }
};

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: SempraColors.EternalBlue,
        secondary: SempraColors.EnergyRed,
        error: SempraColors.EnergyRed,
        accent: SempraColors.CoveBlue,
        success: SempraColors.FieldGreen,
        info: "#ffff00",
        warning: SempraColors.SunburstYellow
        // anchor: SempraColors.EternalBlue,
      },
      dark: {
        primary: SempraColors.EternalBlue,
        secondary: SempraColors.EnergyRed,
        error: SempraColors.EnergyRed,
        accent: SempraColors.CoveBlue,
        success: SempraColors.FieldGreen,
        info: "#ffff00",
        warning: SempraColors.SunburstYellow
        // anchor: SempraColors.EternalBlue,
      }
    }
  }
});
