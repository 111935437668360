<template>
  <v-app>
    <new-app-layout></new-app-layout>
  </v-app>
</template>
<style>
#nprogress .spinner-icon {
  border-top-color: #012a57;
  border-left-color: #012a57;
}
.v-overlay {
  z-index: 1001 !important;
}
.v-dialog__content--active {
  z-index: 1001 !important;
}
.row {
  margin-top: 0;
  margin-bottom: 0;
}

.row + .row {
  margin-top: 0;
}
</style>

<script>
import { authenticationService } from "@/_auth/authentication.service";
//import { onAuthUIStateChange } from '@aws-amplify/ui-components'
import { router } from "@/router";
import { Auth, Hub } from "aws-amplify";

// import OldAppLayout from "./layouts/OldApp";
import NewAppLayout from "./layouts/NewApp";

export default {
  name: "App",

  components: {
    // "old-app-layout": OldAppLayout,
    "new-app-layout": NewAppLayout
  },

  data: () => ({
    layout: "new-app-layout"
  }),
  computed: {},
  created() {
    Hub.listen("auth", data => {
      const { payload } = data;
      if (payload.event === "signIn") {
        this.signedIn = true;
        this.$router.push("/Home", () => {});
      }
      if (payload.event === "signOut") {
        this.$router.push("/login", () => {});
        this.signedIn = false;
      }
    });
    Auth.currentAuthenticatedUser()
      .then(user => {
        //console.table(JSON.parse(user.attributes.identities));
        //console.log(user);
        const cognitoUser = {
          id: user.attributes.sub,
          token: user.signInUserSession.accessToken.jwtToken,
          username: user.username,
          roles: [],
          firstName: "",
          lastName: ""
        };
        authenticationService.currentUser.next(cognitoUser);
        this.signedIn = true;
      })
      .catch(() => (this.signedIn = false));
  },
  methods: {
    logout() {
      authenticationService.logout();
      router.push("/login", () => {});
    }
  }
};
</script>
