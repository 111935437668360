<template>
  <div>
    <v-scroll-y-transition hide-on-leave>
      <v-app-bar v-if="!shrink" prominent flat fixed>
        <!-- EXPANDED APP BAR -->
        <v-row
          id="row-header"
          class="flex-nowrap"
          style="margin-top:-5px;margin-left:0px;margin-right:-15px"
        >
          <v-col
            v-if="$slots.info"
            cols="8"
            class="header-content"
            style="background-color:white"
          >
            <v-row class="header-title flex-nowrap">
              <div class="circle circle-color-exp">
                <slot name="info-icon"></slot>
              </div>
              <div class="bold">
                <slot name="info-header"></slot>
              </div>
            </v-row>
            <v-row v-if="!shrink" class="appendix-dscr light">
              <slot name="info"></slot>
            </v-row>
          </v-col>

          <v-col
            id="user"
            class="flex-grow-1 header-content"
            style="margin-right: -5px"
            :class="{ 'only-user-style': onlyUser }"
          >
            <div v-if="!shrink">
              <v-row>
                <v-icon>mdi-note-text</v-icon>
                <ChangeAuditPeriod v-if="isLead" />
                <v-icon v-else>mdi-pencil</v-icon>
              </v-row>
              <v-row>
                <v-col style="padding-bottom: 0;">
                  <v-row>{{ this.user }}</v-row>
                  <v-row>From: {{ this.auditPeriodFrom }}</v-row>
                  <v-row v-if="!this.auditPeriodToUndef"
                    >To: {{ this.auditPeriodTo }}</v-row
                  >
                  <v-row v-if="this.auditPeriodToUndef">To: Unknown</v-row>
                  <v-row class="pb-1"
                    ><b>{{ this.location }}</b></v-row
                  >
                  <v-col v-if="this.reviewHistory && this.reviewHistory.length > 0" style="padding: 0; max-width: 600px;">
                    <v-row class="font-weight-bold">
                      <v-col cols="3" style="padding: 0">Reviewer Role</v-col>
                      <v-col cols="4" style="padding: 0">Reviewer Name</v-col>
                      <v-col style="padding: 0">Review Date</v-col>
                    </v-row>
                    <v-row v-for="(review, index) in this.reviewHistory" :key="index">
                      <v-col cols="3" style="padding: 0">{{ getUserRole(review.reviewerRole) || 'n/a' }}</v-col>
                      <v-col cols="4" style="padding: 0">{{ review.reviewerName }}</v-col>
                      <v-col style="padding: 0">{{ formatDateTime(review.reviewDate) }}</v-col>
                    </v-row>
                  </v-col>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-app-bar>
    </v-scroll-y-transition>

    <v-scroll-y-transition hide-on-leave>
      <v-app-bar v-if="shrink" prominent flat fixed>
        <v-row
          id="row-header"
          class="flex-nowrap"
          style="margin-top:-5px;margin-left:0px;margin-right:-15px;background-color:#0098da;color:white; z-index:5"
        >
          <v-col v-if="$slots.info" cols="7" class="header-content">
            <v-row class="header-title flex-nowrap">
              <div class="circle circle-color-shr">
                <slot name="info-icon"></slot>
              </div>
              <div class="bold" style="color:white">
                <slot name="info-header"></slot>
              </div>
            </v-row>
          </v-col>
          <v-col class="d-flex align-content-center flex-nowrap">
            <v-row style="padding-right: 10px" class="justify-end flex-nowrap">
              <v-icon>mdi-note-text</v-icon>
              <ChangeAuditPeriod v-if="isLead" />
              <div class="shrunk-div">{{ this.auditPeriodFrom }} -</div>
              <div
                class="shrunk-div flex-nowrap"
                v-if="!this.auditPeriodToUndef"
              >
                {{ this.auditPeriodTo }}
              </div>
              <div class="shrunk-div" v-if="this.auditPeriodToUndef">
                Unknown
              </div>
              <div class="shrunk-div">{{ this.location }}</div>
            </v-row>
          </v-col>
        </v-row>
      </v-app-bar>
    </v-scroll-y-transition>
    <div class="overflow-y-auto" id="scroll=target">
      <div v-scroll:#scroll-target="onScroll">
        <slot name="main-body"></slot>
      </div>
    </div>
  </div>
</template>
<style scoped>
.shrunk-div {
  /* vertical-align: middle; */
  padding-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bold {
  font-weight: bold;
}
.light {
  font-weight: 400;
}
#row-header {
  padding-left: 30px;
  font-size: 14px;
  font-weight: 400;
}
#user {
  background-color: #0098da;
  color: white;
}
.header-content {
  padding: 20px;
}
.header-title {
  padding-top: 10px;
  padding-bottom: 10px;
}
.user-header {
  padding-left: 12px;
}
.v-icon {
  color: white;
}
.circle {
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  text-align: center;
  margin-left: 40px;
  margin-right: 20px;
}
.circle-color-exp {
  color: #fff;
  background: #0098da;
}
.circle-color-shr {
  background: #fff;
  color: #0098da;
}
.appendix-dscr {
  margin-left: 70px;
}
html {
  overflow-y: auto;
}
.only-user-style {
  padding-left: 50px;
}
.v-sheet {
  max-height: 20px;
}
.minify {
  padding: 0px;
}
.v-toolbar--prominent .v-toolbar__content {
  padding-right: 0px;
}
</style>
<script>
import ChangeAuditPeriod from "@/components/audit/ChangeAuditPeriod";
import { authenticationService } from "@/_auth/authentication.service";
import { RoleCheck } from "@/_auth/role";
import { getUserRole } from "../../../utils/role-utils";
import moment from "moment";

export default {
  props: ["onlyUser", "user", "auditPeriodTo", "auditPeriodFrom", "location", "reviewHistory"],
  data: () => ({
    offsetTop: 0,
    shrink: false,
    auditPeriodToUndef: false
  }),
  components: {
    ChangeAuditPeriod
  },
  computed: {
    isLead() {
      return RoleCheck.isLead(authenticationService.currentUserValue);
    }
  },
  methods: {
    onScroll(e) {
      this.offsetTop = e.target.scrollTop;
    },
    handleScroll() {
      this.shrink = window.scrollY > 100;
    },
    formatDateTime(dateString) {
      return moment(dateString).format('YYYY-MM-DD, HH:mm:ss')
    },
    getUserRole
  },
  created() {
    if ("undefined" === typeof this.auditPeriodTo) {
      this.auditPeriodToUndef = true;
    }
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  }
};
</script>
