import { render, staticRenderFns } from "./FyiQuestion.vue?vue&type=template&id=53e11768&scoped=true&"
import script from "./FyiQuestion.vue?vue&type=script&lang=js&"
export * from "./FyiQuestion.vue?vue&type=script&lang=js&"
import style0 from "./FyiQuestion.vue?vue&type=style&index=0&id=53e11768&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53e11768",
  null
  
)

export default component.exports