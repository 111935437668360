<template>
  <div>
    <basic-question></basic-question>
    <additional-actions-required-base>
      <template #row1>
        <v-row style="margin:0; padding-left:10px">
          <div style="padding:20px"><b>Required:</b></div>
          <div>
            <v-radio-group v-model="answer" @change="updateAnswer" row>
              <v-radio
                v-for="(answerChoice, index) in ['YES', 'NO', 'N/A']"
                :key="index"
                :label="answerChoice"
                :value="answerChoice"
              ></v-radio>
            </v-radio-group>
          </div>
        </v-row>
      </template>
    </additional-actions-required-base>
  </div>
</template>
<style scoped></style>
<script>
import AdditionalActionsRequiredBase from "../../slots/AdditionalActionsRequiredBase";
import BasicQuestion from "../BasicQuestion";
export default {
  components: {
    AdditionalActionsRequiredBase,
    BasicQuestion
  }
};
</script>
