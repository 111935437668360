import { BehaviorSubject, Subject } from 'rxjs';
import { handleResponse } from '@/_auth/handle-response';
import { Auth } from 'aws-amplify';

const submittedSubj = new BehaviorSubject(false);
const checklistSubj = new BehaviorSubject({});
// todo: remove a10NotValid instances... eventually we would like all questions to be highlighed like a10
//  setting a10NotValid up for consistency
const a10NotValid = new BehaviorSubject(null);
const errMap = new BehaviorSubject(null);

const auditCheck = new Subject();

export const auditService = {
  getAll,
  startNewAudit,
  getById,
  updateAudit,
  submitAudit,
  verifyAudit,
  completeAudit,
  compileAudit,
  submittedSubj,
  get submittedValue() {
    return submittedSubj.value;
  },
  checklistSubj,
  get checklistValue() {
    return checklistSubj.value;
  },
  a10NotValid,
  errMap,
  checkAudit,
  removeAttachment
};

export const auditStatusService = {
  getAuditStatus: () => auditCheck.asObservable(),
  updateAuditStatus: status => auditCheck.next(status),
  appendixWithErrors
};

async function getAll(forListView = true) {
  let url = `${process.env.VUE_APP_AUTH_BASE}${process.env.VUE_APP_AUDIT_URI}`;
  console.log(process.env.VUE_APP_SERVICE_BASE);
  if (forListView) {
    url += '?listonly=true';
  }
  // return fetch(url, requestOptions.get()).then(handleResponse);
  const response = await fetch(url, {
    method: 'GET',

    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
      'Content-Type': 'application/json'
    }
  }).then(handleResponse);
  return await response;
  // response.ok; // => false
  // response.status; // => 404

  // const text = await response.text();
  // //console.log(text);
  // return text;
}

async function startNewAudit(officeId) {
  let url = `${process.env.VUE_APP_SERVICE_BASE}${process.env.VUE_APP_AUDIT_URI}/start`;
  let payload = { officeId: officeId };
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  }).then(handleResponse);
  return await response;
  // return fetch(url, requestOptions.post(payload)).then(handleResponse);
}

async function getById(auditId) {
  let url = `${process.env.VUE_APP_SERVICE_BASE}${process.env.VUE_APP_AUDIT_URI}/${auditId}`;
  // return fetch(url, requestOptions.get()).then(handleResponse);
  const response = await fetch(url, {
    method: 'GET',

    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
      'Content-Type': 'application/json'
    }
  }).then(handleResponse);
  return await response;
}

// function getAllOpenForAuditor(auditorId) {
//   if (process.env.VUE_APP_SERVICE_BASE == "") {
//     // TODO: This is a quick implementation for sandbox, should be replaced with real version (live and sandbox)
//     let url = `fakeGetAllOpenForAuditor/${auditorId}`;
//     return fetch(url, requestOptions.get()).then(handleResponse);
//   }
// }

async function updateAudit(auditId, patchJson) {
  if (!patchJson || !patchJson.length) {
    if (process.env.VUE_APP_SERVICE_BASE == '') {
      console.log(
        'server side audit update (mock service)',
        'no changes, fetch aborted'
      );
    }
    return Promise.resolve();
  }
  let url = `${process.env.VUE_APP_SERVICE_BASE}${process.env.VUE_APP_AUDIT_URI}/${auditId}`;
  const response = await fetch(url, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(patchJson)
  }).then(handleResponse);
  return await response;
  // return fetch(url, requestOptions.patch(patchJson)).then(handleResponse);
}

async function submitAudit(auditId) {
  let url = `${process.env.VUE_APP_SERVICE_BASE}${process.env.VUE_APP_AUDIT_URI}/${auditId}/submit`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
      'Content-Type': 'application/json'
    }
  }).then(response => {
    submittedSubj.next(true);
    a10NotValid.next(null);
    return response.text().then(text => {
      const data = text && JSON.parse(text);
      auditCheck.next(data);
      if (!response.ok) {
        return Promise.reject(data);
      }
      return data;
    });
  });
  return await response;
}

function appendixWithErrors(errorObject) {
  var retObj = {};
  for (let key in errorObject.incompleteAnswer) {
    let errs = {};
    if (errorObject.incompleteAnswer[key].length > 0) {
      errorObject.incompleteAnswer[key].forEach(element => {
        errs[element.identifier] = ['incompleted'];
      });
    }
    if (errorObject.incompleteComment[key].length > 0) {
      errorObject.incompleteComment[key].forEach(element => {
        if (element.identifier in errs) {
          errs[element.identifier].push('no comments');
        } else {
          errs[element.identifier] = ['no comments'];
        }
      });
    }

    if (errorObject.incompleteAttachment && errorObject.incompleteAttachment[key].length > 0) {
      errorObject.incompleteAttachment[key].forEach(element => {
        if (element.identifier in errs) {
          errs[element.identifier].push('missing attachment');
        } else {
          errs[element.identifier] = ['missing attachment'];
        }
      });
    }
    if (Object.keys(errs).length !== 0) {
      retObj[key] = errs;
    }
  }
  if (retObj['A'] != null && retObj['A']['10'] != null) {
    a10NotValid.next(true);
  } else {
    a10NotValid.next(false);
  }
  errMap.next(retObj);
  return retObj;
}

async function verifyAudit(auditId) {
  let url = `${process.env.VUE_APP_SERVICE_BASE}${process.env.VUE_APP_AUDIT_URI}/${auditId}/verify`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
      'Content-Type': 'application/json'
    }
  }).then(response => {
    submittedSubj.next(true);
    return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        return Promise.reject(data);
      }
      return data;
    });
  });
  return await response;
}

async function compileAudit(auditId) {
  let url = `${process.env.VUE_APP_SERVICE_BASE}${process.env.VUE_APP_AUDIT_URI}/${auditId}/compile`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
      'Content-Type': 'application/json'
    }
  }).then(response => {
    submittedSubj.next(true);
    return response.text().then(text => {
      const data = text && JSON.parse(text);
      auditCheck.next(data);
      if (!response.ok) {
        return Promise.reject(data);
      }
      return data;
    });
  });
  return await response;
}

async function completeAudit(auditId) {
  let url = `${process.env.VUE_APP_SERVICE_BASE}${process.env.VUE_APP_AUDIT_URI}/${auditId}/complete`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
      'Content-Type': 'application/json'
    }
  }).then(response => {
    submittedSubj.next(true);
    return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        return Promise.reject(data);
      }
      return data;
    });
  });
  return await response;
}

function checkAudit() {
  submittedSubj.next(false);
}

async function removeAttachment(auditId, jsonPath, s3Key) {
  const url = `${process.env.VUE_APP_SERVICE_BASE}/attachment/remove/${s3Key}`;
  const patchList = [
    {
      op: 'remove',
      path: jsonPath
    }
  ];
  const jwt = (await Auth.currentSession()).getIdToken().getJwtToken();

  // remove attachment from db
  const response = await updateAudit(auditId, patchList).then(() => {
    // remove attachmend from s3
    return fetch(url, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${jwt}`,
        'Content-Type': 'application/json'
      }
    })
      .then(handleResponse)
      .then(response => {
        return response;
      });
  });

  return await response;
}
