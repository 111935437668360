<template>
  <v-card
    :class="
      getThemeColor +
        ' question-fyi ' +
        (identifier ? ' indented-question' : '')
    "
  >
    {{ identifier }}
    <span v-html="value.text.replace(/\r?\n/g, '<br/>')"></span>
  </v-card>
</template>

<style lang="less" scoped>
.question-fyi {
  text-align: left;
  font-weight: bold;
  span {
    white-space: pre-wrap;
  }
  width: 100%;
}
</style>

<script>
export default {
  props: ["value"],
  data: () => ({}),
  watch: {},
  computed: {
    identifier() {
      if (!this.value.identifier) {
        return null;
      }
      if (this.value.identifier.length > 0) {
        let rv = this.value.identifier;
        if (this.value.identifier != "=>") {
          rv += ".";
        }
        return rv;
      }
      return null;
    },
    displayedText() {
      let rawText = this.value.text;

      let displayed = rawText; //.replace(/\r?\n/g, '<br />');
      return displayed;
    },
    getThemeColor() {
      if (this.$vuetify.theme.dark) {
        return "grey darken-3";
      } else {
        return "grey lighten-3";
      }
    }
  },
  methods: {}
};
</script>
