<template>
  <v-card elevation="0">
    <v-row>
      <v-col class="question">
        <v-row>
          <v-col :class="identifier ? ' indented-question' : ''"
            >{{ identifier }}
            <span v-html="value.text.replace(/\r?\n/g, '<br/>')"></span
          ></v-col>
        </v-row>
        <v-row noGutters class="pt-3">
          <v-col offset="2">
            <v-menu
              dense
              v-model="showFromDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  v-model="FromText"
                  label="From"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                dense
                v-model="fromAnswer"
                :picker-date.sync="shownDate"
                @change="showFromDatePicker = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col offset="1">
            <v-menu
              dense
              v-model="showToDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  v-model="ToText"
                  label="To"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                dense
                v-model="toAnswer"
                :picker-date.sync="shownDate"
                @change="showToDatePicker = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4" sm="2" md="2" lg="2" xl="1">
        <v-radio-group v-model="value.answer" class="mt-0 pt-0">
          <span>{{ adherenceDisplayedWord }}</span>
          <v-radio
            v-for="(answerChoice, index) in ['YES', 'NO', 'N/A']"
            :key="index"
            :label="answerChoice"
            :value="answerChoice"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
  </v-card>
</template>

<style lang="less"></style>

<script>
export default {
  props: ["value", "auditPeriod"],
  data: () => ({
    showFromDatePicker: false,
    showToDatePicker: false,
    shownDateBacking: null
  }),
  computed: {
    fromAnswer: {
      get() {
        return this.value.answerFromToDates[0];
      },
      set(newValue) {
        this.value.answerFromToDates = this.cleanseDataArray(
          this.value.answerFromToDates,
          newValue,
          true
        );
      }
    },
    toAnswer: {
      get() {
        return this.value.answerFromToDates[1];
      },
      set(newValue) {
        this.value.answerFromToDates = this.cleanseDataArray(
          this.value.answerFromToDates,
          newValue,
          false
        );
      }
    },
    shownDate: {
      get() {
        if (this.shownDateBacking == null && this.auditPeriod != null) {
          return this.auditPeriod[0];
        }
        return this.shownDateBacking;
      },
      set(newValue) {
        this.shownDateBacking = newValue;
      }
    },
    identifier() {
      if (!this.value.identifier) {
        return null;
      }
      if (this.value.identifier.length > 0) {
        let rv = this.value.identifier;
        if (this.value.identifier != "=>") {
          rv += ".";
        }
        return rv;
      }
      return null;
    },
    adherenceDisplayedWord() {
      if (this.$vuetify.breakpoint.width < 280) {
        return "";
      } else if (this.$vuetify.breakpoint.width < 320) {
        return "Adheres?";
      }
      return "Adherence:";
    },
    FromText() {
      var dates = this.fromAnswer;
      if (!this.value || dates == null) {
        return null;
      }
      return dates;
    },
    ToText() {
      var dates = this.toAnswer;
      if (!this.value || dates == null) {
        return null;
      }
      return dates;
    }
  },
  methods: {
    cleanseDataArray(fromToDates, newValue, changingFrom) {
      let from = changingFrom ? changingFrom : false;
      let dates = fromToDates;
      if (from) {
        if (!dates[1]) {
          dates = [newValue, null];
        } else {
          let toDate = dates[1];
          if (toDate < newValue) {
            dates = [toDate, newValue];
          } else {
            dates = [newValue, toDate];
          }
        }
      } else {
        if (!dates[0]) {
          dates = [null, newValue];
        } else {
          let fromDate = dates[0];
          if (fromDate > newValue) {
            dates = [newValue, fromDate];
          } else {
            dates = [fromDate, newValue];
          }
        }
      }
      return dates;
    },
    setLocalDate(date) {
      let localTimezoneOffSet = date.getTimezoneOffset() * 60 * 1000;
      let localDate = date - localTimezoneOffSet;
      localDate = new Date(localDate).toISOString().slice(0, 10);
      return localDate;
    }
  }
};
</script>
