<template>
  <!-- TODO: Remove "new-app" class once the old app is fully deprecated -->
  <v-app id="inspire" class="new-app">
    <div v-if="showNav">
      <SideNav
        :activities="activities"
        v-if="this.$router.currentRoute.path != '/login'"
      />
    </div>
    <v-main :class="mainClasses" id="main">
      <router-view @updateAudit="updateAudit"></router-view>
    </v-main>
  </v-app>
</template>

<style lang="scss" scoped>
.main {
  padding-left: 50px;
}
.dark-background {
  background-color: #012957;
}
.main-background {
  background-color: #f7f7f7;
}
.new-app {
  h3 span {
    font-weight: normal;
  }

  .theme--dark.v-divider {
    opacity: 1;
  }

  label.v-label.theme--light {
    color: #ffffff;
  }
}
</style>

<script>
import { RoleCheck } from "@/_auth/role";
import commonMixin from "@/mixins/common";
import { authenticationService } from "@/_auth/authentication.service";
import SideNav from "@/components/SideNav";

export default {
  name: "NewApp",

  components: { SideNav },
  mixins: [commonMixin],

  data: () => ({
    activities: null,
    activityByAppendix: null,
    showNav: true
  }),

  computed: {
    mainClasses() {
      if (this.$route.name == "login") {
        return ["dark-background", "main"];
      }
      if (!this.$vuetify.theme.dark) {
        return ["main-background", "main"];
      } else {
        return ["main"];
      }
    },
    isLead() {
      // return RoleCheck.isLead(this.currentUser);
      return RoleCheck.isLead(this.app.currentUser);
    }
  },
  methods: {
    updateAudit(audit) {
      this.activities = audit.activities;
      this.activityByAppendix = audit.activities.reduce(function(
        map,
        activity
      ) {
        map[activity.appendix] = activity;
        return map;
      },
      {});
    }
  },
  watch: {
    $route(to) {
      if (to.name == "login" && "undefined" !== typeof authenticationService) {
        this.showNav = false;
      } else {
        this.showNav = true;
      }
    }
  },
  created() {}
};
</script>
