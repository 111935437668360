<template>
  <v-row style="margin:0; padding-left:10px">
    <div style="padding:20px">{{ questionLabel }}</div>
    <div>
      <v-radio-group
        v-if="
          (!isSupervisor && !isManager) ||
            (isSupervisor && value.questionType.includes('SUPERVISOR'))
        "
        v-model="answer"
        @change="updateAnswer"
        row
        :disabled="disabled"
      >
        <v-radio
          v-for="(answerChoice, index) in ['YES', 'NO', 'N/A']"
          :key="index"
          :label="answerChoice"
          :value="answerChoice"
        ></v-radio>
      </v-radio-group>
      <div
        v-else
        style="padding:20px; font-weight:bold;"
        :style="{ color: fontColor }"
      >
        {{ answer }}
      </div>
    </div>
  </v-row>
</template>
<style scoped></style>
<script>
import { authenticationService } from "@/_auth/authentication.service";
import { RoleCheck } from "@/_auth/role";
export default {
  props: {
    value: Object,
    questionLabel: {
      type: String,
      default: "Adherence"
    },
    disabled: {
      default: false,
      type: Boolean
    }
  },
  data: () => {
    return {
      answer: null
    };
  },
  emits: ["update"],
  watch: {
    $route: function() {
      this.answer = this.value.answer;
    }
  },
  computed: {
    isSupervisor() {
      return RoleCheck.isSupervisor(authenticationService.currentUserValue);
    },
    isManager() {
      return RoleCheck.isManager(authenticationService.currentUserValue);
    },
    fontColor() {
      if (this.value.answer && this.value.answer.toUpperCase() == "NO") {
        return "red";
      } else return "black";
    }
  },
  methods: {
    updateAnswer() {
      this.$emit("update", [
        { suffix: "answer", value: this.answer },
        { suffix: "answered", value: true }
      ]);
    }
  },
  created() {
    if (this.value && this.value.answer) {
      this.answer = this.value.answer;
    }
  }
};
</script>
