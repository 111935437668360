<template>
  <div>
    <basic-question v-model="value" @update="updateAns" :disabled="disabled">
    </basic-question>
    <additional-actions-required-base>
      <template #row1>
        <div v-html="text(0)"></div>
        <v-row style="margin:0; padding-left:10px">
          <div style="padding:20px">Adherence</div>
          <div v-if="!disabled">
            <v-radio-group
              v-model="subAns1"
              @change="updateSubAns1"
              :disabled="disabled"
              row
            >
              <v-radio
                v-for="(answerChoice, index) in ['YES', 'NO', 'N/A']"
                :key="index"
                :label="answerChoice"
                :value="answerChoice"
              ></v-radio>
            </v-radio-group>
          </div>
          <div
            v-else
            style="padding:20px; font-weight:bold;"
            :style="{ color: fontColor }"
          >
            {{ subAns1 }}
          </div>
        </v-row>
      </template>
      <template #row2>
        <div v-html="text(1)"></div>
        <v-row style="margin:0; padding-left:10px">
          <div style="padding:20px">Adherence</div>
          <div v-if="!disabled">
            <v-radio-group
              v-model="subAns2"
              @change="updateSubAns2"
              row
              :disabled="disabled"
            >
              <v-radio
                v-for="(answerChoice, index) in ['YES', 'NO', 'N/A']"
                :key="index"
                :label="answerChoice"
                :value="answerChoice"
              ></v-radio>
            </v-radio-group>
          </div>
          <div
            v-else
            style="padding:20px; font-weight:bold;"
            :style="{ color: fontColor }"
          >
            {{ subAns2 }}
          </div>
        </v-row>
      </template>
    </additional-actions-required-base>
  </div>
</template>
<style scoped></style>
<script>
import AdditionalActionsRequiredBase from "../../slots/AdditionalActionsRequiredBase";
import BasicQuestion from "../BasicQuestion";
import { UPDATED_TEXT } from "../../../../local-audit-data/updatedText";
export default {
  props: { value: Object, disabled: Boolean },
  components: {
    AdditionalActionsRequiredBase,
    BasicQuestion
  },
  data: () => ({
    answer: null,
    subAns1: null,
    subAns2: null
  }),
  computed: {
    fontColor() {
      if (this.value.answer && this.value.answer.toUpperCase() == "NO") {
        return "red";
      } else return "black";
    }
  },
  methods: {
    text(index) {
      return UPDATED_TEXT["C5"].subitems[index].text.replace(/\r?\n/g, "<br/>");
    },
    updateAns(input) {
      this.answer = input[0].value;
      this.$emit("update", [input[0], this.answered()]);
    },
    updateSubAns1(input) {
      this.subAns1 = input;
      this.$emit("update", [
        { suffix: "subitems/0/answer", value: input },
        this.answered()
      ]);
    },
    updateSubAns2(input) {
      this.subAns2 = input;
      this.$emit("update", [
        { suffix: "subitems/1/answer", value: input },
        this.answered()
      ]);
    },
    answered() {
      if (
        (this.value.answer != null || this.answer != null) &&
        this.value.subitems[0] != null &&
        (this.value.subitems[0].answer != null || this.subAns1 != null) &&
        this.value.subitems[1] != null &&
        (this.value.subitems[1].answer != null || this.subAns2 != null)
      ) {
        return { suffix: "answered", value: true };
      } else {
        return { suffix: "answered", value: false };
      }
    }
  },
  created() {
    this.answer = this.value.answer;

    if ("undefined" !== typeof this.value["subitems"][0]["answer"]) {
      this.subAns1 = this.value["subitems"][0]["answer"];
    } else {
      this.value["subitems"][0]["answer"] = this.subAns1;
    }

    if ("undefined" !== typeof this.value["subitems"][1]["answer"]) {
      this.subAns2 = this.value["subitems"][1]["answer"];
    } else {
      this.value["subitems"][1]["answer"] = this.subAns2;
    }
  }
};
</script>
