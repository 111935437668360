export const Role = {
  Lead: "lead",
  Supervisor: "supervisor",
  Manager: "manager",
  TechAdvisor: "techadvisor"
};

export const RoleCheck = {
  isLead,
  isSupervisor,
  isTechAdvisor,
  isManager
};

function isLead(user) {
  return user && user.role && user.role.includes(Role.Lead);
}

function isSupervisor(user) {
  return user && user.role && user.role.includes(Role.Supervisor);
}

function isTechAdvisor(user) {
  return user && user.role && user.role.includes(Role.TechAdvisor);
}

function isManager(user) {
  return user && user.role && user.role.includes(Role.Manager);
}
